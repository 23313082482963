import useBackendRequest from "../useBackendRequest";
import { IBillingStrategyType, IEntrepreneurActivityTypeReference } from '../../../types/backend/Clients';

interface IBillingStrategyTypeResponse {
  value: number;
  name: string;
}

interface IReferenseResponse {
  name: string;
  value: number;
}


interface IReferencesApi {
  getBillingStrategyTypes: () => Promise<IBillingStrategyType[]>;
  getEntrepreneurActivityTypes(): Promise<IEntrepreneurActivityTypeReference[]>;
}

export default function useReferencesApi(): IReferencesApi {
  const { makeRequest } = useBackendRequest();

  function getBillingStrategyTypes(): Promise<IBillingStrategyType[]> {
    return makeRequest<IBillingStrategyTypeResponse[], IBillingStrategyType[]>(
      '/api/references/billingStrategyTypes',
      "get",
      response => response,
      {}
    );
  }

  function getEntrepreneurActivityTypes() {
    return makeRequest<IReferenseResponse[], IEntrepreneurActivityTypeReference[]>(
      `/api/references/entrepreneurActivityTypes`,
      'get',
      response => response,
      {});
  }

  return {
    getEntrepreneurActivityTypes,
    getBillingStrategyTypes
  }
}
