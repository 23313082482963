import React from 'react';
import { Button, Row, Col, Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { CloseOutlined, DollarOutlined } from '@ant-design/icons';
import { IClientTariffSettings } from '../../../../../types/backend/Clients';

type TColumnProps = ColumnProps<IClientTariffSettings>;

export function getTariffSettingsListColumns(
  handleOnDeactivation: (settingsId: string) => void,
  handleOnBillingStrategyCahnge: (settingsId: string, billingStrategyType: number, tariffName: string) => void
): TColumnProps[] {
  return [
    {
      title: '#',
      key: 'index',
      width: 70,
      render: (text, record, index) => index + 1
    },
    {
      title: 'Продукт',
      dataIndex: 'productName',
      key: 'productName',
    },
    {
      title: 'Подключен',
      key: 'startDate',
      render: (text, record) => record.startDate.format('DD.MM.yyyy')
    },
    {
      title: 'Активен до',
      key: 'endDate',
      render: (text, record) => record.endDate.format('DD.MM.yyyy')
    },
    {
      title: 'Тариф',
      dataIndex: 'tariffName',
      key: 'tariffName',
    },
    {
      title: 'Модель тарификации',
      dataIndex: 'billingStrategyName',
      key: 'billingStrategyName',
      width: 120,
    },
    {
      title: 'Действия',
      key: 'action',
      align: 'center',
      width: 150,
      render: (text, record) => (
        <Row gutter={8}>
          <Col span={12}>
            <Tooltip title='Тарификация'>
              <Button
                size='large'
                shape='circle'
                icon={<DollarOutlined />}
                onClick={() => handleOnBillingStrategyCahnge(record.id, record.billingStrategyType, record.tariffName)}
              />
            </Tooltip>
          </Col>
          <Col span={12}>
            <Tooltip title='Отключить'>
              <Button
                size='large'
                shape='circle'
                disabled
                onClick={() => handleOnDeactivation(record.id)}
                icon={<CloseOutlined />}
              />
            </Tooltip>
          </Col>
        </Row>
      ),
    }
  ];
}