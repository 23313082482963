
import useBackendRequest from "../useBackendRequest";

interface IBalanceApi {
  deposit(clientId: string, amount: number, description: string | null): Promise<void>,
  withdraw(clientId: string, amount: number, description: string | null): Promise<void>
}

export default function useBalanceApi(): IBalanceApi {

  const { makeRequest } = useBackendRequest();

  function deposit(clientId: string, amount: number, description: string | null): Promise<void> {
    return makeRequest<void, void>(
      `/api/billing/deposit/${clientId}?amount=${amount}&description=${description ?? ""}`,
      'post',
      () => { },
      undefined
    )
  }

  function withdraw(clientId: string, amount: number, description: string | null): Promise<void> {
    return makeRequest<void, void>(
      `/api/billing/withdraw/${clientId}?amount=${amount}&description=${description ?? ""}`,
      'post',
      () => { },
      undefined
    )
  }

  return {
    deposit,
    withdraw
  }
}