import React, { useEffect, useState, useRef, useContext } from 'react';
import { Table, TablePaginationConfig, notification, Row, Col, Descriptions } from 'antd';
import { IUserProfile } from '../../../../../hooks/useAuth';
import useClientFreeRequestsApi from '../../../../../hooks/backend/api/clients/useClientFreeRequestsApi';
import { IClientFreeRequestSettings } from '../../../../../types/backend/Clients';
import { getFreeRequestSettingsListColumns } from './FreeRequestSettingsListColumns';
import { useFreeRequestSettingsListConfig } from './FreeRequestSettingsListConfig';
import { defaultPage, defaultPageSize } from '../../../../../types/list/PagingConfig';
import { FreeRequestSettingsConnection } from './FreeRequestSettingsConnection';
import IBackendError, { parseBackendError } from '../../../../../types/BackendError';
import { ProductListContext } from '../ClientProductList';

interface IFreeRequestSettingsListProps {
  currentUser: IUserProfile
  clientId: string;
  isLoading: boolean;
  setIsLoading(value: boolean): void;
  setInitError(error: any): void;
  isConnectionVisible: boolean;
  closeConnection(): void;
}

interface ITableItem extends IClientFreeRequestSettings {
  key: string;
}

export function FreeRequestSettingsList(props: IFreeRequestSettingsListProps) {
  const { clientId, isLoading, setIsLoading, setInitError, currentUser, isConnectionVisible, closeConnection } = props;
  const { listUpdated } = useContext(ProductListContext);

  const unmounted = useRef(false);
  const clientFreeRequestsApi = useClientFreeRequestsApi();
  const { paging, setPaging } = useFreeRequestSettingsListConfig();
  const [totalRows, setTotalRows] = useState(0);
  const [items, setItems] = useState<ITableItem[]>([]);
  const [disablingProcess, setDisablingProcess] = useState<string | null>(null);

  async function fetchData() {
    if (isLoading) return;
    
    setInitError(undefined);
    setIsLoading(true);

    try {
      const receivedItems = await clientFreeRequestsApi.getList(
        clientId, paging.page, paging.pageSize);

      if (!unmounted.current) {
        setItems(receivedItems.list.map((item, index) =>
          ({ key: `freeRequestSettings_${index}`, ...item })));
        setTotalRows(receivedItems.totalRowCount);
      }
    } catch (error) {
      if (!unmounted.current) setInitError(error);
    } finally {
      if (!unmounted.current) setIsLoading(false);
    }
  }  

  useEffect(() => {
    return () => { unmounted.current = true; };
  }, []);

  useEffect(() => {
    if (currentUser?.canViewClients()) {
      fetchData();
    }
  }, [clientId, currentUser, listUpdated])

  function handleOnChangeTable(pagination: TablePaginationConfig) {
    setPaging(pagination.current ?? defaultPage, pagination.pageSize ?? defaultPageSize);
  }

  async function disable(settingsId: string) {
    let isSuccessful = false;
    setDisablingProcess(settingsId);

    try {
      await clientFreeRequestsApi.disable(settingsId);
      isSuccessful = true;
    } catch (error) {
      const errors = parseBackendError(error as IBackendError);
      notification.open({
        type: 'error',
        duration: 0,
        message: 'Не удалось отключить бесплатные запросы',
        description: errors && (
          <Descriptions column={1} size='small'>
            {errors.map((value, index) => (
              <Descriptions.Item key={`error_${index}`}>{value.message}</Descriptions.Item>
            ))}
          </Descriptions>
        )
      })
    } finally {
      if (!unmounted.current) setDisablingProcess(null);
    }

    if (isSuccessful) {
      notification.open({
        type: 'success',
        message: 'Бесплатные запросы успешно отключены',
      });  
      fetchData();
    }
  }

  function handleConnectionOk(productName: string, count: number) {
    closeConnection();
    notification.open({
      type: 'success',
      message: 'Бесплатные запросы успешно подключены',
      description: (
        <Descriptions column={1} size='small'>
          <Descriptions.Item label='Продукт'>{productName}</Descriptions.Item>
          <Descriptions.Item label='Количество'>{count}</Descriptions.Item>
        </Descriptions>
      )
    });
    fetchData();
  }

  return (
    <>
      <Table
        size='small'
        columns={getFreeRequestSettingsListColumns(disable, disablingProcess)}
        loading={isLoading}
        dataSource={items}
        scroll={{ x: 900 }}
        onChange={handleOnChangeTable}
        pagination={{
          current: paging.page,
          position: ['bottomCenter'],
          pageSize: paging.pageSize,
          total: totalRows,
          showSizeChanger: false
        }}
      />
      <FreeRequestSettingsConnection
        currentUser={currentUser}
        clientId={clientId}
        visible={isConnectionVisible}
        handleConncectionOk={handleConnectionOk}
        handleConnectionCancel={closeConnection}
      />
    </>
  )
}