import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Form, PageHeader, Input, Space, Alert, Select } from 'antd';
import useClientRequisitesApi from '../../../../hooks/backend/api/clients/useClientRequisitesApi';
import useReferencesApi from '../../../../hooks/backend/api/useReferencesApi';
import { EntrepreneurActivityType, IClientRequisites, IEntrepreneurActivityTypeReference } from '../../../../types/backend/Clients';
import useAuth from '../../../../hooks/useAuth';
import { useParams } from 'react-router-dom';
import { Wrapper, WrapperState } from '../../../Wrapper';
import useBackendForm from '../../../../hooks/useBackendForm';
import IBackendError from '../../../../types/BackendError';

interface IClientRequisitesForm extends IClientRequisites { }

interface IClientRequisitesEditProps {
    requisites: IClientRequisites | undefined,
    setEditMode: (value: boolean) => void,
    refreshClient: () => Promise<void>
}

export function ClientRequisitesEdit(props: IClientRequisitesEditProps) {
    const { requisites, setEditMode, refreshClient } = props;
    const clientRequisitesApi = useClientRequisitesApi();
    const referencesApi = useReferencesApi();
    const [references, setReferences] = useState<IEntrepreneurActivityTypeReference[]>([]);
    const unmounted = useRef(false);
    const { currentUser } = useAuth();
    const params = useParams<{ id: string }>();
    const [wrapperState, setWrapperState] = useState<WrapperState | null>(null);
    const backendForm = useBackendForm<IClientRequisitesForm>();
    const [isProcessing, setIsProcessing] = useState(false);
    const [entrepreneurActivityType, setEntrepreneurActivityType] 
        = useState<EntrepreneurActivityType | undefined>(props.requisites?.entrepreneurActivityTypeValue ?? EntrepreneurActivityType.Company);

    useEffect(() => {
        fetchData();
        return () => {
            unmounted.current = true;
        };
    }, []);

    useEffect(() => {
        if (currentUser === null) setWrapperState('loading');
        else if (!currentUser.canEditClients()) setWrapperState('accessDenied');
        else setWrapperState(null);
    }, [currentUser]);

    async function fetchData() {
        let references = await referencesApi.getEntrepreneurActivityTypes();
        setReferences(references);
    }

    async function handleOnFinish(values: IClientRequisitesForm) {
        if (!params?.id) return;

        setIsProcessing(true);
        backendForm.resetErrors();
        try {
            await clientRequisitesApi.editClientRequisites(params.id, values);
            await refreshClient();
            setEditMode(false);
        } catch (error) {
            if (!unmounted.current) backendForm.setErrors(error as IBackendError);
        }
        finally {
            if (!unmounted.current) setIsProcessing(false);
        }
    }

    function handleBack() {
        setEditMode(false);
    }

    function handleEntrepreneurActivityTypeChange(value: EntrepreneurActivityType) {
        setEntrepreneurActivityType(value);
        backendForm.validate("inn");
    }

    function renderErrors() {
        return backendForm.generalErrors?.map((error, index) =>
            <Alert
                key={`login_error_${index}`}
                type="error"
                message={error}
            />
        )
    }

    return (
        <Wrapper state={wrapperState}>
            <Card
                bordered={false}
                title={(
                    <PageHeader
                        title="Редактирование реквизитов клиента"
                        onBack={handleBack}
                    />
                )}
            >
                <Space direction="vertical" style={{ width: '100%' }}>
                    <Form
                        form={backendForm.form}
                        autoComplete='off'
                        initialValues={
                            {
                                ...requisites,
                                entrepreneurActivityTypeValue: requisites?.entrepreneurActivityTypeValue ?? EntrepreneurActivityType.Company,
                            }
                        }
                        onFinish={handleOnFinish}
                        wrapperCol={{ span: 12 }}
                        labelCol={{ span: 4 }}
                    >
                        <Form.Item
                            name="inn"
                            label="ИНН"
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(_, value: string) {
                                        if (value.length !== 10 && getFieldValue("entrepreneurActivityTypeValue") as EntrepreneurActivityType === EntrepreneurActivityType.Company) {
                                            return Promise.reject("Длина должна быть 10 символов")
                                        }
                                        if (value.length !== 12 && getFieldValue("entrepreneurActivityTypeValue") as EntrepreneurActivityType === EntrepreneurActivityType.Individual) {
                                            return Promise.reject("Длина должна быть 12 символов")
                                        }
                                        return Promise.resolve();
                                    }
                                })
                            ]}
                        >
                            <Input inputMode='numeric' pattern='[0-9]*' />
                        </Form.Item>
                        <Form.Item
                            name="entrepreneurActivityTypeValue"
                            label="Тип"
                        >
                            <Select
                                onChange={handleEntrepreneurActivityTypeChange}
                                options={references.map(x => ({
                                    value: x.value,
                                    label: x.name
                                }))}></Select>
                        </Form.Item>
                        <Form.Item
                            name="ogrn"
                            label="ОГРН"
                            rules={[
                                { required: true, message: 'Обязательное поле' },
                                { len: 13, message: 'Длина строки должна быть 13 символов' },
                                { pattern: new RegExp('^[0-9]+$'), message: 'Принимаются только цифры' },
                            ]}
                        >
                            <Input inputMode='numeric' pattern='[0-9]*' />
                        </Form.Item>
                        {entrepreneurActivityType === EntrepreneurActivityType.Company &&
                            <Form.Item
                                name="legalName"
                                label="Юр. наименование"
                                rules={[
                                    { required: true, message: 'Обязательное поле' },
                                    { max: 512, message: 'Максимальная длина строки 512' }]}
                            >
                                <Input type='string' />
                            </Form.Item>}
                        <Form.Item
                            name="kpp"
                            label="КПП"
                            rules={[
                                { required: true, message: 'Обязательное поле' },
                                { len: 9, message: 'Длина строки должна быть 9 символов' },
                                { pattern: new RegExp('^[0-9]+$'), message: 'Принимаются только цифры' },
                            ]}
                        >
                            <Input inputMode='numeric' pattern='[0-9]*' />
                        </Form.Item>
                        <Form.Item
                            name="bankName"
                            label="Банк"
                            rules={[{ max: 512, message: 'Максимальная длина строки 512' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="bik"
                            label="БИК"
                            rules={[
                                { len: 9, message: 'Длина строки должна быть 9 символов' },
                                { pattern: new RegExp('^04*'), message: 'Строка должна начинаться с символов \'04\'' },
                                { pattern: new RegExp('^[0-9]+$'), message: 'Принимаются только цифры' },
                            ]}
                        >
                            <Input inputMode='numeric' pattern='[0-9]*' />
                        </Form.Item>
                        <Form.Item
                            name="correspondentAccount"
                            label="К/С"
                            rules={[
                                { len: 20, message: 'Длина строки должна быть 20 символов' },
                                { pattern: new RegExp('^[0-9]+$'), message: 'Принимаются только цифры' }
                            ]}
                        >
                            <Input inputMode='numeric' pattern='[0-9]*' />
                        </Form.Item>
                        <Form.Item
                            name="bankAccount"
                            label="Р/С"
                            rules={[
                                { len: 20, message: 'Длина строки должна быть 20 символов' },
                                { pattern: new RegExp('^[0-9]+$'), message: 'Принимаются только цифры' }
                            ]}
                        >
                            <Input inputMode='numeric' pattern='[0-9]*' />
                        </Form.Item>
                        <Form.Item
                            name="legalAddress"
                            label="Юр. адрес"
                            rules={[
                                { required: true, message: 'Обязательное поле' },
                                { max: 512, message: 'Максимальная длина строки 512' }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="actualAddress"
                            label="Фактический адрес"
                            rules={[
                                { required: true, message: 'Обязательное поле' },
                                { max: 512, message: 'Максимальная длина строки 512' }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="postalAddress"
                            label="Почтовый адрес"
                            rules={[
                                { required: true, message: 'Обязательное поле' },
                                { max: 512, message: 'Максимальная длина строки 512' }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="headFIO"
                            label="ФИО"
                            rules={[
                                { required: true, message: 'Обязательное поле' },
                                { max: 512, message: 'Максимальная длина строки 512' },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        {entrepreneurActivityType === EntrepreneurActivityType.Company &&
                            <Form.Item
                                name="headPosition"
                                label="Должность"
                                rules={[
                                    { required: true, message: 'Обязательное поле' },
                                    { max: 512, message: 'Максимальная длина строки 512' }
                                ]}
                            >
                                <Input />
                            </Form.Item>}

                        {entrepreneurActivityType === EntrepreneurActivityType.Company &&
                            <Form.Item
                                name="headDocument"
                                label="Действует на основании"
                                rules={[{ max: 512, message: 'Максимальная длина строки 512' }]}
                            >
                                <Input />
                            </Form.Item>}
                        <Form.Item
                            name="phoneNumber"
                            label="Телефон"
                            rules={[
                                { required: true, message: 'Обязательное поле' },
                                { max: 50, message: 'Максимальная длина строки 50' },
                                { pattern: new RegExp('^[0-9]+$'), message: 'Принимаются только цифры' }
                            ]}
                        >
                            <Input inputMode='tel' pattern='[0-9]*' />
                        </Form.Item>
                        <Form.Item
                            name="email"
                            label="Email"
                            rules={[{ pattern: new RegExp(/\S+@\S+\.\S+/), message: 'Неверный формат эл. почты' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item wrapperCol={{ span: 6, offset: 4 }}>
                            <Button
                                block
                                type="primary"
                                htmlType="submit"
                                loading={isProcessing}
                            >
                                Сохранить
                            </Button>
                        </Form.Item>
                    </Form>
                    {renderErrors()}
                </Space>
            </Card>
        </Wrapper >
    );
}
