import React, { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { Badge, Button, Col, Collapse, Row, Space, Typography } from 'antd';
import { ReloadOutlined, CaretRightOutlined, ClockCircleOutlined } from '@ant-design/icons';
import moment, { Moment } from 'moment';
import useAuth from '../../../hooks/useAuth';
import { DashboardContext } from '../Dashboard';
import { IDashboardItem } from '../../../types/backend/Dashboard';
import { ICollapsedStoreConfig, useCollapsedStore } from '../stores/CollapsedStore';
import { IPagingStoreConfig, usePagingStore } from '../stores/PagingStore';
import { Wrapper, WrapperState } from '../../Wrapper';
import { DashboardList } from './DashboardList';
import IPagedList from '../../../types/backend/PagedList';

const { Title, Text } = Typography;
const { Panel } = Collapse;

const panelKey = 'listPanel';

interface IDashboardPanelTopProps<TItem extends IDashboardItem> {
  title: string;
  subTitle: string;
  collapsedStoreConfig: ICollapsedStoreConfig;
  pagingStoreConfig: IPagingStoreConfig;
  fetchData(page: number, pageSize: number): Promise<IPagedList<TItem>>;
  renderParams(isLoading: boolean): ReactNode;
  getItemDescription(item: TItem): ReactNode;
  getItemInfo?(item: TItem): ReactNode;
}

export function DashboardPanel<TItem extends IDashboardItem>(props: IDashboardPanelTopProps<TItem>) {
  const { title, subTitle, collapsedStoreConfig, pagingStoreConfig, renderParams, getItemDescription, getItemInfo } = props;
  const unmounted = useRef(false);
  const { dashboardUpdated } = useContext(DashboardContext);

  const { currentUser } = useAuth();
  const { paging, setPaging } = usePagingStore(pagingStoreConfig.pagingAtom, pagingStoreConfig.pagingSelector);
  const { collapsed, setCollapsed } = useCollapsedStore(collapsedStoreConfig.collapsedAtom, collapsedStoreConfig.collapsedSelector);

  const [updated, setUpdated] = useState<Moment>();
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState<TItem[]>([]);
  const [total, setTotal] = useState(0);
  const [initError, setInitError] = useState<any>(undefined);
  const [wrapperState, setWrapperState] = useState<WrapperState | null>(null);

  async function fetchData() {
    if (isLoading) return;

    setIsLoading(true);
    setTotal(0);
    setItems([]);

    try {
      const receivedItems = await props.fetchData(paging.page, paging.pageSize);
      if (!unmounted.current) {
        setItems(receivedItems.list);
        setTotal(receivedItems.totalRowCount);
        setUpdated(moment());
      }
    } catch (error) {
      if (!unmounted.current) setInitError(error);
    } finally {
      if (!unmounted.current) setIsLoading(false);
    }
  }

  useEffect(() => {
    return () => { unmounted.current = true; };
  }, []);

  useEffect(() => {
    currentUser && fetchData();
  }, [currentUser, dashboardUpdated, paging])

  useEffect(() => {
    if (currentUser === null) setWrapperState('loading');
    else if (initError) setWrapperState('hasErrors');
    else setWrapperState(null);
  }, [currentUser, initError]);

  function renderExpandIcon(isActive: boolean | undefined) {
    return (
      <CaretRightOutlined
        rotate={isActive ? 90 : 0}
        onClick={() => setCollapsed(!collapsed)}
      />
    )
  }

  function renderBadge(value: number, isLoading: boolean) {
    const color = 'lightslategrey';

    return (
      <Badge
        count={isLoading ? <ClockCircleOutlined style={{ color: color }} /> : value}
        style={{ backgroundColor: isLoading || value === 0 ? undefined : color }}
      />
    )
  }

  async function handleOnClickRefresh(e: React.MouseEvent<HTMLElement, MouseEvent>) {
    await fetchData();
    if (collapsed && items.length > 0) setCollapsed(false);
  }

  return (
    <Wrapper state={wrapperState} error={initError}>
      <Collapse
        bordered
        expandIcon={({ isActive }) => renderExpandIcon(isActive)}
        activeKey={collapsed ? undefined : panelKey}
      >
        <Panel
          style={{ alignItems: "center" }}
          key={panelKey}
          header={(
            <Space
              className="panel-header-title"
              direction="vertical"
              onClick={() => setCollapsed(!collapsed)}
            >
              <Row gutter={8}>
                <Col><Title level={5} style={{ margin: 0 }}>{title}</Title></Col>
                <Col>{renderBadge(total, isLoading)}</Col>
              </Row>

              <Text type="secondary">{subTitle}</Text>
            </Space>
          )}
          extra={(
            <Space direction="vertical">
              <Row gutter={8}>
                <Col flex={1} />
                <Col>{renderParams(isLoading)}</Col>
                <Col>
                  <Button
                    loading={isLoading}
                    shape="circle"
                    icon={<ReloadOutlined />}
                    onClick={handleOnClickRefresh}
                  />
                </Col>
              </Row>
              <Text type="secondary">
                {`Обновлено ${updated?.local().format('DD.MM.yyyy HH:mm:ss') ?? '-'}`}
              </Text>
            </Space>
          )}
        >
          <DashboardList
            items={items}
            total={total}
            isLoading={isLoading}
            paging={paging}
            setPaging={setPaging}
            getItemDescription={getItemDescription}
            getItemInfo={getItemInfo}
          />
        </Panel>
      </Collapse>
    </Wrapper>
  )
}