import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Tooltip, notification } from "antd";
import { PlayCircleOutlined, StopOutlined } from '@ant-design/icons';
import { useClientNotificationsApi } from "../../../../../hooks/backend/api/clients/useClientNotificationsApi";

interface IClientNotificationSettingsEnableProps {
  settingsId: string;
  onSuccess?(): void;
}

export function ClientNotificationSettingsEnable(props: IClientNotificationSettingsEnableProps) {
  const { settingsId, onSuccess } = props;

  const unmounted = useRef(false);
  useEffect(() => {
    return () => { unmounted.current = true; };
  }, []);

  const api = useClientNotificationsApi();

  const [isProcessing, setIsProcessing] = useState(false);

  const handleOnClick = useCallback(async () => {
    setIsProcessing(true);
    let successful = true;
    try {
      await api.enable(settingsId);
    } catch (error) {
      successful = false;
      console.error(error);
      notification.error({
        message: 'Не удалось включить уведомления'
      })
    } finally {
      if (!unmounted.current) setIsProcessing(false);
    }

    if (successful && onSuccess) onSuccess();
  }, [unmounted, api, setIsProcessing])

  return (
    <Button
      loading={isProcessing}
      icon={<PlayCircleOutlined />}
      onClick={handleOnClick}
    >
      Включить
    </Button>
  )
}