import React, { createContext, PropsWithChildren, useState } from 'react';
import { Button, Card, Col, PageHeader, Row } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import moment, { Moment } from 'moment';
import { BalanceLeftForSomeDays } from './Panels/BalanceLeftForSomeDays';
import { BalanceLessThan } from './Panels/BalanceLessThan';
import { ClientsWithouRequests } from './Panels/ClientsWithoutRequests';

import './Dashboard.scss';

interface IDashboardContext {
  dashboardUpdated: Moment;
}

export const DashboardContext = createContext({} as IDashboardContext);

export function Dashboard() {
  const [updated, setUpdated] = useState<Moment>(moment())

  return (
    <DashboardContext.Provider value={{ dashboardUpdated: updated }}>
      <Card
        bordered={false}
        title={(
          <PageHeader
            title='Dashboard'
          />
        )}
        extra={(
          <Button
            shape="circle"
            icon={<ReloadOutlined />}
            onClick={() => setUpdated(moment())}
          />
        )}
      >
        <Row gutter={[16, 16]}>
          <DashboardRow><BalanceLessThan /></DashboardRow>
          <DashboardRow><ClientsWithouRequests /></DashboardRow>
          <DashboardRow><BalanceLeftForSomeDays /></DashboardRow>
        </Row>
      </Card>
    </DashboardContext.Provider>
  )
}

function DashboardRow({ children }: PropsWithChildren<{}>) {
  return (
    <>
      <Col xs={24} sm={24} md={24} lg={20} xl={14} xxl={10}>
        {children}
      </Col>
      <Col xs={0} sm={0} md={0} lg={4} xl={10} xxl={14} />
    </>
  )
}