import { atom, RecoilState, RecoilValueReadOnly, selector, SetterOrUpdater, useRecoilValue, useSetRecoilState } from "recoil";

export interface ICollapsedStoreConfig {
  collapsedAtom: RecoilState<boolean>;
  collapsedSelector: RecoilValueReadOnly<boolean>;
}

export function buildCollapsedStores(prefix: string, defaultValue: boolean): ICollapsedStoreConfig {
  const collapsedAtom = atom<boolean>({
    key: `${prefix}_collapsedStore`,
    default: defaultValue
  })
  
  const collapsedSelector = selector({
    key: `${prefix}_collapsed`,
    get: ({ get }) => get(collapsedAtom)
  })
  
  return {
    collapsedAtom,
    collapsedSelector,
  }
}

interface ICollapsedStore {
  collapsed: boolean;
  setCollapsed: SetterOrUpdater<boolean>;
}

export function useCollapsedStore(atom: RecoilState<boolean>, selector: RecoilValueReadOnly<boolean>): ICollapsedStore {
  const collapsed = useRecoilValue(selector);
  const setCollapsed = useSetRecoilState(atom);

  return {
    collapsed,
    setCollapsed,
  }
}