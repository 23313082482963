import { UserAddOutlined, RedoOutlined } from '@ant-design/icons';
import { Button, PageHeader, Space, Table, TablePaginationConfig, Tooltip } from 'antd';
import { Key, SorterResult } from 'antd/lib/table/interface';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import useClientsApi from '../../../hooks/backend/api/clients/useClientsApi';
import { SubscriptionState, IClientsListItem, ClientState, ClientFreeRequestSettingsState } from '../../../types/backend/Clients';
import useIdentityApi from '../../../hooks/backend/api/useIdentityApi';
import { IManager } from '../../../types/backend/Identity';
import useProductsApi from '../../../hooks/backend/api/useProductsApi';
import { IProduct } from '../../../types/backend/Products';
import { Wrapper, WrapperState } from "../../Wrapper";
import SortDirection from "../../../types/list/SortDirection";
import { getClientListColumns } from "./ClientListColumns";
import { useClientListConfig, ISortValues, IFilterValues } from "./ClientListConfig";
import { ClientListFilter } from './ClientListFilter';
import { defaultPage, defaultPageSize } from '../../../types/list/PagingConfig';

interface IClientsTableItem extends IClientsListItem {
  key: string;
}

export function ClientList() {
  const { paging, setFilter, setPaging, filter, sort, setSort } = useClientListConfig();
  const [isLoading, setIsLoading] = useState(false);
  const [isReferencesLoading, setIsReferencesLoading] = useState(false);
  const [initError, setInitError] = useState<any>(undefined);
  const [wrapperState, setWrapperState] = useState<WrapperState | null>(null);
  const [clients, setClients] = useState<IClientsTableItem[]>([]);
  const [managers, setManagers] = useState<IManager[]>([]);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [totalRows, setTotalRows] = useState<number>(0);
  const unmounted = useRef(false);
  const clientsApi = useClientsApi();
  const productsApi = useProductsApi();
  const identityApi = useIdentityApi();
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    return () => { unmounted.current = true; };
  }, []);

  async function getClientList() {
    try {
      setInitError(undefined);
      setIsLoading(true);
      const receivedClients = await clientsApi.getClients(paging.page, paging.pageSize, sort, filter);
      const convertedClients: IClientsTableItem[] = receivedClients.list.map((r) => ({
        ...r,
        key: r.id,
      }));

      if (!unmounted.current) {
        setClients(convertedClients);
        setTotalRows(receivedClients.totalRowCount);
      }
    } catch (error) {
      if (!unmounted.current) setInitError(error);
    } finally {
      if (!unmounted.current) setIsLoading(false);
    }
  }

  async function getReferences() {
    setInitError(undefined);
    setIsReferencesLoading(true);
    try {
      const receivedManagers = await identityApi.getManagers();
      const receivedProducts = await productsApi.getProducts();

      if (!unmounted.current) {
        setManagers(receivedManagers);
        setProducts(receivedProducts);
      }
    }
    catch (error) {
      if (!unmounted.current) setInitError(error);
    }
    finally {
      if (!unmounted.current) setIsReferencesLoading(false);
    }
  }

  useEffect(() => {
    if (currentUser === null) {
      setWrapperState('loading');
    }
    else if (!currentUser.canViewClients()) {
      setWrapperState('accessDenied');
    } else if (initError) {
      setWrapperState('hasErrors');
    } else {
      setWrapperState(null);
    }
  }, [currentUser, isLoading, initError]);

  useEffect(() => {
    if (currentUser?.canViewClients()) {
      getReferences();
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser?.canViewClients()) {
      getClientList();
    }
  }, [currentUser, filter, sort, paging]);

  function redirectOnCreateClient() {
    navigate('/clients/creation');
  }

  function redirectOnViewRequest(id: string) {
    navigate(`/clients/${id}`);
  }

  function handleSorter(sorter: SorterResult<any> | SorterResult<any>[]) {
    let sorterArray = sorter instanceof (Array) ? sorter : [sorter];
    const sortValue = sorterArray.reduce<any>((acc, cur) => {
      const value: SortDirection | null = cur.order ? (cur.order === "ascend" ? "Asc" : "Desc") : null;

      if (cur.field) {
        acc[cur.field.toString()] = value;
      }

      return acc;
    }, {});
    setSort(sortValue as ISortValues);
  }

  function onFilterSearch(values: IFilterValues) {
    setFilter(values);
  }

  function handleOnChange(pagination: TablePaginationConfig,
    filters: Record<string, (Key | boolean)[] | null>,
    sorter: SorterResult<any> | SorterResult<any>[]) {
    handleSorter(sorter);
    setPaging(pagination.current ?? defaultPage, pagination.pageSize ?? defaultPageSize);
  }

  function refresh() {
    getReferences();
    getClientList();
  }

  const renderTable = () => {
    if (!currentUser) {
      return null;
    }
    return (
      <Table
        size='small'
        columns={getClientListColumns(redirectOnViewRequest)}
        loading={isLoading}
        dataSource={clients}
        scroll={{ x: 900 }}
        onChange={handleOnChange}
        pagination={{
          current: paging.page,
          position: ['bottomCenter'],
          pageSize: paging.pageSize,
          total: totalRows,
          showSizeChanger: false
        }}
      />
    );
  };

  return (
    <Wrapper state={wrapperState} error={initError}>
      <PageHeader
        title="Клиенты"
        extra={
          currentUser?.canEditClients() && (
            <Button
              type="primary"
              size="large"
              onClick={redirectOnCreateClient}
              icon={<UserAddOutlined />}
            >
              Создать клиента
            </Button>
          )
        }
      />
      <ClientListFilter
        managersOptions={managers}
        productsOptions={products}
        subscriptionStateOptions={[
          { name: "Активна", value: SubscriptionState.Available },
          { name: "Не подключена", value: SubscriptionState.Unavailable },
          { name: "Отключена", value: SubscriptionState.Closed }
        ]}
        clientStateOptions={[
          { name: 'Активен', value: ClientState.Active },
          { name: 'Заблокирован', value: ClientState.Blocked },
        ]}
        freeRequestSettingsStateOptions={[
          { name: 'Доступны', value: ClientFreeRequestSettingsState.Available },
          { name: 'Недоступны', value: ClientFreeRequestSettingsState.Unavailable }
        ]}
        handleOnSearch={onFilterSearch}
        isLoading={isLoading || isReferencesLoading}
      />
      {renderTable()}
    </Wrapper>
  );
};