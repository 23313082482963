import useBackendRequest from "../../useBackendRequest";
import { IClientRequisites, IUpdateRequisitesFromDadataForm } from '../../../../types/backend/Clients';

interface IClientRequisitesApi {
  editClientRequisites(id: string, requisites: IClientRequisites): Promise<void>;
  editClientRequisitesInn(id: string, values: IUpdateRequisitesFromDadataForm): Promise<void>;
  updateRequesitesFromDadata(id: string): Promise<boolean>;
}

export default function useClientsApi(): IClientRequisitesApi {
  const { makeRequest } = useBackendRequest();

  function editClientRequisites(clientId: string, requisites: IClientRequisites): Promise<void> {
    return makeRequest<void, void>(
      `/api/clients/${clientId}/editRequisites`,
      'post',
      () => { },
      {
        ...requisites
      }
    );
  }

  function editClientRequisitesInn(clientId: string, values: IUpdateRequisitesFromDadataForm): Promise<void> {
    return makeRequest<void, void>(
      `/api/clients/${clientId}/editRequisitesInn`,
      'post',
      () => { },
      {
        ...values
      }
    )
  }

  function updateRequesitesFromDadata(clientId: string): Promise<boolean> {
    return makeRequest<boolean, boolean>(
      `/api/clients/${clientId}/updateRequesitesFromDadata`,
      'post',
      response => response,
      undefined
    );
  }

  return {
    editClientRequisites,
    editClientRequisitesInn,
    updateRequesitesFromDadata
  }
}