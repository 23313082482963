import React, { useEffect, useMemo } from "react";
import { ColumnProps } from "antd/lib/table";
import { ClientNotificationType, IClientTelegramBindingsListItem } from "../../../../types/backend/Clients";
import { Badge, Button, Space, Tooltip } from "antd";
import { ClientTelegramGetLink } from "./Actions/ClientTelegramGetLink";
import { ClientTelegramBindingEnable } from "./Actions/ClientTelegramBindingEnable";
import { ClientTelegramBindingDisable } from "./Actions/ClientTelegramBindingDisable";

interface IConfig {
  refetch(): void;
}

export function useColumns(config: IConfig): ColumnProps<IClientTelegramBindingsListItem>[] {
  const typeMap = useMemo<Record<ClientNotificationType, string>>(() => ({
    [ClientNotificationType.Balance]: 'Баланс',
    [ClientNotificationType.SuccessfulBinding]: 'Приветствие'
  }), [])

  return [
    {
      title: '#',
      key: 'index',
      width: 70,
      render(value, record, index) {
        return index + 1;
      },
    },
    {
      title: 'Пользователь',
      key: 'UserName',
      render(value, record, index) {
        return record.userName ?? '-'
      },
    },
    {
      title: 'Последнее уведомление',
      key: 'lastNotification',
      render(value, record, index) {
        return record.lastNotification === null ? '-' : (
          <Space direction='horizontal'>
            {typeMap[record.lastNotification?.type]}
            {'-'}
            {record.lastNotification?.date.local().format('DD.MM.yyyy HH:mm:ss')}
          </Space>
        )
      },
    },
    {
      title: 'Статус',
      key: 'status',
      render(value, record, index) {
        return record.alreadyBinded ? (
          record.disabled ? (
            <Badge status='default' text='Неактивная связь' />
          ) : (
            <Badge status='success' text='Активная связь' />
          )
        ) : (
          <Badge status='processing' text='Ожидаем связывания' />
        )
      },
    },
    {
      key: 'actions',
      align: 'center',
      render(value, record, index) {
        return (
          <Space direction='horizontal'>
            <ClientTelegramGetLink
              link={record.link}
              disabled={record.alreadyBinded}
            />
            <ClientTelegramBindingEnable
              bindingId={record.id}
              disabled={!record.alreadyBinded || !record.disabled}
              onSuccess={config.refetch}
            />
            <ClientTelegramBindingDisable
              bindingId={record.id}
              disabled={!record.alreadyBinded || record.disabled}
              onSuccess={config.refetch}
            />
          </Space>
        )
      },
    }
  ]
}