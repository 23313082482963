import React, { useState } from 'react';
import { Button, Card, Divider, Badge, Descriptions, Dropdown, Menu, Empty, Popconfirm, Input, Form, Row, Col, notification } from 'antd';
import { FileAddOutlined, DownloadOutlined, DownOutlined, FileDoneOutlined, FileOutlined, DeleteOutlined, QuestionCircleOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { PresetStatusColorType } from 'antd/lib/_util/colors';
import { IClientLicenseAgreement, LicenseAgreementState } from '../../../../types/backend/LicenseAgreements';
import useLicenseAgreementsApi from '../../../../hooks/backend/api/useLicenseAgreementsApi';
import { InfoPopover } from '../../../InfoPopover';
import saveFile from '../../../../services/saveFile';
import { DateTime } from 'luxon';

interface ILicenseAgreementProps {
  clientId: string;
  licenseAgreement: IClientLicenseAgreement | null;
  refreshClient: () => Promise<void>;
}

interface IRejectionForm {
  comment: string;
}

interface ITerminationForm {
  comment: string;
}

type ActionInProgress = "creation" | "downloading" | "signing" | "rejection" | "termination";

export function LicenseAgreement(props: ILicenseAgreementProps) {
  const { clientId, licenseAgreement, refreshClient } = props;
  const [actionInProgress, setActionInProgress] = useState<ActionInProgress | undefined>(undefined);
  const createNewButton = useCreation(clientId, actionInProgress, setActionInProgress, refreshClient);
  const downloadButton = useDownloading(licenseAgreement, actionInProgress, setActionInProgress, refreshClient);
  const signButton = useSigning(licenseAgreement, actionInProgress, setActionInProgress, refreshClient);
  const rejectButton = useRejection(licenseAgreement, actionInProgress, setActionInProgress, refreshClient);
  const terminateButton = useTermination(licenseAgreement, actionInProgress, setActionInProgress, refreshClient);

  const emptyContent = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={false} />
  const emptyActions = [createNewButton]

  const stateColors: Record<LicenseAgreementState, PresetStatusColorType> = {
    "1": 'default',
    "2": 'processing',
    "3": 'success',
    "4": 'error',
    "5": 'warning'
  };

  const stateActions: Record<LicenseAgreementState, JSX.Element[]> = {
    "1": [rejectButton, downloadButton],
    "2": [rejectButton, signButton, downloadButton],
    "3": [terminateButton, downloadButton],
    "4": [createNewButton],
    "5": [createNewButton],
  }

  return (
    <>
      <Divider orientation="center">Лицензионное соглашение</Divider>
      <Card
        bordered={false}
        size="small"
        actions={licenseAgreement ? stateActions[licenseAgreement.state] : emptyActions}
      >
        {
          licenseAgreement
            ? (
              <Descriptions bordered column={1} size="small">
                <Descriptions.Item label="Номер">{licenseAgreement.number}</Descriptions.Item>
                <Descriptions.Item label="Статус">
                  <Row gutter={8}>
                    <Col>
                      <Badge
                        status={stateColors[licenseAgreement.state]}
                        text={licenseAgreement.stateName}
                      />
                    </Col>
                    {
                      licenseAgreement.state === LicenseAgreementState.Rejected && licenseAgreement.rejectionComment && (
                        <Col>
                          <InfoPopover info={licenseAgreement.rejectionComment} />
                        </Col>
                      )
                    }
                    {
                      licenseAgreement.state === LicenseAgreementState.Terminated && licenseAgreement.terminationComment && (
                        <Col>
                          <InfoPopover info={licenseAgreement.terminationComment} />
                        </Col>
                      )
                    }
                  </Row>
                </Descriptions.Item>
              </Descriptions>
            )
            : emptyContent
        }
      </Card>
    </>
  )
}

function useCreation(
  clientId: string,
  actionInProgress: ActionInProgress | undefined,
  setActionInProgress: React.Dispatch<React.SetStateAction<ActionInProgress | undefined>>,
  refreshClient: () => Promise<void>
): JSX.Element {
  const { create } = useLicenseAgreementsApi();

  async function handleCreateNew() {
    setActionInProgress("creation");
    try {
      await create(clientId);
    }
    catch (error) {
      notification["error"]({ message: 'Не удалось создать соглашение' });
    }
    finally {
      setActionInProgress(undefined);
      await refreshClient();
    }
  }

  return (
    <Button
      icon={<FileAddOutlined />}
      loading={actionInProgress === "creation"}
      disabled={actionInProgress && actionInProgress !== "creation"}
      size="small"
      onClick={handleCreateNew}
    >
      Создать новое соглашение
    </Button>
  )
}

function useDownloading(
  licenseAgreement: IClientLicenseAgreement | null,
  actionInProgress: ActionInProgress | undefined,
  setActionInProgress: React.Dispatch<React.SetStateAction<ActionInProgress | undefined>>,
  refreshClient: () => Promise<void>
): JSX.Element {
  const { download } = useLicenseAgreementsApi();

  async function handleDownload(withStamp: boolean) {
    if (!licenseAgreement) return;

    setActionInProgress("downloading");
    try {
      const file = await download(licenseAgreement.id, withStamp);
      const fileName = `Соглашения ${licenseAgreement.number} от ${DateTime.now().toFormat('dd.MM.yyyy')}`;
      saveFile(file, 'application/zip', fileName, 'zip');
    }
    catch (error) {
      notification["error"]({ message: 'Не удалось скачать соглашение' });
    }
    finally {
      setActionInProgress(undefined);
      if (licenseAgreement.state === LicenseAgreementState.Created) {
        await refreshClient();
      }
    }
  }

  const downloadMenu = (
    <Menu>
      <Menu.Item
        key="with_stamp"
        icon={<FileDoneOutlined />}
        disabled={actionInProgress !== undefined}
        onClick={() => handleDownload(true)}
      >
        С печатью
      </Menu.Item>
      <Menu.Item
        key="without_stamp"
        icon={<FileOutlined />}
        disabled={actionInProgress !== undefined}
        onClick={() => handleDownload(false)}
      >
        Без печати
      </Menu.Item>
    </Menu >
  )

  return (
    <Dropdown
      overlay={downloadMenu}
      disabled={actionInProgress && actionInProgress !== "downloading"}
    >
      <Button
        icon={<DownloadOutlined />}
        loading={actionInProgress === "downloading"}
        disabled={actionInProgress && actionInProgress !== "downloading"}
        size="small"
      >
        Скачать <DownOutlined />
      </Button>
    </Dropdown>
  )
}

function useSigning(
  licenseAgreement: IClientLicenseAgreement | null,
  actionInProgress: ActionInProgress | undefined,
  setActionInProgress: React.Dispatch<React.SetStateAction<ActionInProgress | undefined>>,
  refreshClient: () => Promise<void>
): JSX.Element {
  const { sign } = useLicenseAgreementsApi();

  async function handleSign() {
    if (!licenseAgreement) return;

    setActionInProgress("signing");
    try {
      await sign(licenseAgreement.id);
    }
    catch (error) {
      notification["error"]({ message: 'Не удалось подписать соглашение' });
    }
    finally {
      setActionInProgress(undefined);
      await refreshClient();
    }
  }

  return (
    <Button
      icon={<CheckCircleOutlined />}
      loading={actionInProgress === "signing"}
      disabled={actionInProgress && actionInProgress !== "signing"}
      size="small"
      onClick={handleSign}
    >
      Подписать
    </Button>
  )
}

function useRejection(
  licenseAgreement: IClientLicenseAgreement | null,
  actionInProgress: ActionInProgress | undefined,
  setActionInProgress: React.Dispatch<React.SetStateAction<ActionInProgress | undefined>>,
  refreshClient: () => Promise<void>
): JSX.Element {
  const { reject } = useLicenseAgreementsApi();
  const [rejectionForm] = Form.useForm<IRejectionForm>();

  async function handleReject(comment: string) {
    if (!licenseAgreement) return;

    setActionInProgress("rejection");
    try {
      await reject(licenseAgreement.id, comment);
    }
    catch (error) {
      notification["error"]({ message: 'Не удалось отклонить соглашение' });
    }
    finally {
      setActionInProgress(undefined);
      await refreshClient();
    }
  }

  const popconfirmButtonsProps = {
    loading: actionInProgress === "rejection",
    disabled: actionInProgress && actionInProgress !== "rejection"
  }

  const rejectConfirmation = (
    <Form
      form={rejectionForm}
      initialValues={{ comment: '' }}
      onFinish={async (values) => {
        await handleReject(values.comment);
        rejectionForm.resetFields();
      }}
    >
      <span>Вы действительно хотите отклонить соглашение?</span>
      <Form.Item name="comment" label="Комментарий" style={{ marginBottom: 0 }}>
        <Input size="small" />
      </Form.Item>
    </Form>
  )

  return (
    <Popconfirm
      disabled={actionInProgress && actionInProgress !== "rejection"}
      title={rejectConfirmation}
      okText="Отклонить"
      okButtonProps={popconfirmButtonsProps}
      cancelButtonProps={popconfirmButtonsProps}
      cancelText="Отмена"
      onConfirm={() => rejectionForm.submit()}
      onCancel={() => rejectionForm.resetFields()}
      icon={<QuestionCircleOutlined style={{ color: 'orange' }} />}
    >
      <Button
        icon={<DeleteOutlined />}
        loading={actionInProgress === "rejection"}
        disabled={actionInProgress && actionInProgress !== "rejection"}
        size="small"
      >
        Отклонить
      </Button>
    </Popconfirm>
  )
}

function useTermination(
  licenseAgreement: IClientLicenseAgreement | null,
  actionInProgress: ActionInProgress | undefined,
  setActionInProgress: React.Dispatch<React.SetStateAction<ActionInProgress | undefined>>,
  refreshClient: () => Promise<void>
): JSX.Element {
  const { terminate } = useLicenseAgreementsApi();
  const [terminationForm] = Form.useForm<ITerminationForm>();

  async function handleTermiante(comment: string) {
    if (!licenseAgreement) return;

    setActionInProgress("termination");
    try {
      await terminate(licenseAgreement.id, comment);
    }
    catch (error) {
      notification["error"]({ message: 'Не удалось расторгнуть соглашение' });
    }
    finally {
      setActionInProgress(undefined);
      await refreshClient();
    }
  }

  const popconfirmButtonsProps = {
    loading: actionInProgress === "termination",
    disabled: actionInProgress && actionInProgress !== "termination"
  }

  const terminateConfirmation = (
    <Form
      form={terminationForm}
      initialValues={{ comment: '' }}
      onFinish={async (values) => {
        await handleTermiante(values.comment);
        terminationForm.resetFields();
      }}
    >
      <span>Вы действительно хотите расторгнуть соглашение?</span>
      <Form.Item name="comment" label="Комментарий" style={{ marginBottom: 0 }}>
        <Input size="small" />
      </Form.Item>
    </Form>
  )

  return (
    <Popconfirm
      disabled={actionInProgress && actionInProgress !== "termination"}
      title={terminateConfirmation}
      okText="Расторгнуть"
      okButtonProps={popconfirmButtonsProps}
      cancelButtonProps={popconfirmButtonsProps}
      cancelText="Отмена"
      onConfirm={() => terminationForm.submit()}
      onCancel={() => terminationForm.resetFields()}
      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
    >
      <Button
        icon={<CloseCircleOutlined />}
        loading={actionInProgress === "termination"}
        disabled={actionInProgress && actionInProgress !== "termination"}
        size="small"
      >
        Расторгнуть
      </Button>
    </Popconfirm>
  )
}