import { Button, Tag, Tooltip } from 'antd';
import { FormOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import React from 'react';
import { IClientsListItem } from '../../../types/backend/Clients';
import subscriptionStateColorMap from '../../../types/SubscriptionStateColorMap';

type TColumnProps = ColumnProps<IClientsListItem>;

export function getClientListColumns(redirectOnViewRequest: (id: string) => void,): TColumnProps[] {
  return [
    {
      title: '№',
      dataIndex: 'index',
      key: 'index',
      render: (text, record, index) => index + 1,
      width: 70,
    },
    {
      title: 'Наименование',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
    },
    {
      title: 'ИНН',
      dataIndex: 'inn',
      key: 'inn',
      width: 120
    },
    {
      title: 'Статус клиента',
      key: 'clientState',
      width: 100,
      render: (text, record) => (
        <Tag color={record.blocked ? 'error' : 'success'}>
          {record.blocked ? 'Заблокирован' : 'Активен'}
        </Tag>
      )
    },
    {
      title: 'Бесплатные запросы',
      key: 'freeRequestSettingsState',
      width: 100,
      render: (text, record) => (
        <Tag color={record.hasAvailableFreeRequests ? 'processing' : 'default'}>
          {record.hasAvailableFreeRequests ? 'Доступны' : 'Недоступны'}
        </Tag>
      )
    },
    {
      title: 'Статус подписки',
      key: 'subscriptionState',
      width: 100,
      render: (text, record) => {
        const color = subscriptionStateColorMap[record.subscriptionState];
        return <Tag color={color}>{record.subscriptionStateName}</Tag>
      }
    },
    {
      title: 'Период подписки',
      key: 'subscriptionPeriod',
      width: 180,
      render: (text, record) => (record.subscriptionPeriodStart !== null
        ? `${record.subscriptionPeriodStart.format('DD.MM.YYYY')}-${record.subscriptionPeriodEnd?.format('DD.MM.YYYY')}`
        : '-'
      )
    },
    {
      title: 'Баланс',
      dataIndex: 'currentBalance',
      key: 'currentBalance',
      sorter: true,
      render: (text, record) => (Intl.NumberFormat('en-us', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(record?.currentBalance ?? 0))
    },
    {
      title: 'Ответственный',
      dataIndex: 'managerName',
      key: 'managerName',
    },
    {
      title: 'Действия',
      key: 'action',
      width: 75,
      render: (text, record) => (
        <Tooltip title='Просмотреть'>
          <Button
            shape='circle'
            size='large'
            onClick={() => redirectOnViewRequest(record.id)}
            icon={<FormOutlined />}
          />
        </Tooltip>
      ),
    }
  ]
};