import React, { useEffect, useState, useRef } from 'react';
import { Card, Button, Descriptions, Modal, Alert, Form, Space, Input, Row, Col, Select } from 'antd';
import { EditOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { Wrapper, WrapperState } from '../../../Wrapper';
import useClientRequisitesApi from '../../../../hooks/backend/api/clients/useClientRequisitesApi';
import useReferencesApi from '../../../../hooks/backend/api/useReferencesApi';
import { EntrepreneurActivityType, IClientRequisites, IEntrepreneurActivityTypeReference, IUpdateRequisitesFromDadataForm } from '../../../../types/backend/Clients';
import { useParams } from 'react-router-dom';
import { ClientRequisitesEdit } from './ClientRequisitesEdit';
import useBackendForm from '../../../../hooks/useBackendForm';
import IBackendError from '../../../../types/BackendError';

export function ClientRequisites(props: { requisites: IClientRequisites | undefined, refreshClient: () => Promise<void> }) {
  const { requisites, refreshClient } = props;
  const clientRequisitesApi = useClientRequisitesApi();
  const referencesApi = useReferencesApi();
  const unmounted = useRef(false);
  const [wrapperState] = useState<WrapperState | null>(null);
  const params = useParams<{ id: string }>();
  const [editMode, setEditMode] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false);
  const backendForm = useBackendForm<IUpdateRequisitesFromDadataForm>();
  const [references, setReferences] = useState<IEntrepreneurActivityTypeReference[]>([]);
  const [innFormVisible, setInnFormVisible] = useState(false);

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  async function handleOnFinish(values: IUpdateRequisitesFromDadataForm) {
    if (!params?.id) return;

    setIsProcessing(true);
    backendForm.resetErrors();

    try {
      await clientRequisitesApi.editClientRequisitesInn(params.id, values);
      await updateFromDatata();
      setInnFormVisible(false);
    } catch (error) {
      if (!unmounted.current) backendForm.setErrors(error as IBackendError);
    }
    finally {
      if (!unmounted.current) setIsProcessing(false);
    }
  }

  function renderErrors() {
    return backendForm.generalErrors?.map((error, index) =>
      <Alert
        key={`login_error_${index}`}
        type="error"
        message={error}
      />
    )
  }

  async function updateRequisitesFromDadata() {
    if (!requisites?.inn) {
      backendForm.form.resetFields();

      let references = await referencesApi.getEntrepreneurActivityTypes();
      setReferences(references);
      setInnFormVisible(true);

    } else {
      await updateFromDatata();
    }
  }

  async function updateFromDatata() {
    if (!params?.id) return;

    var result = await clientRequisitesApi.updateRequesitesFromDadata(params.id);
    if (result) {
      await refreshClient();
    } else {
      Modal.info({
        title: 'По заданному ИНН, в базах "Дадаты", не уадлось найти компанию'
      })
    }

  }

  function handleOnEditEvent() {
    setEditMode(true)
  }

  return (
    <Wrapper state={wrapperState}>
      {editMode ? (
        <ClientRequisitesEdit
          requisites={requisites}
          setEditMode={setEditMode}
          refreshClient={refreshClient}
        />
      ) : (
        <Card bordered={false} extra={
          <Row gutter={8}>
            <Col span={12}>
              <Button
                type="default"
                icon={<EditOutlined />}
                onClick={handleOnEditEvent}
              >
                Редактировать реквизиты
              </Button>
            </Col>
            <Col span={12}>
              <Button
                icon={<CloudDownloadOutlined />}
                type="default"
                onClick={updateRequisitesFromDadata}
              >
                Загрузить через "дадата"
              </Button>
            </Col>
          </Row>
        }>
          <Descriptions title="Общее" column={1}>
            <Descriptions.Item label="Тип">{requisites?.entrepreneurActivityType}</Descriptions.Item>
            <Descriptions.Item label="ИНН">{requisites?.inn}</Descriptions.Item>
            <Descriptions.Item label="ОГРН">{requisites?.ogrn}</Descriptions.Item>
            {
              requisites?.entrepreneurActivityTypeValue === EntrepreneurActivityType.Company &&
              <Descriptions.Item label="Юридическое наименование">{requisites?.legalName}</Descriptions.Item>
            }
            <Descriptions.Item label="КПП">{requisites?.kpp}</Descriptions.Item>
          </Descriptions>
          <Descriptions title="Банк" column={1}>
            <Descriptions.Item label="Банк">{requisites?.bankName}</Descriptions.Item>
            <Descriptions.Item label="БИК">{requisites?.bik}</Descriptions.Item>
            <Descriptions.Item label="К/С">{requisites?.correspondentAccount}</Descriptions.Item>
            <Descriptions.Item label="Р/С">{requisites?.bankAccount}</Descriptions.Item>
          </Descriptions>
          <Descriptions title="Адреса" column={1} >
            <Descriptions.Item label="Юридический адрес">{requisites?.legalAddress}</Descriptions.Item>
            <Descriptions.Item label="Физический адрес">{requisites?.actualAddress}</Descriptions.Item>
            <Descriptions.Item label="Почтовый адрес">{requisites?.postalAddress}</Descriptions.Item>
          </Descriptions>
          <Descriptions title="Руководитель" column={1}>
            <Descriptions.Item label="ФИО">{requisites?.headFIO}</Descriptions.Item>
            {
              requisites?.entrepreneurActivityTypeValue === EntrepreneurActivityType.Company &&
              <>
                <Descriptions.Item label="Должность">{requisites?.headPosition}</Descriptions.Item>
                <Descriptions.Item label="Действует на основании">{requisites?.headDocument}</Descriptions.Item>
              </>
            }
            <Descriptions.Item label="Телефон">{requisites?.phoneNumber}</Descriptions.Item>
            <Descriptions.Item label="Email">{requisites?.email}</Descriptions.Item>
          </Descriptions>
        </Card>
      )}
      <Modal
        closable={false}
        destroyOnClose
        visible={innFormVisible}
        title='У клиента не заполнен ИНН'
        okText='Сохранить'
        okButtonProps={{ loading: isProcessing }}
        onOk={backendForm.form.submit}
        cancelText='Отмена'
        cancelButtonProps={{ disabled: isProcessing }}
        onCancel={() => setInnFormVisible(false)}
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          <Form
            initialValues={{ inn: '' }}
            form={backendForm.form}
            autoComplete='off'
            onFinish={handleOnFinish}
          >
            <Form.Item
              name="inn"
              label="ИНН"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value: string) {
                    if (value.length !== 10 && getFieldValue("entrepreneurActivityTypeValue") === EntrepreneurActivityType.Company) {
                      return Promise.reject("Длина должна быть 10 символов")
                    }
                    if (value.length !== 12 && getFieldValue("entrepreneurActivityTypeValue") === EntrepreneurActivityType.Individual) {
                      return Promise.reject("Длина должна быть 12 символов")
                    }
                    return Promise.resolve();
                  }
                })
              ]}
            >
              <Input inputMode='numeric' pattern='[0-9]*' />
            </Form.Item>
            <Form.Item
              name="entrepreneurActivityTypeValue"
              label="Тип"
            >
              <Select
                onChange={() => backendForm.validate("inn")}
                options={references.map(x => ({
                  value: x.value,
                  label: x.name
                }))}></Select>
            </Form.Item>
            <Form.Item>
              <Button
                block
                type="primary"
                htmlType="submit"
                loading={isProcessing}
              >
                Сохранить
              </Button>
            </Form.Item>
          </Form>
          {renderErrors()}
        </Space>
      </Modal>
    </Wrapper>
  )
}