import React, { useRef, useState, useEffect, useContext } from 'react';
import { Table, notification, TablePaginationConfig } from 'antd';
import { getTariffSettingsListColumns } from './TariffSettingsListColumns';
import { useTariffSettingsListConfig } from './TariffSettingsListConfig';
import { TariffSettingsConnection, ITariffSettingsConnectionResult } from './TariffSettingsConnection';
import { TariffSettingsBillingStrategy, ITariffSettingsBillingStrategyChangingResult } from './TariffSettingsBillingStrategy';
import { IClientTariffSettings } from '../../../../../types/backend/Clients';
import useClientTariffsApi from '../../../../../hooks/backend/api/clients/useClientTariffsApi';
import { IUserProfile } from '../../../../../hooks/useAuth';
import { defaultPage, defaultPageSize } from '../../../../../types/list/PagingConfig';
import { ProductListContext } from '../ClientProductList';
import moment from "moment";

interface ITariffSettingsListProps {
  currentUser: IUserProfile
  clientId: string;
  isLoading: boolean;
  setIsLoading(value: boolean): void;
  setInitError(error: any): void;
  isConnectionVisible: boolean;
  closeConnection(): void;
}

interface IBillingStrategyChanging {
  settingsId: string;
  type: number;
  tariffName: string;
}

interface ITableItem extends IClientTariffSettings {
  key: string;
}

export function TariffSettingsList(props: ITariffSettingsListProps) {
  const { clientId, isLoading, setIsLoading, setInitError, currentUser, isConnectionVisible, closeConnection } = props;
  const { listUpdated } = useContext(ProductListContext);

  const unmounted = useRef(false);
  const clientTariffsApi = useClientTariffsApi();
  const { paging, setPaging } = useTariffSettingsListConfig();
  const [totalRows, setTotalRows] = useState(0);
  const [items, setItems] = useState<ITableItem[]>([]);
  const [billingStrategyChanging, setBillingStrategyChanging] = useState<IBillingStrategyChanging | null>(null);

  async function fetchData() {
    if (isLoading) return;
    
    setInitError(undefined);
    setIsLoading(true);

    try {
      const receivedSettings = await clientTariffsApi.getClientTariffSettings(
        clientId, paging.page, paging.pageSize);

      if (!unmounted.current) {
        setItems(receivedSettings.list.map((item, index) =>
          ({ key: `tariffSettings_${index}`, ...item })));
          setTotalRows(receivedSettings.totalRowCount);
      }
  } catch (error) {
      if (!unmounted.current) setInitError(error);
    } finally {
      if (!unmounted.current) setIsLoading(false);
    }
  }

  useEffect(() => {
    return () => { unmounted.current = true; };
  }, []);

  useEffect(() => {
    if (currentUser?.canViewClients()) {
      fetchData();
    }
  }, [clientId, currentUser, listUpdated])

  function handleOnTariffDeactivation(settingsId: string) {
  }

  function handleOnBillingStrategyCahnge(settingsId: string, billingStrategyType: number, tariffName: string) {
    setBillingStrategyChanging({
      settingsId: settingsId,
      type: billingStrategyType,
      tariffName: tariffName
    });
  }

  function handleOnBillingStrategyCahngeOk(result: ITariffSettingsBillingStrategyChangingResult) {
    const tariffName = billingStrategyChanging?.tariffName;

    setBillingStrategyChanging(null);

    switch (result.type) {
      case "Changed":
        notification.open({
          type: 'info',
          message: `Модель тарификации для ${tariffName} изменена на "${result.selectedBillingStrategyName}"`
        });
        fetchData();
        break;
      case "Error":
        notification.open({
          type: 'error',
          message: `Не удалось изменить модель тарификации для ${tariffName} на "${result.selectedBillingStrategyName}"`
        });
        break;
    }
  }

  function handleOnChangeTable(pagination: TablePaginationConfig) {
    setPaging(pagination.current ?? defaultPage, pagination.pageSize ?? defaultPageSize);
  }

  function handleOnProductConnectionOk(result: ITariffSettingsConnectionResult) {
    closeConnection();
    switch (result.type) {
      case "Ok":
        notification.open({
          type: 'info',
          message: `Тариф ${result.tariffName} успешно подключен`
        });
        fetchData();
        break;
      case "AlreadyExists":
        notification.open({
          type: 'warning',
          message: `Другой тариф (${result.tariffName}) уже подключен`
        });
        break;
      case "Error":
        notification.open({
          type: 'error',
          message: `Не удалось подключить тариф ${result.tariffName}`
        });
        break;
    }
  }
  
  return (
    <>
      <Table
        size='small'
        columns={getTariffSettingsListColumns(
          handleOnTariffDeactivation,
          handleOnBillingStrategyCahnge)
        }
        rowClassName={record => record.startDate.isAfter(moment()) || record.endDate.isBefore(moment()) ? "disabled-row" : ""}
        loading={isLoading}
        dataSource={items}
        scroll={{ x: 900 }}
        onChange={handleOnChangeTable}
        pagination={{
          current: paging.page,
          position: ['bottomCenter'],
          pageSize: paging.pageSize,
          total: totalRows,
          showSizeChanger: false
        }}
      />
      <TariffSettingsConnection
        clientId={clientId}
        visible={isConnectionVisible}
        handleOnCancel={closeConnection}
        handleOnOk={handleOnProductConnectionOk}
      />
      <TariffSettingsBillingStrategy
        settingsId={billingStrategyChanging?.settingsId ?? null}
        type={billingStrategyChanging?.type ?? null}
        handleOnOk={handleOnBillingStrategyCahngeOk}
        handleOnCancel={() => setBillingStrategyChanging(null)}
      />
    </>
  )
}