import { atom, RecoilState, RecoilValueReadOnly, selector, SetterOrUpdater, useRecoilValue, useSetRecoilState } from "recoil";

export interface IParamsStoreConfig<TValues> {
  paramsAtom: RecoilState<TValues>;
  paramsSelector: RecoilValueReadOnly<TValues>;
}

export function buildParamsStores<TValues>(prefix: string, defaultValue: TValues): IParamsStoreConfig<TValues> {
  const paramsAtom = atom<TValues>({
    key: `${prefix}_paramsStore`,
    default: defaultValue
  })
  
  const paramsSelector = selector({
    key: `${prefix}_params`,
    get: ({ get }) => get(paramsAtom)
  })
  
  return {
    paramsAtom,
    paramsSelector,
  }
}

interface IParamsStore<TValues> {
  params: TValues;
  setParams: SetterOrUpdater<TValues>;
}

export function useParamsStore<TValues>(atom: RecoilState<TValues>, selector: RecoilValueReadOnly<TValues>): IParamsStore<TValues> {
  const params = useRecoilValue(selector);
  const setParams = useSetRecoilState(atom);

  return {
    params,
    setParams
  }
}