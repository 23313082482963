import React, { useEffect, useRef, useState } from 'react';
import { Table, TablePaginationConfig } from 'antd';
import { IUserProfile } from '../../../hooks/useAuth';
import useInvoicesApi from '../../../hooks/backend/api/useInvoicesApi';
import { IInvoiceListItem } from '../../../types/backend/Invoices';
import saveFile from '../../../services/saveFile';
import { defaultPage, defaultPageSize } from '../../../types/list/PagingConfig';
import { IPaging } from '../../../types/list/PagingConfig';
import { ColumnProps } from 'antd/lib/table';
import IInvoiceProcessing from '../../../types/InvoiceProcessing';

interface IInvoicesTableProps<TTableItem extends IInvoiceListItem> {
  currentUser: IUserProfile | null;
  invoices: TTableItem[];
  refresh: () => Promise<void>;
  isLoading: boolean;
  paging: IPaging;
  setPaging: (page: number, pageSize: number) => void;
  totalRows: number;
  getColumns: (
    currentUser: IUserProfile | null,
    downloadInvoice: (invoice: IInvoiceListItem) => void,
    confirmInvoice: (id: string) => void,
    cancelInvoice: (id: string) => void,
    invoiceProcessing: IInvoiceProcessing | null
  ) => ColumnProps<TTableItem>[];
}

export function InvoicesTable<TTableItem extends IInvoiceListItem>(props: IInvoicesTableProps<TTableItem>) {
  const { currentUser, invoices, refresh, isLoading, paging, setPaging, totalRows, getColumns } = props;
  const unmounted = useRef(false);
  const invoicesApi = useInvoicesApi();
  const [invoiceProcessing, setInvoiceProcessing] = useState<IInvoiceProcessing | null>(null);

  useEffect(() => {
    return () => { unmounted.current = true; };
  }, []);

  function handleOnChange(pagination: TablePaginationConfig) {
    setPaging(pagination.current ?? defaultPage, pagination.pageSize ?? defaultPageSize);
  }

  async function handleOnDownloadClick(invoice: IInvoiceListItem) {
    const file = await invoicesApi.download(invoice.id);
    const fileName = `Счет №Р${invoice.number} от ${invoice.created.format('DD.MM.YYYY')}`;
    saveFile(file, 'application/pdf', fileName, 'pdf');
  }

  async function handleOnConfirmClick(invoiceId: string) {
    setInvoiceProcessing({ invoiceId: invoiceId, action: "Confirmation" })
    try {
      await invoicesApi.confirm(invoiceId);
      await refresh();
    } catch (error) {

    }
    finally {
      if (!unmounted.current) setInvoiceProcessing(null);
    }
  }

  async function handleOnCancelClick(invoiceId: string) {
    setInvoiceProcessing({ invoiceId: invoiceId, action: "Cancellation" })
    try {
      await invoicesApi.cancel(invoiceId);
      await refresh();
    } catch (error) {

    } finally {
      if (!unmounted.current) setInvoiceProcessing(null);
    }
  }

  return (
    <Table
      size='small'
      columns={
        getColumns(
          currentUser,
          handleOnDownloadClick,
          handleOnConfirmClick,
          handleOnCancelClick,
          invoiceProcessing)}
      loading={isLoading}
      dataSource={invoices}
      scroll={{ x: 900 }}
      onChange={handleOnChange}
      pagination={{
        current: paging.page,
        position: ['bottomCenter'],
        pageSize: paging.pageSize,
        total: totalRows,
        showSizeChanger: false
      }}
    />
  );
}