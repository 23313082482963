import { useSetRecoilState, useRecoilValue } from 'recoil';
import IPagingConfig from '../../../../../types/list/PagingConfig';
import { buildPagedListConfigStores } from '../../../../../types/list/ListConfigStore';

const { pagingAtom, pagingSelector } = buildPagedListConfigStores('clientTariffSettingsList');

interface IConfig extends IPagingConfig { }

export function useTariffSettingsListConfig(): IConfig {
    const setPaging = useSetRecoilState(pagingAtom);
    const paging = useRecoilValue(pagingSelector);

    return {
        paging,
        setPaging: (page: number, pageSize: number) => setPaging({ page, pageSize }),
    }
}