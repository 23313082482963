import React, { useState } from 'react';
import { InputNumber } from 'antd';
import useDashboardApi from '../../../hooks/backend/api/useDashboardApi';
import { IClientWithoutRequests } from '../../../types/backend/Dashboard';
import { buildCollapsedStores } from '../stores/CollapsedStore';
import { buildParamsStores, useParamsStore } from '../stores/ParamsStore';
import { buildPagingStore } from '../stores/PagingStore';
import { DashboardPanel } from '../Common/DashboardPanel';

const daysCountMin = 1;
const daysCountDefault = 2;

const prefix = 'clientsWithouRequests';

const collapsedStoreConfig = buildCollapsedStores(prefix, true);
const pagingStoreConfig = buildPagingStore(prefix);
const { paramsAtom, paramsSelector } = buildParamsStores(prefix, daysCountDefault);

export function ClientsWithouRequests() {
  const { params, setParams } = useParamsStore(paramsAtom, paramsSelector);
  const dashboardApi = useDashboardApi();

  const [subTitle, setSubTitle] = useState(getSubTitleText());

  function getSubTitleText() {
    return `Дней: ${params}`;
  }

  function fetchData(page: number, pageSize: number) {
    setSubTitle(getSubTitleText());
    return dashboardApi.clientsWithoutRequests(params, page, pageSize);
  }

  function handleOnChangeDaysCount(value: number) {
    setParams(value === null ? daysCountMin : value);
  }

  function getItemDescription(item: IClientWithoutRequests) {
    return item.latestReportRequest
      ? `Последний запрос: ${item.latestReportRequest.local().format('DD.MM.yyyy HH:mm:ss')}`
      : 'Нет запросов';
  }

  function renderParams(isLoading: boolean) {
    return (
      <InputNumber
        disabled={isLoading}
        min={daysCountMin}
        max={90}
        precision={0}
        value={params}
        onChange={handleOnChangeDaysCount}
        onFocus={e => e.target.select()}
      />
    )
  }

  return (
    <DashboardPanel
      title='Перестал делать запросы'
      subTitle={subTitle}
      fetchData={fetchData}
      collapsedStoreConfig={collapsedStoreConfig}
      pagingStoreConfig={pagingStoreConfig}
      getItemDescription={getItemDescription}
      renderParams={renderParams}
    />
  )
}