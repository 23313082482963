import { useSetRecoilState, useRecoilValue } from 'recoil';
import IPagingConfig from '../../types/list/PagingConfig';
import IFilterConfig from '../../types/list/FilterConfig';
import { buildFilteredListConfigStores, buildPagedListConfigStores } from '../../types/list/ListConfigStore';

const prefix = 'invoiceList';

export interface IFilterValues {
    paymentPending: boolean;
}

const defaultFilter: IFilterValues = {
    paymentPending: false
}

const { pagingAtom, pagingSelector } = buildPagedListConfigStores(prefix);
const { filterIsOpenAtom, filterIsOpenSelector, filterAtom, filterSelector } = buildFilteredListConfigStores(prefix, defaultFilter);

interface IConfig extends IPagingConfig, IFilterConfig<IFilterValues> { }

export function useInvoiceListConfig(): IConfig {
  const setPaging = useSetRecoilState(pagingAtom);
  const paging = useRecoilValue(pagingSelector);
  const setFilterIsOpen = useSetRecoilState(filterIsOpenAtom);
  const filterIsOpen = useRecoilValue(filterIsOpenSelector);
  const setFilter = useSetRecoilState(filterAtom);
  const filter = useRecoilValue(filterSelector);

  return {
    isFilterOpen: filterIsOpen,
    openFilter: () => setFilterIsOpen(true),
    closeFilter: () => setFilterIsOpen(false),
    filter: filter,
    resetFilter: () => setFilter(defaultFilter),
    setFilter: (values: IFilterValues) => setFilter(values),
    paging,
    setPaging: (page: number, pageSize: number) => setPaging({ page, pageSize }),
  }
}