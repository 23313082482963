import moment from 'moment';
import { IClientBalanceLeftForSomeDays, IClientBalanceLessThanLimit, IClientWithoutRequests } from '../../../types/backend/Dashboard';
import IPagedList from '../../../types/backend/PagedList';
import useBackendRequest from '../useBackendRequest';

interface IClientBalanceLessThanLimitResponse {
  clientId: string;
  clientName: string;
  managerName: string;
  balance: number;
}

interface IClientWithoutRequestsResponse {
  clientId: string;
  clientName: string;
  managerName: string;
  latestReportRequest: Date | null;
}

interface IClientBalanceLeftForSomeDaysResponse {
  clientId: string;
  clientName: string;
  managerName: string;
  balance: number;
  billingPeriodStartDate: Date;
  billingPeriodEndDate: Date;
  averageDailyConsumption: number;
}

interface IDashboardApi {
  clientsBalanceLeftForSomeDays(daysCount: number, page: number, pageSize: number): Promise<IPagedList<IClientBalanceLeftForSomeDays>>;
  clientsBalanceLessThan(limit: number, page: number, pageSize: number): Promise<IPagedList<IClientBalanceLessThanLimit>>;
  clientsWithoutRequests(daysCount: number, page: number, pageSize: number): Promise<IPagedList<IClientWithoutRequests>>;
}

export default function useDashboardApi(): IDashboardApi {
  const { makeRequest } = useBackendRequest();

  function clientsBalanceLeftForSomeDays(daysCount: number, page: number, pageSize: number) {
    const queryString = new URLSearchParams({
      daysCount: daysCount.toString(),
      page: page.toString(),
      pageSize: pageSize.toString()
    })
    return makeRequest<IPagedList<IClientBalanceLeftForSomeDaysResponse>, IPagedList<IClientBalanceLeftForSomeDays>>(
      `/api/dashboard/clientsBalanceLeftForSomeDays?${queryString}`,
      "get",
      response => {
        const list = response.list.map(item => ({
          ...item,
          billingPeriodStartDate: moment(item.billingPeriodStartDate),
          billingPeriodEndDate: moment(item.billingPeriodEndDate)
        }))

        return {
          ...response,
          list
        }
      },
      {}
    )
  }

  function clientsBalanceLessThan(limit: number, page: number, pageSize: number) {
    const queryString = new URLSearchParams({
      limit: limit.toString(),
      page: page.toString(),
      pageSize: pageSize.toString()
    })
    return makeRequest<IPagedList<IClientBalanceLessThanLimitResponse>, IPagedList<IClientBalanceLessThanLimit>>(
      `/api/dashboard/clientsBalanceLessThan?${queryString}`,
      "get",
      response => response,
      {}
    )
  }

  function clientsWithoutRequests(daysCount: number, page: number, pageSize: number) {
    const queryString = new URLSearchParams({
      daysCount: daysCount.toString(),
      page: page.toString(),
      pageSize: pageSize.toString()
    })
    return makeRequest<IPagedList<IClientWithoutRequestsResponse>, IPagedList<IClientWithoutRequests>>(
      `/api/dashboard/clientsWithoutRequests?${queryString}`,
      "get",
      response => {
        const list = response.list.map(item => ({
          ...item,
          latestReportRequest: item.latestReportRequest ? moment(item.latestReportRequest) : null
        }))

        return {
          ...response,
          list: list
        }
      },
      {}
    )
  }

  return {
    clientsBalanceLeftForSomeDays,
    clientsBalanceLessThan,
    clientsWithoutRequests
  }
}