import useBackendRequest from "../useBackendRequest";
import { ITariff, ICreatedTariff } from '../../../types/backend/Tariffs';

interface ITariffResponse {
  id: string;
  name: string;
}

interface ICreatedTariffResponse {
  id: string;
  name: string;
}

interface ITariffsApi {
  getTariffs(productId?: string): Promise<ITariff[]>;
  createSpecialTariff(productId: string, price: number): Promise<ICreatedTariff>;
}

export default function useTariffsApi(): ITariffsApi {
  const { makeRequest } = useBackendRequest();

  function getTariffs(productId?: string) {
    const query = productId !== undefined ? `?productId=${productId}` : null;
    return makeRequest<ITariffResponse[], ITariff[]>(
      `/api/tariffs${query}`,
      'get',
      response => response,
      {}
    );
  }

  function createSpecialTariff(productId: string, price: number) {
    return makeRequest<ICreatedTariffResponse, ICreatedTariff>(
      '/api/tariffs/specialTariffs',
      'post',
      response => response,
      {
        productId: productId,
        price: price
      }
    );
  }

  return {
    getTariffs,
    createSpecialTariff
  }
}