import React, { useEffect, useState, useRef, createContext, createRef } from 'react';
import { useParams } from 'react-router-dom';
import moment, { Moment } from 'moment';
import { Card, Button, PageHeader, Radio, Row, Col, RadioChangeEvent, Tooltip } from 'antd';
import { AppstoreAddOutlined, RedoOutlined } from '@ant-design/icons';
import useAuth, { IUserProfile } from '../../../../hooks/useAuth';
import { Wrapper, WrapperState } from '../../../Wrapper';
import { TariffSettingsList } from './TariffSettings/TariffSettingsList';
import { FreeRequestSettingsList } from './FreeRequestSettings/FreeRequestSettingsList';

interface IProductListContext {
  listUpdated: Moment
}

export const ProductListContext = createContext({} as IProductListContext);

export type ProductListTarget = "TariffSettings" | "FreeRequestSettings";

export function ClientProductList() {
  const unmounted = useRef(false);
  const { currentUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [initError, setInitError] = useState<any>(undefined);
  const [wrapperState, setWrapperState] = useState<WrapperState | null>(null);
  const params = useParams<{ id: string }>();
  const [isConnectionVisible, setIsConnectionVisible] = useState<ProductListTarget | null>(null);
  const [target, setTarget] = useState<ProductListTarget>("TariffSettings");
  const [listUpdated, setListUpdated] = useState(moment());

  useEffect(() => {
    return () => { unmounted.current = true; };
  }, []);

  useEffect(() => {
    if (currentUser === null) setWrapperState('loading');
    else if (!currentUser.canViewClients()) setWrapperState('accessDenied');
    else if (initError) setWrapperState('hasErrors');
    else setWrapperState(null);
  }, [currentUser, initError]);

  function handleOnTargetChanged(e: RadioChangeEvent) {
    setTarget(e.target.value);
  }

  function handleOnProductConnect() {
    setIsConnectionVisible(target);
  }

  function renderList() {
    if (!params?.id || !currentUser) return null;

    switch (target) {
      case "TariffSettings": return renderTariffSettings(params.id, currentUser);
      case "FreeRequestSettings": return renderFreeRequestSettings(params.id, currentUser);
      default: return null;
    }
  }

  function renderTariffSettings(clientId: string, currentUser: IUserProfile) {
    return (
      <TariffSettingsList
        currentUser={currentUser}
        clientId={clientId}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setInitError={setInitError}
        isConnectionVisible={isConnectionVisible === "TariffSettings"}
        closeConnection={() => setIsConnectionVisible(null)}
      />
    )
  }

  function renderFreeRequestSettings(clientId: string, currentUser: IUserProfile) {
    return <FreeRequestSettingsList
      currentUser={currentUser}
      clientId={clientId}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      setInitError={setInitError}
      isConnectionVisible={isConnectionVisible === "FreeRequestSettings"}
      closeConnection={() => setIsConnectionVisible(null)}
    />;
  }

  return (
    <ProductListContext.Provider value={{ listUpdated: listUpdated }}>
      <Wrapper state={wrapperState} error={initError}>
        <Card
          bordered={false}
          title={(
            <PageHeader
              title={(
                <Row gutter={12}>
                  <Col>
                    <Radio.Group value={target} onChange={handleOnTargetChanged}>
                      <Radio.Button value="TariffSettings">Подписка</Radio.Button>
                      <Radio.Button value="FreeRequestSettings">Бесплатные запросы</Radio.Button>
                    </Radio.Group>
                  </Col>
                  <Col>
                    <Tooltip title='Обновить'>
                      <Button
                        loading={isLoading}
                        shape='circle'
                        icon={<RedoOutlined />}
                        onClick={() => setListUpdated(moment())}
                      />
                    </Tooltip>
                  </Col>
                </Row>
              )}
              extra={
                currentUser?.canEditClients() && (
                  <Button
                    disabled={isLoading}
                    type="primary"
                    size='large'
                    onClick={handleOnProductConnect}
                    icon={<AppstoreAddOutlined />}
                  >
                    Подключить
                  </Button>
                )
              }
            />
          )}
        >
          {renderList()}
        </Card>
      </Wrapper>
    </ProductListContext.Provider>
  )
}