import moment, { Moment } from 'moment';
import { IClientFreeRequestSettings } from '../../../../types/backend/Clients';
import IPagedList from '../../../../types/backend/PagedList';
import useBackendRequest from '../../useBackendRequest';

interface IClientFreeRequestSettingsResponse {
  id: string;
  created: Date;
  userName: string;
  productName: string;
  count: number;
  isClosed: boolean;
  closedByUserName: string;
  comment: string;
}

interface IClientFreeRequestsApi {
  getList(clientId: string, page: number, pageSize: number): Promise<IPagedList<IClientFreeRequestSettings>>;
  add(clientId: string, productId: string, count: number, comment: string): Promise<void>;
  disable(settingsId: string): Promise<void>;
  disableAll(clientId: string): Promise<void>;
}

export default function useClientFreeRequestsApi(): IClientFreeRequestsApi {
  const { makeRequest } = useBackendRequest();

  function getList(clientId: string, page: number, pageSize: number): Promise<IPagedList<IClientFreeRequestSettings>> {
    return makeRequest<IPagedList<IClientFreeRequestSettingsResponse>, IPagedList<IClientFreeRequestSettings>>(
      `/api/clients/${clientId}/freeRequestSettings?page=${page}&pageSize=${pageSize}`,
      "get",
      response => {
        return {
          ...response,
          list: response.list.map<IClientFreeRequestSettings>(value => ({
            ...value,
            created: moment(value.created)
          }))
        }
      },
      {}
    )
  }

  function add(clientId: string, productId: string, count: number, comment: string): Promise<void> {
    return makeRequest<void, void>(
      `/api/clients/${clientId}/freeRequestSettings/add`,
      "post",
      () => { },
      {
        productId: productId,
        count: count,
        comment: comment === '' ? null : comment
      }
    )
  }

  function disableAll(clientId: string): Promise<void> {
    return makeRequest<void, void>(
      `/api/clients/${clientId}/freeRequestSettings/disable`,
      "post",
      () => { },
      {}
    )
  }

  function disable(settingsId: string): Promise<void> {
    return makeRequest<void, void>(
      `/api/clients/freeRequestSettings/${settingsId}/disable`,
      "post",
      () => { },
      {}
    )
  }

  return {
    getList,
    add,
    disable,
    disableAll
  }
}