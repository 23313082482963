import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

export function RequireAuth({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const location = useLocation();

  return auth.isAuthenticated
    ? children
    : <Navigate to="/login" state={{ from: location }} replace />
}