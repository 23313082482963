import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './PageLoading.scss';
import React from 'react';

const antIcon = <LoadingOutlined className="page-loading__icon" spin />;

export function PageLoading() {
  return (
    <div className="page-loading">
      <Spin indicator={antIcon} />
    </div>
  );
}