import { Badge, BadgeProps, Button, Collapse, Empty, Space, Switch, Table } from "antd";
import { PlayCircleOutlined, StopOutlined } from '@ant-design/icons';
import React, { useEffect, useMemo, useRef, useState } from "react";
import { ClientTelegramNotificationsList } from "./ClientTelegramNotificationsList";
import { useClientNotificationsApi } from "../../../../hooks/backend/api/clients/useClientNotificationsApi";
import useAuth from "../../../../hooks/useAuth";
import { IClientNotificationSettings, IClientTariffSettings } from "../../../../types/backend/Clients";
import { Wrapper, WrapperState } from "../../../Wrapper";
import { ClientNotificationSettingsEnable } from "./Actions/ClientNotificationSettingsEnable";
import { ClientNotificationSettingsDisable } from "./Actions/ClientNotificationSettingsDisable";

interface IClientNotificationsProps {
  clientId: string;
}

const telegramKey = 'telegram';

export function ClientNotifications(props: IClientNotificationsProps) {
  const { clientId } = props;

  const { currentUser } = useAuth();

  const unmounted = useRef(false);
  useEffect(() => {
    return () => { unmounted.current = true; };
  }, []);

  const api = useClientNotificationsApi();

  const [settings, setSettings] = useState<IClientNotificationSettings | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [initError, setInitError] = useState<any>(undefined);

  async function fetchData() {
    if (isLoading) return;

    setInitError(undefined);
    setIsLoading(true);

    try {
      const received = await api.getSettings(clientId);

      if (!unmounted.current) setSettings(received);
    } catch (error) {
      if (!unmounted.current) setInitError(error);
    } finally {
      if (!unmounted.current) setIsLoading(false);
    }
  }

  useEffect(() => {
    if (currentUser?.canViewClients()) fetchData();
  }, [clientId, currentUser])

  const wrapperState = useMemo<WrapperState | null>(() => {
    if (currentUser === null) return 'loading';
    else if (isLoading) return 'loading';
    else if (!currentUser.canViewClients()) return 'accessDenied';
    else if (initError) return 'hasErrors';
    else return null;
  }, [currentUser, isLoading, initError])

  return (
    <Wrapper state={wrapperState} error={initError}>
      {settings === null ? (
        <Empty />
      ) : (
        <>
          <Space
            style={{ paddingBottom: '1rem' }}
            direction='horizontal'
          >
            {settings.disabled ? (
              <Badge status='default' text='Уведомления выключены' />
            ) : (
              <Badge status='success' text='Уведомления включены' />
            )}
            {settings.disabled ? (
              <ClientNotificationSettingsEnable
                settingsId={settings.id}
                onSuccess={fetchData}
              />
            ) : (
              <ClientNotificationSettingsDisable
                settingsId={settings.id}
                onSuccess={fetchData}
              />
            )}
          </Space>
          <Collapse
            defaultActiveKey={[
              telegramKey
            ]}
          >
            <Collapse.Panel header='Telegram' key={telegramKey}>
              <ClientTelegramNotificationsList clientId={clientId} />
            </Collapse.Panel>
          </Collapse>
        </>
      )}
    </Wrapper>
  )
}