import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Tooltip, notification } from "antd";
import { PlayCircleOutlined, StopOutlined } from '@ant-design/icons';
import { useClientNotificationsApi } from "../../../../../hooks/backend/api/clients/useClientNotificationsApi";

interface IClientNotificationSettingsDisableProps {
  settingsId: string;
  onSuccess?(): void;
}

export function ClientNotificationSettingsDisable(props: IClientNotificationSettingsDisableProps) {
  const { settingsId, onSuccess } = props;

  const unmounted = useRef(false);
  useEffect(() => {
    return () => { unmounted.current = true; };
  }, []);

  const api = useClientNotificationsApi();

  const [isProcessing, setIsProcessing] = useState(false);

  const handleOnClick = useCallback(async () => {
    setIsProcessing(true);
    let successful = true;
    try {
      await api.disable(settingsId);
    } catch (error) {
      successful = false;
      console.error(error);
      notification.error({
        message: 'Не удалось отключить уведомления'
      })
    } finally {
      if (!unmounted.current) setIsProcessing(false);
    }

    if (successful && onSuccess) onSuccess();
  }, [unmounted, api, setIsProcessing])

  return (
    <Button
    loading={isProcessing}
    icon={<StopOutlined />}
    onClick={handleOnClick}
  >
    Выключить
  </Button>
)
}