import React, { useState } from 'react';
import { InputNumber } from 'antd';
import useDashboardApi from '../../../hooks/backend/api/useDashboardApi';
import { IClientBalanceLessThanLimit } from '../../../types/backend/Dashboard';
import { buildCollapsedStores } from '../stores/CollapsedStore';
import { buildParamsStores, useParamsStore } from '../stores/ParamsStore';
import { buildPagingStore } from '../stores/PagingStore';
import { DashboardPanel } from '../Common/DashboardPanel';

const balanceLimitMin = 0;

const prefix = 'balanceLessThan';

const collapsedStoreConfig = buildCollapsedStores(prefix, true);
const pagingStoreConfig = buildPagingStore(prefix);
const { paramsAtom, paramsSelector } = buildParamsStores(prefix, balanceLimitMin);

export function BalanceLessThan() {
  const { params, setParams } = useParamsStore(paramsAtom, paramsSelector);
  const dashboardApi = useDashboardApi();

  const [subTitle, setSubTitle] = useState(getSubTitleText);

  function getSubTitleText() {
    return `<= ${params} руб.`;
  }

  function fetchData(page: number, pageSize: number) {
    setSubTitle(getSubTitleText());
    return dashboardApi.clientsBalanceLessThan(params, page, pageSize);
  }

  function handleOnChangeBalanceLimit(value: number) {
    setParams(value === null ? balanceLimitMin : value);
  }

  function getItemDescription(item: IClientBalanceLessThanLimit) {
    const balanceStr = Intl.NumberFormat('en-us', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      .format(item.balance ?? 0);
    return `Баланс: ${balanceStr} руб.`;
  }

  function renderParams(isLoading: boolean) {
    return (
      <InputNumber
        disabled={isLoading}
        min={balanceLimitMin}
        precision={2}
        value={params}
        onChange={handleOnChangeBalanceLimit}
        onFocus={e => e.target.select()}
      />
    )
  }

  return (
    <DashboardPanel
      title='Баланс'
      subTitle={subTitle}
      fetchData={fetchData}
      collapsedStoreConfig={collapsedStoreConfig}
      pagingStoreConfig={pagingStoreConfig}
      getItemDescription={getItemDescription}
      renderParams={renderParams}
    />
  )
}