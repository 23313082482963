import React, { useEffect, useState, useRef } from 'react';
import { Modal, Form, Row, Col, Select, InputNumber, Alert, Space } from 'antd';
import useProductsApi from '../../../../../hooks/backend/api/useProductsApi';
import { IProduct } from '../../../../../types/backend/Products';
import useTariffsApi from '../../../../../hooks/backend/api/useTariffsApi';
import useClientTariffsApi from '../../../../../hooks/backend/api/clients/useClientTariffsApi';
import { ITariff } from '../../../../../types/backend/Tariffs';
import useAuth from '../../../../../hooks/useAuth';
import { Wrapper, WrapperState } from '../../../../Wrapper';
import emptyGuid, { isEmptyGuid } from '../../../../../types/EmptyGuid';
import useReferencesApi from '../../../../../hooks/backend/api/useReferencesApi';
import { IBillingStrategyType } from '../../../../../types/backend/Clients';
import useBackendForm from '../../../../../hooks/useBackendForm';

type TariffSettingsConnectionResultType = "Ok" | "Error" | "AlreadyExists";

export interface ITariffSettingsConnectionResult {
  type: TariffSettingsConnectionResultType;
  tariffName: string;
  productName: string;
}

const zeroProduct = { id: emptyGuid, name: '-', hasTariffs: false };

interface ITariffItem extends ITariff {
  index: number;
}

const zeroTariffIndex = -1;
const zeroTariff: ITariffItem = {
  index: zeroTariffIndex,
  id: emptyGuid,
  name: '-'
};

const newSpecialTariffIndex = -2;
const newSpecialTariff: ITariffItem = {
  index: newSpecialTariffIndex,
  id: emptyGuid,
  name: 'Новый специальный тариф'
};

const zeroBillingStrategyType = -1;
const zeroBillingStrategy = {
  name: '-',
  value: zeroBillingStrategyType
}

interface ITariffSettingsConnectionForm {
  ProductId: string;
  TariffId: number;
  BillingStrategyType: number;
  Price: number;
}

interface ITariffSettingsConnectionProps {
  clientId: string;
  visible: boolean;
  handleOnCancel: () => void;
  handleOnOk: (result: ITariffSettingsConnectionResult) => void;
}

export function TariffSettingsConnection(props: ITariffSettingsConnectionProps) {
  const unmounted = useRef(false);
  const { currentUser } = useAuth();
  const productsApi = useProductsApi();
  const tariffsApi = useTariffsApi();
  const clientTariffsApi = useClientTariffsApi();
  const referencesApi = useReferencesApi();

  const [initError, setInitError] = useState<any>(undefined);
  const [wrapperState, setWrapperState] = useState<WrapperState | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [products, setProducts] = useState<IProduct[]>([]);

  const [isTariffsLoading, setIsTariffsLoading] = useState(false);
  const [tariffs, setTariffs] = useState<ITariffItem[]>([]);

  const [isPriceVisible, setIsPriceVisible] = useState(false);

  const [billingStrategyTypes, setBillingStrategyTypes] = useState<IBillingStrategyType[]>([]);

  const [isProcessing, setIsProcessing] = useState(false);

  const backendForm = useBackendForm<ITariffSettingsConnectionForm>();

  useEffect(() => {
    return () => { unmounted.current = true; };
  }, []);

  useEffect(() => {
    async function fetchData() {
      setInitError(undefined);
      setIsLoading(true);

      try {
        if (props.visible) {
          const receivedProducts = await productsApi.getProducts();
          const receivedBillingStrategyTypes = await referencesApi.getBillingStrategyTypes();

          if (!unmounted.current) {
            setProducts([zeroProduct, ...receivedProducts]);
            setBillingStrategyTypes([zeroBillingStrategy, ...receivedBillingStrategyTypes]);
          }
        }
      } catch (error) {
        if (!unmounted.current) setInitError(error);
      } finally {
        if (!unmounted.current) setIsLoading(false);
      }
    }

    setProducts([zeroProduct]);
    setTariffs([zeroTariff]);
    setIsPriceVisible(false);

    backendForm.form.setFieldsValue({
      ProductId: emptyGuid,
      TariffId: zeroTariffIndex,
      Price: 0,
      BillingStrategyType: zeroBillingStrategyType
    });

    if (currentUser?.canViewClients() && props.visible) {
      fetchData();
    }
  }, [currentUser, props.visible]);

  useEffect(() => {
    if (currentUser === null) setWrapperState('loading');
    else if (!currentUser.canViewClients()) setWrapperState('accessDenied');
    else if (isLoading) setWrapperState('loading');
    else if (initError) setWrapperState('hasErrors');
    else setWrapperState(null);
  }, [currentUser, initError, isLoading]);

  async function handleOnFinish(value: ITariffSettingsConnectionForm) {
    const { ProductId, TariffId, Price, BillingStrategyType } = value;

    setIsProcessing(true);

    const productName = products.find(item => item.id === ProductId)?.name ?? 'Неизвестно';

    const currentTariff = tariffs.find(item => item.index === TariffId);
    let tariffName = currentTariff?.name ?? 'Неизветсно';

    if (!currentTariff) {
      setIsProcessing(false);
      props.handleOnOk({ type: "Error", tariffName: tariffName, productName: productName });
      return;
    };

    let tariffId = currentTariff.id;

    let successful = true;

    try {
      const existingTariffName = await clientTariffsApi.checkClientTariffSettings(props.clientId, ProductId);

      if (existingTariffName) {
        props.handleOnOk({ type: "AlreadyExists", tariffName: existingTariffName, productName: productName });
        return;
      }

      if (currentTariff.index === newSpecialTariffIndex) {
        const createdSpecialTariff = await tariffsApi.createSpecialTariff(ProductId, Price);
        tariffId = createdSpecialTariff.id;
        tariffName = createdSpecialTariff.name;
      }

      await clientTariffsApi.addClientTariffSettings(props.clientId, tariffId, BillingStrategyType);
    }
    catch (error) {
      successful = false;
    }
    finally {
      if (!unmounted.current) setIsProcessing(false);
    }

    if (successful) {
      props.handleOnOk({ type: "Ok", tariffName: tariffName, productName: productName });
    }
  }

  async function handleOnChangeProduct(value: string) {
    setInitError(undefined);
    setIsTariffsLoading(true);
    setTariffs([zeroTariff]);
    setIsPriceVisible(false);

    try {
      if (!isEmptyGuid(value)) {
        const receivedTariffs = (await tariffsApi.getTariffs(value))
          .map<ITariffItem>((tariff, index) => ({
            index: index,
            id: tariff.id,
            name: tariff.name
          }));

        if (!unmounted.current) {
          if (currentUser?.canEditSpecialTariffs()) setTariffs([zeroTariff, newSpecialTariff, ...receivedTariffs]);
          else setTariffs([zeroTariff, ...receivedTariffs]);
        }
      }
    } catch (error) {
      if (!unmounted.current) setInitError(undefined);
    } finally {
      if (!unmounted.current) setIsTariffsLoading(false);
    }
  }

  function handleOnChangeTariff(value: number) {
    setIsPriceVisible(value === newSpecialTariffIndex);
  }

  function renderErrors() {
    return backendForm.generalErrors?.map((error, index) =>
      <Alert
        key={`error_${index}`}
        type="error"
        message={error}
      />
    )
  }

  return (
    <Modal
      destroyOnClose
      visible={props.visible}
      closable={false}
      maskClosable={false}
      title='Подключение нового продукта'
      okText='Подключить'
      okButtonProps={{ loading: isProcessing }}
      onOk={backendForm.form.submit}
      cancelText='Отмена'
      cancelButtonProps={{ disabled: isProcessing }}
      onCancel={props.handleOnCancel}
    >
      <Wrapper state={wrapperState} error={initError}>
        <Space direction='vertical' style={{ width: '100%' }}>
          <Form
            form={backendForm.form}
            onFinish={handleOnFinish}
            labelCol={{ span: 9 }}
          >
            <Form.Item
              label='Продукт'
              name='ProductId'
              rules={[
                { required: true, message: 'Обязательное поле' },
                () => ({
                  validator(_, value) {
                    if (value === emptyGuid) return Promise.reject('Необходимо выбрать продукт');
                    return Promise.resolve();
                  },
                })
              ]}
            >
              <Select
                onChange={handleOnChangeProduct}
                options={products.map(value => ({ label: value.name, value: value.id }))}
              />
            </Form.Item>
            <Form.Item
              label='Тариф'
              name='TariffId'
              rules={[
                { required: true, message: 'Обязательное поле' },
                () => ({
                  validator(_, value) {
                    if (value === zeroTariffIndex) return Promise.reject('Необходимо выбрать тариф');
                    return Promise.resolve();
                  },
                })
              ]}
            >
              <Select
                loading={isTariffsLoading}
                onChange={handleOnChangeTariff}
                options={tariffs.map(value => ({ label: value.name, value: value.index }))}
              />
            </Form.Item>
            {
              isPriceVisible && (
                <Form.Item
                  name='Price'
                  label='Цена'
                  rules={[
                    { required: true, message: 'Обязательное поле' },
                    () => ({
                      validator(_, value) {
                        if (value > 0) return Promise.resolve();
                        return Promise.reject('Необходимо указать цену');
                      },
                    })
                  ]}
                >
                  <InputNumber
                    decimalSeparator='.'
                    parser={
                      value => value ? Number(value.replace(',', '.')) : 0
                    }
                    precision={2}
                    step={1}
                    min={0}
                    onFocus={e => e.target.select()}
                  />
                </Form.Item>
              )
            }
            <Form.Item
              name='BillingStrategyType'
              label='Модель тарификации'
              rules={[
                { required: true, message: 'Обязательное поле' },
                () => ({
                  validator(_, value) {
                    if (value === zeroBillingStrategyType) {
                      return Promise.reject('Необходимо выбрать модель тарификации');
                    }
                    return Promise.resolve();
                  },
                })
              ]}
            >
              <Select
                options={billingStrategyTypes.map(t => ({ label: t.name, value: t.value }))}
              />
            </Form.Item>
          </Form>
          {renderErrors()}
        </Space>
      </Wrapper>
    </Modal>
  )
}