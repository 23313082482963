import React from "react";
import { PageHeader } from "antd";
import { useEffect, useRef, useState } from "react";
import useAuth from '../../hooks/useAuth';
import useInvoicesApi from '../../hooks/backend/api/useInvoicesApi';
import { IInvoiceWithClientListItem } from '../../types/backend/Invoices';
import { Wrapper, WrapperState } from "../Wrapper";
import { getInvoiceListColumns } from './InvoiceListColumns';
import { useInvoiceListConfig, IFilterValues } from "./InvoiceListConfig";
import { InvoiceListFilter } from './InvoiceListFilter';
import { InvoicesTable } from './Common';

interface IInvoicesTableItem extends IInvoiceWithClientListItem {
    key: string;
}

export function InvoiceList() {
    const invoicesApi = useInvoicesApi();
    const { paging, setPaging, filter, setFilter } = useInvoiceListConfig();
    const { currentUser } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [initError, setInitError] = useState<any>(undefined);
    const [wrapperState, setWrapperState] = useState<WrapperState | null>(null);
    const [invoices, setInvoices] = useState<IInvoicesTableItem[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);
    const unmounted = useRef(false);

    useEffect(() => {
        return () => { unmounted.current = true; };
    }, []);

    async function fetchData() {
        try {
            setInitError(undefined);
            setIsLoading(true);
            const receivedInvoices = await invoicesApi.get(paging.page, paging.pageSize, filter);
            const tableItems = receivedInvoices.list.map((value, index) => ({ key: `invoice_${index}`, ...value }));

            if (!unmounted.current) {
                setInvoices(tableItems);
                setTotalRows(receivedInvoices.totalRowCount);
            }
        } catch (error) {
            if (!unmounted.current) setInitError(error);
        } finally {
            if (!unmounted.current) setIsLoading(false);
        }
    }

    useEffect(() => {
        if (currentUser === null) setWrapperState('loading');
        else if (!currentUser.canViewInvoices()) setWrapperState('accessDenied');
        else if (initError) setWrapperState('hasErrors');
        else setWrapperState(null);
    }, [currentUser, isLoading, initError]);

    useEffect(() => {
        if (currentUser?.canViewInvoices()) fetchData();
    }, [currentUser, paging, filter]);

    function handleOnSearch(values: IFilterValues) {
        setFilter(values)
    }

    return (
        <Wrapper state={wrapperState} error={initError}>
            <PageHeader
                title="Счета"
            />
            <InvoiceListFilter isLoading={isLoading} handleOnSearch={handleOnSearch} />
            <InvoicesTable
                currentUser={currentUser}
                invoices={invoices}
                isLoading={isLoading}
                refresh={fetchData}
                paging={paging}
                setPaging={setPaging}
                totalRows={totalRows}
                getColumns={getInvoiceListColumns}
            />
        </Wrapper>
    );
}

