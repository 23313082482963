import { Button, Form, PageHeader, Select, Input, Card } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import emptyGuid, { isEmptyGuid } from '../../../types/EmptyGuid';
import useClientsApi from '../../../hooks/backend/api/clients/useClientsApi';
import { IClientCard } from '../../../types/backend/Clients';
import useIdentityApi from "../../../hooks/backend/api/useIdentityApi";
import useAuth from '../../../hooks/useAuth';
import { useParams, useNavigate } from 'react-router-dom';
import { Wrapper, WrapperState } from '../../Wrapper';
import useBackendForm from '../../../hooks/useBackendForm';
import IBackendError from '../../../types/BackendError';

interface IManagersListItem {
    id: string;
    name: string;
}

interface IClientCreationForm {
    ManagerId: string | null;
    Name: string;
}

const initialValues: IClientCreationForm = {
    ManagerId: emptyGuid,
    Name: ''
};

export function ClientCreation() {
    const clientsApi = useClientsApi();
    const identityApi = useIdentityApi();
    const unmounted = useRef(false);
    const [isLoading, setIsLoading] = useState(false);
    const [initError, setInitError] = useState<any>(undefined);
    const [managers, setManagers] = useState<IManagersListItem[]>([]);
    const [editableClient, setEditableClient] = useState<IClientCard | null>(null);
    const { currentUser } = useAuth();
    const params = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [wrapperState, setWrapperState] = useState<WrapperState | null>(null);
    const backendForm = useBackendForm<IClientCreationForm>();
    const [isProcessing, setIsProcessing] = useState(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    useEffect(() => {
        async function fetchData(clientId: string | null) {
            setInitError(undefined);
            setIsLoading(true);
            try {
                const receivedRoles = (await identityApi.getManagers()).map(manager => ({
                    id: manager.id,
                    name: manager.name
                }));

                if (!unmounted.current) {
                    setManagers([{ id: emptyGuid, name: '-' }, ...receivedRoles]);
                }
                if (clientId) {
                    const receivedClient = await clientsApi.getClient(clientId);
                    if (!unmounted.current) {
                        setEditableClient(receivedClient);
                        backendForm.form.setFieldsValue({
                            ManagerId: receivedClient.managerId ?? emptyGuid,
                            Name: receivedClient.name
                        });
                    }
                }
            } catch (error) {
                if (!unmounted.current) setInitError(error);
            } finally {
                if (!unmounted.current) setIsLoading(false);
            }
        }

        if (currentUser?.canEditClients()) {
            fetchData(params?.id ? params.id : null);
        }
    }, [currentUser, params]);

    useEffect(() => {
        if (currentUser === null) setWrapperState('loading');
        else if (!currentUser.canEditClients()) setWrapperState('accessDenied');
        else if (isLoading) setWrapperState('loading');
        else if (initError) setWrapperState('hasErrors');
        else setWrapperState(null);
    }, [currentUser, isLoading, initError]);

    async function createClient(values: IClientCreationForm) {
        const clientId = await clientsApi.createClient(
            values.Name,
            values.ManagerId !== emptyGuid ? values.ManagerId : null
        );
        navigate(`/clients/${clientId}`);
    }

    async function editClient(clientId: string, values: IClientCreationForm) {
        await clientsApi.editClient(
            clientId,
            values.Name,
            values.ManagerId !== emptyGuid ? values.ManagerId : null
        );
        navigate(`/clients/${params?.id}`);
    }

    async function handleOnFinish(values: IClientCreationForm) {
        backendForm.resetErrors();
        setIsProcessing(true);
        try {
            if (editableClient) await editClient(editableClient.id, values);
            else await createClient(values);
        }
        catch (error) {
            if (!unmounted.current) backendForm.setErrors(error as IBackendError);
        }
        finally {
            if (!unmounted.current) setIsProcessing(true);
        }
    }

    function handleOnBack() {
        if (editableClient) navigate(`/clients/${params?.id}`);
        else navigate(`/clients`);
    }

    return (
        <Wrapper state={wrapperState} error={initError}>
            <Card
                title={(
                    <PageHeader
                        title={editableClient ? 'Редактирование клиента' : 'Создание клиента'}
                        subTitle={editableClient ? editableClient.name : null}
                        onBack={handleOnBack}
                    />
                )}
            >
                <Form
                    form={backendForm.form}
                    initialValues={initialValues}
                    onFinish={handleOnFinish}
                    wrapperCol={{ span: 8 }}
                    labelCol={{ span: 3 }}
                >
                    <Form.Item
                        name="Name"
                        label="Наменование"
                        rules={[{ required: true, message: 'Необходимо указать наименование' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item name="ManagerId" label="Ответственный">
                        <Select
                            options={managers.map((r) => ({ label: r.name, value: r.id }))}
                        />
                    </Form.Item>

                    <Form.Item wrapperCol={{ span: 8, offset: 3 }}>
                        <Button
                            block
                            type="primary"
                            htmlType="submit"
                            loading={isProcessing}
                        >
                            {editableClient ? 'Редактировать' : 'Создать'}
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </Wrapper>
    );
}