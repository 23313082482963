import React from 'react';
import { Button, Tooltip, Tag } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { CloseOutlined } from '@ant-design/icons';
import { IClientFreeRequestSettings } from '../../../../../types/backend/Clients';

type TColumnProps = ColumnProps<IClientFreeRequestSettings>;

export function getFreeRequestSettingsListColumns(
  disable: (settingsId: string) => void,
  disablingProcess: string | null
): TColumnProps[] {
  return [
    {
      title: '#',
      key: 'index',
      width: 70,
      render: (text, record, index) => index + 1
    },
    {
      title: 'Продукт',
      dataIndex: 'productName',
      key: 'productName',
      width: 250
    },
    {
      title: 'Дата подключения',
      key: 'created',
      render: (text, record) => record.created.local().format('DD.MM.yyyy HH:mm:ss')
    },
    {
      title: 'Подключен пользователем',
      dataIndex: 'userName',
      key: 'userName',
      width: 350
    },
    {
      title: 'Состояние',
      key: 'state',
      width: 100,
      render: (text, record) => (
        record.isClosed
          ? (
            <Tooltip title={`Отключен пользователем ${record.closedByUserName}`}>
              <Tag color='error'>Отключен</Tag>
            </Tooltip>
          )
          : (
            <Tag
              color={record.count > 0 ? 'processing' : 'default'}
            >
              {record.count > 0 ? 'Активен' : 'Закончился'}
            </Tag>
          )
      )
    },
    {
      title: 'Количество',
      dataIndex: 'count',
      key: 'count',
      width: 100,
    },
    {
      title: 'Комментарий',
      dataIndex: 'comment',
      key: 'comment',
    },
    {
      title: 'Действия',
      key: 'action',
      align: 'center',
      width: 150,
      render: (text, record) => (
        <Tooltip title='Отключить'>
          <Button
            size='large'
            shape='circle'
            loading={disablingProcess === record.id}
            disabled={record.isClosed || record.count === 0 || disablingProcess !== null}
            onClick={() => disable(record.id)}
            icon={<CloseOutlined />}
          />
        </Tooltip>
      ),
    }
  ];
}