import { UserAddOutlined } from '@ant-design/icons';
import { Button, PageHeader, Table } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import useIdentityApi from '../../../hooks/backend/api/useIdentityApi';
import { IUsersListItem } from '../../../types/backend/Identity';
import { Wrapper, WrapperState } from '../../Wrapper';

import { getUserListColumns } from './UserListColumns';

interface IUsersTableItem extends IUsersListItem {
  key: string;
}

export function UserList() {
  const unmounted = useRef(false);
  const identityApi = useIdentityApi();
  const [isLoading, setIsLoading] = useState(false);
  const [initError, setInitError] = useState<any>(undefined);
  const [wrapperState, setWrapperState] = useState<WrapperState | null>(null);
  const [users, setUsers] = useState<IUsersTableItem[]>([]);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        setInitError(undefined);
        setIsLoading(true);
        const receivedUsers = await identityApi.getUsers();

        if (!unmounted.current) {
          const convertedUsers: IUsersTableItem[] = receivedUsers.map((r) => ({
            ...r,
            key: r.id,
          }));
          setUsers(convertedUsers);
        }
      } catch (error) {
        if (!unmounted.current) setInitError(error);
      } finally {
        if (!unmounted.current) setIsLoading(false);
      }
    }

    if (currentUser?.canViewUsers()) {
      fetchData();
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser === null) setWrapperState('loading');
    else if (!currentUser.canViewUsers()) setWrapperState('accessDenied');
    else if (initError) setWrapperState('hasErrors');
    else setWrapperState(null);
  }, [currentUser, isLoading, initError]);

  function redirectOnCreateUser() {
    navigate('/users/creation');
  }

  function redirectOnEditUser(id: string) {
    navigate(`/users/${id}`);
  }

  function redirectOnLockUser(id: string) {
    navigate(`/users/${id}/locking`);
  }

  const renderTable = () => {
    if (!currentUser) {
      return null;
    }
    return (
      <Table
        size='small'
        columns={getUserListColumns(
          currentUser,
          redirectOnEditUser,
          redirectOnLockUser
        )}
        loading={isLoading}
        dataSource={users}
        scroll={{ x: 900 }}
      />
    );
  };

  return (
    <Wrapper state={wrapperState} error={initError}>
      <PageHeader
        title="Пользователи"
        extra={
          currentUser?.canEditUsers() && (
            <Button
              type="primary"
              size="large"
              onClick={redirectOnCreateUser}
              icon={<UserAddOutlined />}
            >
              Создать пользователя
            </Button>
          )
        }
      />

      {renderTable()}
    </Wrapper>
  );
};