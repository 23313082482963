import { Button, Col, Row, Space, Table, TablePaginationConfig, Tooltip, notification } from "antd";
import { PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useColumns } from "./ClientTelegramNotificationsListColumns";
import { useClientTelegramNotificationsListConfig } from "./ClientTelegramNotificationsListConfig";
import { IClientTelegramBindingsListItem } from "../../../../types/backend/Clients";
import { useClientNotificationsApi } from "../../../../hooks/backend/api/clients/useClientNotificationsApi";
import { Wrapper, WrapperState } from "../../../Wrapper";
import useAuth from "../../../../hooks/useAuth";
import { defaultPage, defaultPageSize } from "../../../../types/list/PagingConfig";

interface ITableItem extends IClientTelegramBindingsListItem {
  key: string;
}

interface IClientTelegramNotificationsListProps {
  clientId: string;
}

export function ClientTelegramNotificationsList(props: IClientTelegramNotificationsListProps) {
  const { clientId } = props;

  const { currentUser } = useAuth();

  const config = useClientTelegramNotificationsListConfig();
  const { paging, setPaging } = config;

  const unmounted = useRef(false);
  useEffect(() => {
    return () => { unmounted.current = true; };
  }, []);

  const api = useClientNotificationsApi();
  const [isLoading, setIsLoading] = useState(false);
  const [initError, setInitError] = useState<any>(undefined);
  const [totalRows, setTotalRows] = useState(0);
  const [items, setItems] = useState<ITableItem[]>([]);
  const [isAdding, setIsAdding] = useState(false);

  async function fetchData() {
    if (isLoading) return;

    setInitError(undefined);
    setIsLoading(true);

    try {
      const received = await api.getTelegram(
        clientId, paging.page, paging.pageSize);

      if (!unmounted.current) {
        setItems(received.list.map((item, index) =>
          ({ key: `telegramNotifications_${index}`, ...item })));
        setTotalRows(received.totalRowCount);
      }
    } catch (error) {
      if (!unmounted.current) setInitError(error);
    } finally {
      if (!unmounted.current) setIsLoading(false);
    }
  }

  useEffect(() => {
    if (currentUser?.canViewClients()) fetchData();
  }, [clientId, currentUser])

  const wrapperState = useMemo<WrapperState | null>(() => {
    if (currentUser === null) return 'loading';
    else if (!currentUser.canViewClients()) return 'accessDenied';
    else if (initError) return 'hasErrors';
    else return null;
  }, [currentUser, isLoading, initError])

  function handleOnChange(pagination: TablePaginationConfig) {
    setPaging(pagination.current ?? defaultPage, pagination.pageSize ?? defaultPageSize);
  }

  const handleOnClickAdd = useCallback(async () => {
    setIsAdding(true);
    try {
      await api.createTelegramBinding(clientId);
      fetchData();
    } catch (error) {
      console.error(error);
      notification.error({
        message: 'Не удалось добавить аккаунт'
      })
    } finally {
      if (!unmounted.current) setIsAdding(false);
    }
  }, [unmounted, setIsAdding, fetchData, api])

  const columns = useColumns({
    refetch: fetchData
  })

  return (
    <>
      <Row
        style={{ paddingTop: '1rem' }}
      >
        <Col>
          <Button
            icon={<PlusOutlined />}
            disabled={wrapperState !== null}
            loading={isAdding}
            onClick={handleOnClickAdd}
          >
            Добавить
          </Button>
        </Col>
        <Col flex={1} />
        <Col>
          <Tooltip title='Обновить'>
            <Button
              shape='circle'
              icon={<ReloadOutlined />}
              loading={isLoading}
            />
          </Tooltip>
        </Col>
      </Row>

      <Wrapper state={wrapperState} error={initError}>
        <Table
          style={{ paddingTop: '1rem' }}
          size='small'
          columns={columns}
          loading={isLoading}
          dataSource={items}
          scroll={{ x: 900 }}
          onChange={handleOnChange}
          pagination={{
            current: paging.page,
            position: ['bottomCenter'],
            pageSize: paging.pageSize,
            total: totalRows,
            showSizeChanger: false
          }}
        />
      </Wrapper>
    </>
  )
}