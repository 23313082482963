import React, { ReactNode, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, List, Modal, PageHeader, Row, Tooltip, Typography } from 'antd';
import { InfoCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { SetterOrUpdater } from 'recoil';
import { IPaging } from '../../../types/list/PagingConfig';
import { IDashboardItem } from '../../../types/backend/Dashboard';

interface IDashboardPanelProps<TItem extends IDashboardItem> {
  items: TItem[];
  total: number;
  isLoading: boolean;
  paging: IPaging;
  setPaging: SetterOrUpdater<IPaging>;
  getItemDescription(item: TItem): ReactNode;
  getItemInfo?(item: TItem): ReactNode;
}

export function DashboardList<TItem extends IDashboardItem>(props: IDashboardPanelProps<TItem>) {
  const { items, total, isLoading, paging, setPaging, getItemDescription, getItemInfo } = props;

  const [info, setInfo] = useState<TItem | undefined>(undefined);

  function renderListItem(item: TItem, index: number) {
    const description = getItemDescription(item);

    return (
      <List.Item
        key={`item_${index}`}
      >
        <List.Item.Meta
          avatar={`${index + 1}.`}
          title={(
            <Row gutter={8}>
              <Col>
                <Link to={`/clients/${item.clientId}`}>{item.clientName}</Link>
              </Col>
              {getItemInfo && (
                <Col>
                  <InfoCircleOutlined
                    onClick={() => setInfo(item)}
                  />
                </Col>
              )}
            </Row>
          )}
          description={description}
        />
        <div>{item.managerName}</div>
      </List.Item>
    )
  }

  function handleOnChangePage(page: number, pageSize: number) {
    setPaging({ page: page, pageSize: pageSize })
  }

  return (
    <>
      <List
        bordered={false}
        loading={isLoading}
        itemLayout="horizontal"
        dataSource={items}
        renderItem={renderListItem}
        pagination={{
          simple: false,
          showSizeChanger: false,
          hideOnSinglePage: false,
          pageSize: paging.pageSize,
          total: total,
          current: paging.page,
          onChange: handleOnChangePage
        }}
      />
      {getItemInfo && (
        <Modal
          title={info?.clientName}
          closable={true}
          visible={info !== undefined}
          footer={null}
          closeIcon={(
            <CloseOutlined onClick={() => setInfo(undefined)} />
          )}
        >
          {info && getItemInfo(info)}
        </Modal>
      )}
    </>
  )
}