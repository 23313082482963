import IFieldError from './FieldError';

interface IValidationError {
  fieldName?: string;
  message: string;
}

interface IError {
  message: string;
}

export const generalErrorKey = '';

export default interface IBackendError {
  statusCode: number | undefined;
  getGroupedMessages(): Record<string, string[]>;
}

export class BackendValidationError implements IBackendError {
  statusCode: number;
  validationErrors: IValidationError[];

  constructor(validationErrors?: IValidationError[]) {
    this.statusCode = 400;
    this.validationErrors = validationErrors ? validationErrors : [];
  }

  getGroupedMessages(): Record<string, string[]> {
    const errors = this.validationErrors.length > 0
      ? (
        this.validationErrors.map(value => ({
          fieldName: value.fieldName ? value.fieldName : '',
          message: value.message
        }))
      )
      : [{ message: `Http error with code ${this.statusCode}` }];

    return groupMessages(errors);
  }
}

export class BackendError implements IBackendError {
  statusCode: number | undefined;
  errors: IError[];

  constructor(statusCode?: number, errors?: IError[]) {
    this.statusCode = statusCode;
    this.errors = errors ? errors : [];
  }

  getGroupedMessages(): Record<string, string[]> {
    const errors = this.errors.length > 0
      ? (
        this.errors.map(value => ({
          message: value.message
        }))
      )
      : this.statusCode
        ? [{ message: `Http error with code ${this.statusCode}` }]
        : [{ message: 'Unknown error' }]

    return groupMessages(errors);
  }
}

function groupMessages(errors: { fieldName?: string, message: string }[]): Record<string, string[]> {
  return errors.reduce(
    (acc: Record<string, string[]>, cur: { fieldName?: string, message: string }) => {
      const fieldName = cur.fieldName || generalErrorKey;

      if (!acc[fieldName]) {
        acc[fieldName] = [];
      }

      acc[fieldName].push(cur.message);

      return {
        ...acc,
        [fieldName]: acc[fieldName]
      }
    },
    {}
  );
}

export function parseBackendError(error: any): IFieldError[] {
  const result: IFieldError[] = [];

  const backendError = error as IBackendError;

  if (backendError && typeof backendError.getGroupedMessages === 'function') {
    const groupedErrors = backendError.getGroupedMessages();
    Object.keys(groupedErrors)?.forEach(key => {
      const fieldName = key === generalErrorKey ? undefined : key;

      groupedErrors[key]?.forEach(errorMessage => result.push({
        fieldName: fieldName,
        message: errorMessage
      }))
    })
  }

  return result;
}