import { Moment } from 'moment';

export enum InvoiceState {
  PaymentPending = 1,
  Paid = 2,
  Canceled = 3
}

export interface IInvoiceListItem {
  id: string;
  number: string;
  created: Moment;
  paymentDate: Moment | null;
  amount: number;
  state: string;
  stateValue: InvoiceState;
}

export interface IInvoiceWithClientListItem extends IInvoiceListItem {
  clientId: string;
  clientINN: string;
  managerId: string;
  managerName: string;
}