import React, { useEffect, useRef, useState, createContext } from 'react';
import { Button, Card, PageHeader, Space, Tabs } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import useAuth, { IUserProfile } from '../../../hooks/useAuth';
import { Wrapper, WrapperState } from '../../Wrapper';
import useClientsApi from '../../../hooks/backend/api/clients/useClientsApi';
import { IClientCard } from '../../../types/backend/Clients';
import { ClientProductList } from './Products/ClientProductList';
import { ClientRequisites } from './Requisites/ClientRequisites';
import { ClientCardMain } from './ClientCardMain';
import { InvoiceList } from './Invoices/InvoiceList';
import { ClientNotifications } from './Notifications/ClientNotifications';
const { TabPane } = Tabs

interface IClientCardContext {
    currentUser: IUserProfile;
    client: IClientCard;
    refreshClient(): Promise<void>;
}

export const ClientCardContext = createContext({} as IClientCardContext);

export function ClientCard() {
    const unmounted = useRef(false);
    const clientsApi = useClientsApi();
    const [isLoading, setIsLoading] = useState(false);
    const [initError, setInitError] = useState<any>(undefined);
    const [wrapperState, setWrapperState] = useState<WrapperState | null>(null);
    const { currentUser } = useAuth();
    const params = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [client, setClient] = useState<IClientCard | undefined>(undefined);
    const [tabKey, setTabKey] = useState("1");

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    async function fetchData(clientId: string) {
        setInitError(undefined);
        setIsLoading(true);

        try {
            if (!unmounted.current) {
                const clientData = await clientsApi.getClient(clientId);
                setClient(clientData);
            }
        } catch (error) {
            if (!unmounted.current) setInitError(error);
        } finally {
            if (!unmounted.current) setIsLoading(false);
        }
    }

    async function refreshClient() {
        if (!client) return;

        await fetchData(client.id);
    }

    useEffect(() => {
        if (params?.id && currentUser?.canViewClients()) {
            fetchData(params.id);
        }
    }, [currentUser, params]);

    useEffect(() => {
        if (currentUser === null) setWrapperState('loading');
        else if (!currentUser.canViewClients()) setWrapperState('accessDenied');
        else if (isLoading) setWrapperState('loading');
        else if (initError) setWrapperState('hasErrors');
        else setWrapperState(null);
    }, [currentUser, isLoading, initError]);

    function handleTabClickEvent(key: string) {
        setTabKey(key);
    }

    return (
        <Wrapper state={wrapperState} error={initError}>
            {currentUser && client && (
                <ClientCardContext.Provider
                    value={{ client, currentUser, refreshClient }}
                >
                    <Card
                        bordered={false}
                        title={
                            <PageHeader
                                title={`Карточка клиента`}
                                subTitle={`${client?.name}`}
                                onBack={() => navigate('/clients')}
                            />
                        }
                    >
                        <Tabs defaultActiveKey="1" activeKey={tabKey} onTabClick={handleTabClickEvent}>
                            <TabPane tab="Основное" key="1" style={{ outline: 'none' }}>
                                <ClientCardMain />
                            </TabPane>
                            <TabPane tab="Реквизиты" key="2">
                                <ClientRequisites requisites={client.requisitesSet} refreshClient={refreshClient} />
                            </TabPane>
                            <TabPane tab="Продукты" key="3">
                                <ClientProductList />
                            </TabPane>
                            {currentUser?.canViewInvoicesOnClient() && (
                                <TabPane tab="Счета" key="4">
                                    <InvoiceList clientId={client.id} />
                                </TabPane>
                            )}
                            <TabPane tab="Уведомления" key="5">
                                <ClientNotifications clientId={client.id} />
                            </TabPane>
                        </Tabs>
                    </Card>
                </ClientCardContext.Provider>
            )}
        </Wrapper>
    );
};
