import React, { useEffect, useState, useRef } from 'react';
import { Modal, Form, Select } from 'antd';
import useBackendForm from '../../../../../hooks/useBackendForm';
import useReferencesApi from '../../../../../hooks/backend/api/useReferencesApi';
import { IBillingStrategyType } from '../../../../../types/backend/Clients';
import useAuth from '../../../../../hooks/useAuth';
import { Wrapper, WrapperState } from '../../../../Wrapper';
import useClientTariffsApi from '../../../../../hooks/backend/api/clients/useClientTariffsApi';

interface ITariffSettingsBillingStrategyProps {
  settingsId: string | null;
  type: number | null;
  handleOnOk(result: ITariffSettingsBillingStrategyChangingResult): void;
  handleOnCancel(): void;
}

interface ITariffSettingsBillingStrategyForm {
  billingStrategyType: number;
}

type TariffSettingsBillingStrategyChangingResultType = "Changed" | "Same" | "Error";

export interface ITariffSettingsBillingStrategyChangingResult {
  type: TariffSettingsBillingStrategyChangingResultType;
  selectedBillingStrategyName?: string;
}

const zeroType = { name: '-', value: -1 }

export function TariffSettingsBillingStrategy(props: ITariffSettingsBillingStrategyProps) {
  const unmounted = useRef(false);
  const { currentUser } = useAuth();
  const referencesApi = useReferencesApi();
  const clientTariffsApi = useClientTariffsApi();

  const [isLoading, setIsLoading] = useState(false);
  const [initError, setInitError] = useState<any>(undefined);
  const [wrapperState, setWrapperState] = useState<WrapperState | null>(null);

  const [billingStrategyTypes, setBillingStrategyTypes] = useState<IBillingStrategyType[]>([]);

  const [isProcessing, setIsProcessing] = useState(false);

  const backendForm = useBackendForm<ITariffSettingsBillingStrategyForm>();

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  useEffect(() => {
    let currentType: IBillingStrategyType = zeroType;
    if (props.type) {
      const foundType = billingStrategyTypes.find(e => { return e.value === props.type; });
      if (foundType) currentType = foundType;

      backendForm.form.setFieldsValue({ billingStrategyType: currentType.value });
    }
  }, [billingStrategyTypes, props.type])

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const receivedTypes = await referencesApi.getBillingStrategyTypes();
        if (!unmounted.current) setBillingStrategyTypes([zeroType, ...receivedTypes]);
      } catch (error) {
        if (!unmounted.current) setInitError(error);
      } finally {
        if (!unmounted.current) setIsLoading(false);
      }
    }

    if (currentUser?.canEditClients) fetchData();
  }, [currentUser])

  useEffect(() => {
    if (currentUser === null) setWrapperState('loading');
    else if (!currentUser.canEditClients()) setWrapperState('accessDenied');
    else if (isLoading) setWrapperState('loading');
    else if (initError) setWrapperState('hasErrors');
    else setWrapperState(null);
  }, [currentUser, isLoading, initError]);

  async function handleOnFinish(value: ITariffSettingsBillingStrategyForm) {
    const { billingStrategyType } = value;
    const foundType = billingStrategyTypes.find(e => e.value === billingStrategyType);
    if (!props?.settingsId) return;

    setIsProcessing(true);
    try {
      if (billingStrategyType === props.type) {
        props.handleOnOk({ type: "Same" });
      } else {
        await clientTariffsApi.setClientTariffSettingsBillingStrategyType(props.settingsId, billingStrategyType)
        props.handleOnOk({
          type: "Changed",
          selectedBillingStrategyName: foundType?.name ?? 'Неизвестно'
        });
      }
    } catch (error) {
      props.handleOnOk({
        type: "Error",
        selectedBillingStrategyName: foundType?.name ?? 'Неизвестно'
      })
    } finally {
      if (!unmounted.current) setIsProcessing(false);
    }
  }

  return (
    <Modal
      visible={props.settingsId !== null}
      title="Тарификация"
      closable={false}
      maskClosable={false}
      okText="Применить"
      okButtonProps={{ loading: isProcessing }}
      onOk={backendForm.form.submit}
      cancelText="Отмена"
      cancelButtonProps={{ disabled: isProcessing }}
      onCancel={props.handleOnCancel}
    >
      <Wrapper state={wrapperState} error={initError}>
        <Form
          form={backendForm.form}
          onFinish={handleOnFinish}
        >
          <Form.Item
            name="billingStrategyType"
            rules={[
              () => ({
                validator(_, value) {
                  if (value === -1) {
                    return Promise.reject('Необходимо выбрать модель тарификации');
                  }
                  return Promise.resolve();
                },
              })
            ]}
          >
            <Select
              options={billingStrategyTypes.map(m => ({ value: m.value, label: m.name, }))}
            />
          </Form.Item>
        </Form>
      </Wrapper>
    </Modal>
  )
}