import React, { useRef, useState, useContext } from 'react';
import { Descriptions, Button, Modal, Space, Row, Col, Tag, Divider, Alert } from 'antd';
import { PlusOutlined, MinusOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { InfoPopover } from '../../InfoPopover';
import { ClientManualBalanceOperationModal } from './ClientManualBalanceOperationModal';
import { SubscriptionState, ActivationIssue, DeactivationIssue } from '../../../types/backend/Clients';
import useClientSubscriptionApi from '../../../hooks/backend/api/clients/useClientSubscriptionApi';
import { parseBackendError } from '../../../types/BackendError';
import { ClientCardContext } from './ClientCard';
import subscriptionStateColorMap from '../../../types/SubscriptionStateColorMap';

const activationIssuesMap: Record<ActivationIssue, React.ReactNode> = {
  1: (
    <Alert
      type='warning'
      message='Клиент заблокирован'
      key='activationIssue_1'
    />
  ),
  2: (
    <Alert
      type='warning'
      message='У клиента есть бесплатные запросы'
      key='activationIssue_2'
    />
  ),
  3: (
    <Alert
      type='error'
      message='У клиента уже есть активированная подписка'
      key='activationIssue_3'
    />
  ),
  4: (
    <Alert
      type='error'
      message='У клиента нет настроенных продуктов для подписки'
      key='activationIssue_4'
    />
  )
}

const closingIssuesMap: Record<DeactivationIssue, React.ReactNode> = {
  1: (
    <Alert
      type='warning'
      message='Клиент активен'
      key='closingIssue_1'
    />
  ),
  2: (
    <Alert
      type='warning'
      message='У клиента есть бесплатные запросы'
      key='closingIssue_2'
    />
  )
}

export function ClientCardSubscription() {
  const unmounted = useRef(false);

  const clientSubscriptionApi = useClientSubscriptionApi();
  const [activationProcessStage, setActivationProcessStage] = useState<number | null>(null);
  const [closingProcessStage, setClosingProcessStage] = useState<number | null>(null);

  const { client, currentUser, refreshClient } = useContext(ClientCardContext);

  const [manualBalanceModalVisible, setManualBalanceModalVisible] = useState(false);
  const [manualBalanceModalIsWithdrawalType, setManualBalanceModalIsWithdrawalType] = useState(false);

  function showFailModal(error: any) {
    Modal.error({
      title: 'Возникла ошибка',
      content: parseBackendError(error).map((value, index) => (
        <div key={`error_${index}`}>{value.fieldName} {value.message}</div>
      ))
    });
  }

  async function subscriptionActivate() {
    setActivationProcessStage(2);
    try {
      await clientSubscriptionApi.activate(client.id);
      await refreshClient();
    } catch (error) {
      if (!unmounted.current) showFailModal(error);
    } finally {
      if (!unmounted.current) setActivationProcessStage(null);
    }
  }

  async function startSubscriptionActivation() {
    setActivationProcessStage(1);

    const issues = await clientSubscriptionApi.checkActivationIssues(client.id);

    const criticalIssues = issues.includes(ActivationIssue.EmptyClientTariffSettings)
      || issues.includes(ActivationIssue.AlreadyHasBillingPeriod);

    if (issues.length > 0) {
      Modal.confirm({
        title: 'При активации подписки обнаружены проблемы',
        closable: false,
        maskClosable: false,
        okText: 'Продолжить',
        okButtonProps: { loading: activationProcessStage === 2, disabled: criticalIssues },
        onOk: subscriptionActivate,
        cancelText: 'Отмена',
        cancelButtonProps: { disabled: activationProcessStage === 2 },
        onCancel: () => setActivationProcessStage(null),
        content: (
          <Space direction='vertical' size='large'>
            {issues.map(value => activationIssuesMap[value])}
          </Space>
        ),
      })
    } else {
      await subscriptionActivate();
    }
}

  async function startSubscriptionClosing() {
    setClosingProcessStage(1);

    const issues = await clientSubscriptionApi.checkClosingIssues(client.id);

    if (issues.length > 0) {
      Modal.confirm({
        title: 'При закрытии подписки обнаружены проблемы',
        closable: false,
        maskClosable: false,
        okText: 'Продолжить',
        okButtonProps: { loading: closingProcessStage === 2 },
        onOk: subscriptionClose,
        cancelText: 'Отмена',
        cancelButtonProps: { disabled: closingProcessStage === 2 },
        onCancel: () => setClosingProcessStage(null),
        content: (
          <Space direction='vertical' size='large'>
            {issues.map(value => closingIssuesMap[value])}
          </Space>
        ),
      })
    } else {
      await subscriptionClose();
    }
}

  async function subscriptionClose() {
    setClosingProcessStage(1);

    try {
      await clientSubscriptionApi.close(client.id);
      await refreshClient();
    } catch (error) {
      if (!unmounted.current) showFailModal(error);
    }
    finally {
      if (!unmounted.current) setClosingProcessStage(null);
    }
  }

  function renderSubscriptionState() {
    const color = subscriptionStateColorMap[client.subscriptionState];
    return <Tag color={color}>{client.subscriptionStateName}</Tag>
  }

  function renderSubscriptionActions() {
    let result = null;

    switch (client.subscriptionState) {
      case SubscriptionState.Unavailable:
        result = <Space size="small">
          <Button size="small" type='primary' ghost loading={activationProcessStage !== null} onClick={startSubscriptionActivation} icon={<CheckOutlined />}>
            Подключить
          </Button>
          <InfoPopover info="Создает период биллинга клиента, дает доступ по всем сконфигурированным продуктам." />
        </Space>
        break;
      case SubscriptionState.Available:
        result = <Space size="small">
          <Button size="small" danger loading={closingProcessStage !== null} onClick={startSubscriptionClosing} icon={<CloseOutlined />}>
            Закрыть
          </Button>
          <InfoPopover info="Использовать при расторжении договора с клиентом. Отключает продукты, закрывает период биллинга, списывает остаток денег. " />
        </Space>
        break;
    }

    return currentUser?.canEditClients() && client && result
  }

  function openTopupModal() {
    setManualBalanceModalIsWithdrawalType(false);
    setManualBalanceModalVisible(true);
  }

  function openWithdrawalModal() {
    setManualBalanceModalIsWithdrawalType(true);
    setManualBalanceModalVisible(true);
  }

  function renderFormattedBalance() {
    return Intl.NumberFormat('en-us', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      .format(client.currentBalance ?? 0)
  }

  function renderBalanceActions() {
    return (
      currentUser?.canEditClients()
      && client.subscriptionState != SubscriptionState.CloseRequested
      && client.subscriptionState != SubscriptionState.Closed
      && (
        <Row gutter={16}>
          <Col>
            <Button size="small" icon={<PlusOutlined />} onClick={openTopupModal}>
              Пополнить
            </Button>
          </Col>
          <Col>
            <Button size="small" icon={<MinusOutlined />} onClick={openWithdrawalModal}>
              Списать
            </Button>
          </Col>
        </Row>
      )
    )
  }

  function closeBalanceModal() {
    setManualBalanceModalVisible(false);
  }

  return (
    <>
      <Divider orientation='left'>Подписка</Divider>
      <Descriptions column={1}>
        {client.blocked && client.subscriptionState === SubscriptionState.Available && (
          <Descriptions.Item><Alert
            closable={false}
            type='warning'
            message='Клиент заблокирован, запросы по подписке обрабатываться не будут'
          /></Descriptions.Item>
        )}

        <Descriptions.Item label="Статус подписки">
          <Row gutter={16}>
            <Col>{renderSubscriptionState()}</Col>
            <Col>{renderSubscriptionActions()}</Col>
          </Row>
        </Descriptions.Item>
        <Descriptions.Item label="Период">
          <span hidden={client.subscriptionPeriodStart === null}>{client.subscriptionPeriodStart !== null ? client.subscriptionPeriodStart.format('DD.MM.YYYY') : "нет"} - {client.subscriptionPeriodEnd !== null ? client.subscriptionPeriodEnd.format('DD.MM.YYYY') : "нет"}</span>
          <span hidden={client.subscriptionPeriodStart !== null}>отсутствует</span>
        </Descriptions.Item>
        <Descriptions.Item label="Дата первой активации">
          {client.firstActivationDate !== null ? client.firstActivationDate.format('DD.MM.YYYY') : "нет"}
        </Descriptions.Item>
        <Descriptions.Item label="Текущий баланс">
          <Row gutter={16}>
            <Col>{renderFormattedBalance()}</Col>
            <Col>{renderBalanceActions()}</Col>
          </Row>
        </Descriptions.Item>
      </Descriptions>
      <ClientManualBalanceOperationModal
        visible={manualBalanceModalVisible}
        isWithdrawalOperation={manualBalanceModalIsWithdrawalType}
        clientId={client.id}
        onCancel={closeBalanceModal}
        afterTransactionApplied={closeBalanceModal}
        afterResultNotification={refreshClient}
      />
    </>
  )
}