import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Tooltip, notification } from "antd";
import { PlayCircleOutlined, StopOutlined } from '@ant-design/icons';
import { useClientNotificationsApi } from "../../../../../hooks/backend/api/clients/useClientNotificationsApi";

interface IClientTelegramBindingDisableProps {
  disabled?: boolean;
  bindingId: string;
  onSuccess?(): void;
}

export function ClientTelegramBindingDisable(props: IClientTelegramBindingDisableProps) {
  const { disabled, bindingId, onSuccess } = props;

  const unmounted = useRef(false);
  useEffect(() => {
    return () => { unmounted.current = true; };
  }, []);

  const api = useClientNotificationsApi();

  const [isProcessing, setIsProcessing] = useState(false);

  const handleOnClick = useCallback(async () => {
    setIsProcessing(true);
    let successful = true;
    try {
      await api.disableTelegramBinding(bindingId);
    } catch (error) {
      successful = false;
      console.error(error);
      notification.error({
        message: 'Не удалось отключить связь'
      })
    } finally {
      if (!unmounted.current) setIsProcessing(false);
    }

    if (successful && onSuccess) onSuccess();
  }, [unmounted, api, setIsProcessing])

  return (
    <Tooltip title='Выключить'>
      <Button
        shape='circle'
        disabled={disabled}
        loading={isProcessing}
        icon={<StopOutlined />}
        onClick={handleOnClick}
      />
    </Tooltip>
  )
}