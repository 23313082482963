import moment from "moment";
import useBackendRequest from "../../useBackendRequest";
import IPagedList from '../../../../types/backend/PagedList';
import { IFilterValues, ISortValues } from "../../../../components/Clients/List/ClientListConfig";
import { LicenseAgreementState } from '../../../../types/backend/LicenseAgreements';
import {
  SubscriptionState,
  IClientCard,
  IClientLifeCircle,
  IClientLifeCircleResponse,
  IClientsListItem,
  ClientState,
  ClientFreeRequestSettingsState,
  EntrepreneurActivityType
} from '../../../../types/backend/Clients';

interface IClientListItemResponse {
  id: string;
  name: string;
  subscriptionStateName: string;
  subscriptionState: SubscriptionState;
  currentBalance: number;
  blocked: boolean;
  managerName: string;
  managerId: string;
  inn: string;
  subscriptionPeriodStart: Date;
  subscriptionPeriodEnd: Date;
  hasAvailableFreeRequests: boolean;
}

interface IClientCardResponse {
  id: string;
  name: string;
  subscriptionStateName: string;
  subscriptionState: SubscriptionState;
  managerName: string;
  managerId: string;
  currentBalance: number;
  inn: string;
  apiToken: string;
  blocked: boolean;
  subscriptionPeriodStart: Date | null;
  subscriptionPeriodEnd: Date | null;
  firstActivationDate: Date | null;
  lifeCircles: IClientLifeCircleResponse[] | null;
  licenseAgreement: IClientLicenseAgreementResponse | null;
  requisitesSet: IClientRequisitesResponse | undefined;
  hasAvailableFreeRequests: boolean;
}

interface IClientRequisitesResponse {
  inn: string;
  name: string;
  legalName: string;
  ogrn: string;
  kpp: string;
  bik: string;
  bankName: string;
  correspondentAccount: string;
  bankAccount: string;
  actualAddress: string;
  legalAddress: string;
  postalAddress: string;
  headFIO: string;
  headPosition: string;
  headDocument: string;
  phoneNumber: string;
  email: string;
  entrepreneurActivityType: string;
  entrepreneurActivityTypeValue: EntrepreneurActivityType;
}

interface IClientLicenseAgreementResponse {
  id: string;
  number: string;
  state: LicenseAgreementState;
  stateName: string;
  rejectionComment: string;
  terminationComment: string;
}

interface IClientsApi {
  createClient(name: string, managerId: string | null): Promise<string>
  editClient(clientId: string, name: string, managerId: string | null): Promise<void>
  getClients(page: number, pageSize: number, sort: ISortValues, filter: IFilterValues): Promise<IPagedList<IClientsListItem>>;
  getClient(id: string): Promise<IClientCard>;
  block(id: string): Promise<void>;
  unblock(id: string): Promise<void>;
}

export default function useClientsApi(): IClientsApi {
  const { makeRequest } = useBackendRequest();

  function getClients(page: number, pageSize: number, sort: ISortValues, filter: IFilterValues): Promise<IPagedList<IClientsListItem>> {

    let url = `/api/clients?page=${page}&pageSize=${pageSize}`;

    if (sort.currentBalance) url += `&direction=${sort.currentBalance}&field=balance`;

    if (sort.period) url += `&direction=${sort.period}&field=period`;

    if (filter?.name) url += `&name=${filter.name}`;

    switch (filter?.clientState) {
      case ClientState.Active:
        url += `&clientBlocked=false`;
        break;
      case ClientState.Blocked:
        url += `&clientBlocked=true`;
        break;
    }

    filter?.managers.forEach(item => url += `&managerIds=${item}`);

    if (filter?.subscriptionState) url += `&subscriptionState=${filter.subscriptionState}`;

    filter?.subscriptionProducts.forEach(item => url += `&subscriptionProductIds=${item}`);

    switch (filter?.freeRequestSettingsState) {
      case ClientFreeRequestSettingsState.Available:
        url += `&hasFreeRequestSettings=true`;
        break;
      case ClientFreeRequestSettingsState.Unavailable:
        url += `&hasFreeRequestSettings=false`;
        break;
    }

    filter?.freeRequestSettingsProducts.forEach(item => url += `&freeRequestSettingsProductIds=${item}`);

    return makeRequest<IPagedList<IClientListItemResponse>, IPagedList<IClientsListItem>>(
      url,
      "get",
      (response): { list: IClientsListItem[]; page: number; pageSize: number; totalRowCount: number } => {

        const list = response.list.map<IClientsListItem>(value => {
          var periodStart = value.subscriptionPeriodStart !== null ? moment(value.subscriptionPeriodStart) : null;
          var periodEnd = value.subscriptionPeriodEnd !== null ? moment(value.subscriptionPeriodEnd) : null;
          return {
            ...value,
            subscriptionPeriodEnd: periodEnd,
            subscriptionPeriodStart: periodStart
          };
        });
        return {
          list: list,
          page: response.page,
          pageSize: response.pageSize,
          totalRowCount: response.totalRowCount
        };
      },
      undefined
    );
  };

  function getClient(id: string): Promise<IClientCard> {
    const url = `/api/clients/${id}`;
    return makeRequest<IClientCardResponse, IClientCard>(
      url,
      "get",
      response => {
        let lifecicles: IClientLifeCircle[];
        if (response.lifeCircles !== null) {
          lifecicles = response.lifeCircles.map<IClientLifeCircle>(x => {
            return {
              activationUserName: x.activationUserName,
              deactivationUserName: x.deactivationUserName,
              activationDate: x.activationDate !== null ? moment(x.activationDate) : null,
              deactivationDate: x.deactivationDate !== null ? moment(x.deactivationDate) : null
            }
          });
        } else {
          lifecicles = new Array<IClientLifeCircle>();
        }
        return {
          ...response,
          firstActivationDate: response.firstActivationDate !== null ? moment(response.firstActivationDate) : null,
          subscriptionPeriodStart: response.subscriptionPeriodStart !== null ? moment(response.subscriptionPeriodStart) : null,
          subscriptionPeriodEnd: response.subscriptionPeriodEnd !== null ? moment(response.subscriptionPeriodEnd) : null,
          lifeCircles: lifecicles
        };
      },
      undefined);
  };

  function createClient(name: string, managerId: string | null): Promise<string> {
    return makeRequest<string, string>(
      `/api/clients/create`,
      'post',
      response => response,
      {
        name: name,
        managerId: managerId
      }
    )
  }

  function editClient(clientId: string, name: string, managerId: string | null): Promise<void> {
    return makeRequest<void, void>(
      `/api/clients/${clientId}/edit`,
      'post',
      () => { },
      {
        name: name,
        managerId: managerId
      }
    )
  }

  function block(id: string): Promise<void> {
    return makeRequest<void, void>(
      `/api/clients/${id}/block`,
      'post',
      () => { },
      undefined
    )
  }

  function unblock(id: string): Promise<void> {
    return makeRequest<void, void>(
      `/api/clients/${id}/unblock`,
      'post',
      () => { },
      undefined
    )
  }

  return {
    getClients,
    getClient,
    createClient,
    editClient,
    block,
    unblock
  }
}