import useBackendRequest from "../useBackendRequest";
import { IProduct } from '../../../types/backend/Products';

interface IProductResponse {
  id: string;
  name: string;
  hasTariffs: boolean;
}

interface IProductsApi {
  getProducts(): Promise<IProduct[]>;
}

export default function useProductsApi(): IProductsApi {
  const { makeRequest } = useBackendRequest();

  function getProducts() {
    return makeRequest<IProductResponse[], IProduct[]>(
      `/api/products`,
      'get',
      response => response,
      {});
  }

  return {
    getProducts,
  }
}