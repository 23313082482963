export const defaultPage = 1;
export const defaultPageSize = 20;

export interface IPaging {
  page: number;
  pageSize: number;
}

export default interface IPagingConfig {
  paging: IPaging;
  setPaging: (page: number, pageSize: number) => void;
}