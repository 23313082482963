import { Button, Row, Col, Tooltip } from "antd";
import { ColumnProps } from "antd/lib/table";
import { CloudDownloadOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import React from "react";
import { IInvoiceListItem, InvoiceState } from "../../../../types/backend/Invoices";
import { IUserProfile } from "../../../../hooks/useAuth";
import IInvoiceProcessing from '../../../../types/InvoiceProcessing';

export function getInvoiceListColumns(
    currentUser: IUserProfile | null,
    downloadInvoice: (invoice: IInvoiceListItem) => void,
    confirmInvoice: (id: string) => void,
    cancelInvoice: (id: string) => void,
    invoiceProcessing: IInvoiceProcessing | null
): ColumnProps<IInvoiceListItem>[] {
    return [
        {
            title: '',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1,
            width: 70,
        },
        {
            title: '№ счета',
            dataIndex: 'number',
            key: 'number',
            render: (text, record) => (<>P{text}</>)
        },
        {
            title: '',
            dataIndex: 'download',
            key: 'download',
            render: (text, record) => (
                <Button
                    onClick={() => downloadInvoice(record)}
                    block
                    type="link"
                    icon={<CloudDownloadOutlined />}
                />
            )
        },
        {
            title: 'Дата создания',
            dataIndex: 'created',
            key: 'created',
            render: (text, record) => record.created.format('DD.MM.YYYY')
        },
        {
            title: 'Дата оплаты',
            dataIndex: 'paymentDate',
            key: 'paymentDate',
            render: (text, record) => record.paymentDate?.format('DD.MM.YYYY')
        },
        {
            title: 'Сумма',
            dataIndex: 'amount',
            key: 'amount'
        },
        {
            title: 'Статус',
            dataIndex: 'state',
            key: 'state'
        },
        {
            title: 'Действия',
            dataIndex: 'actions',
            key: 'actions',
            width: 150,
            render: (text, record) => (
                <Row gutter={8} >
                    <Col span={12}>
                        <Tooltip title='Подтвердить оплату'>
                            <Button
                                shape='circle'
                                size='large'
                                onClick={() => confirmInvoice(record.id)}
                                disabled={!currentUser?.canConfirmInvoices() || record.stateValue !== InvoiceState.PaymentPending || invoiceProcessing !== null}
                                icon={<CheckOutlined />}
                                loading={invoiceProcessing?.action === "Confirmation" && invoiceProcessing?.invoiceId === record.id}
                            />
                        </Tooltip>
                    </Col>
                    <Col span={12}>
                        <Tooltip title='Отменить счет'>
                            <Button
                                shape='circle'
                                size='large'
                                onClick={() => cancelInvoice(record.id)}
                                disabled={!currentUser?.canCancelInvoices() || record.stateValue !== InvoiceState.PaymentPending || invoiceProcessing !== null}
                                icon={<CloseOutlined />}
                                loading={invoiceProcessing?.action === "Cancellation" && invoiceProcessing?.invoiceId === record.id}
                            />
                        </Tooltip>
                    </Col>
                </Row>
            )
        }
    ]
};