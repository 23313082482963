import moment, { Moment } from "moment";
import useBackendRequest from "../../useBackendRequest";
import IPagedList from '../../../../types/backend/PagedList';
import { IClientTariffSettings } from '../../../../types/backend/Clients';

interface IClientTariffSettingsResponse {
  id: string;
  productName: string;
  tariffName: string;
  startDate: Moment;
  endDate: Moment;
  billingStrategyType: number;
  billingStrategyName: string;
}

interface IClientTariffsApi {
  getClientTariffSettings(clientId: string, page: number, pageSize: number): Promise<IPagedList<IClientTariffSettings>>;
  addClientTariffSettings(clientId: string, tariffId: string, billingStrategyType: number): Promise<void>;
  checkClientTariffSettings(clientId: string, productId: string): Promise<string>;
  setClientTariffSettingsBillingStrategyType(settingsId: string, billingStrategyType: number): Promise<void>;
}

export default function useClientsApi(): IClientTariffsApi {
  const { makeRequest } = useBackendRequest();

  function getClientTariffSettings(clientId: string, page: number, pageSize: number): Promise<IPagedList<IClientTariffSettings>> {
    return makeRequest<IPagedList<IClientTariffSettingsResponse>, IPagedList<IClientTariffSettings>>(
      `/api/clients/${clientId}/tariffSettings?page=${page}&pageSize=${pageSize}`,
      'get',
      response => (
        {
          ...response,
          list: response.list.map(value => (
            {
              ...value,
              startDate: moment(value.startDate),
              endDate: moment(value.endDate)
            }
          ))
        }
      ),
      {}
    )
  }

  function addClientTariffSettings(clientId: string, tariffId: string, billingStrategyType: number): Promise<void> {
    return makeRequest<void, void>(
      `/api/clients/${clientId}/tariffSettings/add`,
      'post',
      () => { },
      {
        tariffId: tariffId,
        billingStrategyType: billingStrategyType
      }
    );
  }

  function checkClientTariffSettings(clientId: string, productId: string): Promise<string> {
    return makeRequest<string, string>(
      `/api/clients/${clientId}/tariffSettings/check?productId=${productId}`,
      'get',
      response => response,
      {}
    );
  }

  function setClientTariffSettingsBillingStrategyType(settingsId: string, billingStrategyType: number): Promise<void> {
    return makeRequest<void, void>(
      `/api/clients/tariffSettings/${settingsId}/setBillingStrategy?type=${billingStrategyType}`,
      'post',
      () => {},
      undefined
    );
  }

  return {
    getClientTariffSettings,
    addClientTariffSettings,
    checkClientTariffSettings,
    setClientTariffSettingsBillingStrategyType
  }
}