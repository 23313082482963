import React, { useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Descriptions, Typography, Button, Modal, Space, Row, Col, Tag, Divider, Alert } from 'antd';
import { EditOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { InfoPopover } from '../../InfoPopover';
import useClientsApi from '../../../hooks/backend/api/clients/useClientsApi';
import { LicenseAgreement } from './LicenseAgreements/LicenseAgreement';
import { parseBackendError } from '../../../types/BackendError';
import { ClientCardContext } from './ClientCard';
import { ClientCardSubscription } from './ClientCardSubscription';

export function ClientCardMain() {
  const unmounted = useRef(false);
  const clientsApi = useClientsApi();
  const navigate = useNavigate();
  const { client, currentUser, refreshClient } = useContext(ClientCardContext);

  function showFailModal(error: any) {
    Modal.error({
      title: 'Возникла ошибка',
      content: parseBackendError(error).map((value, index) => (
        <div key={`error_${index}`}>{value.fieldName} {value.message}</div>
      ))
    });
  }

  async function clientBlock() {
    try {
      await clientsApi.block(client.id);
      await refreshClient();
    } catch (error) {
      if (!unmounted.current) showFailModal(error);
    }
  }

  async function clientUnblock() {
    try {
      await clientsApi.unblock(client.id);
      await refreshClient();
    } catch (error) {
      if (!unmounted.current) showFailModal(error);
    }
  }

  function redirectOnEditClient() {
    navigate(`/clients/${client.id}/edit`);
  }

  function renderClientState() {
    return (
      <Tag color={client.blocked ? 'error' : 'success'}>
        {client.blocked ? 'Заблокирован' : 'Активен'}
      </Tag>
    )
  }

  function renderClientActions() {
    return client.blocked
      ? (
        <Space size="small">
          <Button size="small" type='primary' ghost onClick={clientUnblock} icon={<UnlockOutlined />}>
            Разблокировать
          </Button>
          <InfoPopover info="Отменить блокировку трафика от клиента." />
        </Space>
      ) : (
        <Space size="small">
          <Button size="small" danger onClick={clientBlock} icon={<LockOutlined />}>
            Заблокировать
          </Button>
          <InfoPopover info="Блокирование трафика от клиента по всем продуктам, использовать при нештатных ситуациях" />
        </Space>
      );
  }

  return (
    <>
      <Card bordered={false}
        extra={
          currentUser?.canEditClients() &&
          <Button
            type="default"
            onClick={redirectOnEditClient}
            icon={<EditOutlined />}
          >
            Редактировать клиента
          </Button>
        }>
        <Row wrap={false} gutter={16}>
          <Col span={11}>
            <Descriptions column={1} >
              <Descriptions.Item label="Наименование">{client.name}</Descriptions.Item>
              <Descriptions.Item label="Ответственный">{client.managerName}</Descriptions.Item>
              <Descriptions.Item label="API ключ"><Typography.Text copyable>{client.apiToken}</Typography.Text></Descriptions.Item>
              <Descriptions.Item label="Статус клиента">
                <Row gutter={16}>
                  <Col>{renderClientState()}</Col>
                  <Col>{renderClientActions()}</Col>
                </Row>
              </Descriptions.Item>
            </Descriptions>
            <ClientCardSubscription />
            <Divider orientation='left'>Бесплатные запросы</Divider>
            <Descriptions column={1}>
              {client.blocked && client.hasAvailableFreeRequests && (
                <Descriptions.Item>
                  <Alert
                    closable={false}
                    type='warning'
                    message='Клиент заблокирован, бесплатные запросы обрабатываться не будут'
                  />
                </Descriptions.Item>
              )}
              <Descriptions.Item label='Статус бесплатных запросов'>
                <Tag color={client.hasAvailableFreeRequests ? 'processing' : 'default'}>
                  {client.hasAvailableFreeRequests ? 'Доступны' : 'Недоступны'}
                </Tag>
              </Descriptions.Item>
            </Descriptions>
          </Col>
          <Col span={1} />
          <Col xxl={7} xl={9} lg={12}>
            <LicenseAgreement
              clientId={client.id}
              licenseAgreement={client.licenseAgreement}
              refreshClient={refreshClient}
            />
          </Col>
          <Col xxl={5} xl={3} lg={0} />
        </Row>
      </Card>
    </>
  )
}