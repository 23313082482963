import { atom, RecoilState, RecoilValueReadOnly, selector, SetterOrUpdater, useRecoilValue, useSetRecoilState } from "recoil";
import { defaultPage, IPaging } from "../../../types/list/PagingConfig";

export const defaultPageSize = 7;

export interface IPagingStoreConfig {
  pagingAtom: RecoilState<IPaging>;
  pagingSelector: RecoilValueReadOnly<IPaging>;
}

export function buildPagingStore(prefix: string): IPagingStoreConfig {
  const pagingAtom = atom<IPaging>({
    key: `${prefix}_pagingStore`,
    default: { page: defaultPage, pageSize: defaultPageSize }
  })
  
  const pagingSelector = selector({
    key: `${prefix}_paging`,
    get: ({ get }) => get(pagingAtom)
  })
  
  return {
    pagingAtom,
    pagingSelector,
  }
}

interface IPagingStore {
  paging: IPaging;
  setPaging: SetterOrUpdater<IPaging>;
}

export function usePagingStore(atom: RecoilState<IPaging>, selector: RecoilValueReadOnly<IPaging>): IPagingStore {
  const paging = useRecoilValue(selector);
  const setPaging = useSetRecoilState(atom);

  return {
    paging,
    setPaging,
  }
}