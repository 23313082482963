import { Button, Col, Row, Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import React from 'react';
import { LockOutlined, UnlockOutlined, FormOutlined } from '@ant-design/icons';
import { IUserProfile } from '../../../hooks/useAuth';
import { IUsersListItem } from '../../../types/backend/Identity';

type TColumnProps = ColumnProps<IUsersListItem>;

export function getUserListColumns(
  currentUser: IUserProfile,
  redirectOnEditUser: (id: string) => void,
  redirectOnLockUser: (id: string) => void,
): TColumnProps[] {
  return [
    {
      title: '№',
      dataIndex: 'index',
      key: 'index',
      render: (text, record, index) => index + 1,
      width: 70,
    },
    {
      title: 'Имя пользователя',
      dataIndex: 'userName',
      key: 'userName',
      width: '35%',
    },
    {
      title: 'Роль',
      dataIndex: 'roleName',
      key: 'roleName',
    },
    {
      title: 'Блокировка',
      dataIndex: 'isLockedOut',
      key: 'isLockedOut',
      render: (text, record) => (record.isLockedOut ? 'Есть' : 'Нет'),
    },

    {
      title: 'Действия',
      key: 'action',
      width: 150,
      render: (text, record) => (
        <Row gutter={8}>
          <Col span={12}>
            <Tooltip title='Редактировать'>
              <Button
                shape='circle'
                size='large'
                onClick={() => redirectOnEditUser(record.id)}
                disabled={!currentUser?.canEditUsers()}
                icon={<FormOutlined />}
              />
            </Tooltip>
          </Col>
          <Col span={12}>
            <Tooltip title={record.isLockedOut ? 'Разблокировать' : 'Заблокировать'}>
              <Button
                shape='circle'
                size='large'
                onClick={() => redirectOnLockUser(record.id)}
                disabled={!currentUser?.canEditUsers()}
                icon={record.isLockedOut ? <UnlockOutlined /> : <LockOutlined />}
              />
            </Tooltip>
          </Col>
        </Row>
      ),
    },
  ]
};