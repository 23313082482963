import { Moment } from 'moment';
import { IClientLicenseAgreement } from './LicenseAgreements';

export interface IClientsListItem {
  id: string;
  name: string;
  subscriptionStateName: string;
  subscriptionState: SubscriptionState;
  currentBalance: number;
  blocked: boolean;
  managerName: string;
  managerId: string;
  inn: string;
  subscriptionPeriodStart: Moment | null;
  subscriptionPeriodEnd: Moment | null;
  hasAvailableFreeRequests: boolean;
}

export interface IClientLifeCircleResponse {
  activationUserName: string;
  deactivationUserName: string;
  activationDate: Date;
  deactivationDate: Date;
}

export interface IClientCard {
  id: string;
  name: string;
  subscriptionStateName: string;
  subscriptionState: SubscriptionState;
  managerName: string;
  managerId: string;
  currentBalance: number;
  inn: string;
  apiToken: string;
  blocked: boolean;
  subscriptionPeriodStart: Moment | null;
  subscriptionPeriodEnd: Moment | null;
  firstActivationDate: Moment | null;
  lifeCircles: IClientLifeCircle[] | null;
  licenseAgreement: IClientLicenseAgreement | null;
  requisitesSet: IClientRequisites | undefined;
  hasAvailableFreeRequests: boolean;
}

export interface IClientRequisites {
  inn: string;
  name: string;
  legalName: string;
  ogrn: string;
  kpp: string;
  bik: string;
  bankName: string;
  correspondentAccount: string;
  bankAccount: string;
  actualAddress: string;
  legalAddress: string;
  postalAddress: string;
  headFIO: string;
  headPosition: string;
  headDocument: string;
  phoneNumber: string;
  email: string;
  entrepreneurActivityType: string;
  entrepreneurActivityTypeValue: EntrepreneurActivityType;
}

export interface IClientLifeCircle {
  activationUserName: string;
  deactivationUserName: string;
  activationDate: Moment | null;
  deactivationDate: Moment | null;
}

export interface IClient {
  id: string;
  name: string;
  managerId: string;
  inn: string;
}

export enum SubscriptionState {
  Unavailable = 1,
  Available = 2,
  CloseRequested = 3,
  Closed = 4
}

export enum ClientState {
  Blocked = 1,
  Active = 2
}

export enum ClientFreeRequestSettingsState {
  Available = 1,
  Unavailable = 2
}

export interface IEntrepreneurActivityTypeReference {
  name: string;
  value: EntrepreneurActivityType;
}

export enum EntrepreneurActivityType {
  Individual = 1,
  Company = 2
}

export interface IClientTariffSettings {
  id: string;
  productName: string;
  tariffName: string;
  startDate: Moment;
  endDate: Moment;
  billingStrategyType: number;
  billingStrategyName: string;
}

export interface IBillingStrategyType {
  value: number;
  name: string;
}


export interface IClientFreeRequestSettings
{
    id: string;
    created: Moment;
    userName: string;
    productName: string;
    count: number;
    isClosed: boolean;
    closedByUserName: string;
    comment: string;
}

export interface IUpdateRequisitesFromDadataForm {
  inn: string;
  entrepreneurActivityTypeValue: EntrepreneurActivityType;
}

export enum ActivationIssue {
  ClientBlocked = 1,
  HasFreeRequests = 2,
  AlreadyHasBillingPeriod = 3,
  EmptyClientTariffSettings = 4
}

export enum DeactivationIssue
{
    ClientActive = 1,
    HasFreeRequests = 2,
}

export interface IClientNotificationSettings {
  id: string;
  clientId: string;
  disabled: boolean;
}

export enum ClientNotificationType {
  Balance = 1,
  SuccessfulBinding = 2,
}

export interface ITelegramNotificationMeta {
  id: string;
  date: Moment;
  type: ClientNotificationType;
}

export interface IClientTelegramBindingsListItem {
  id: string;
  userName: string | null;
  lastNotification: ITelegramNotificationMeta | null;
  alreadyBinded: boolean;
  disabled: boolean;
  link: string;
}