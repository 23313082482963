import React, { useEffect, useState } from 'react';
import { Collapse, Row, Col, Button, Form, Input, Select, Divider } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useClientListConfig, IFilterValues } from "./ClientListConfig";
import { IManager } from '../../../types/backend/Identity';
import { IProduct } from '../../../types/backend/Products';
import { SubscriptionState, ClientState, ClientFreeRequestSettingsState } from '../../../types/backend/Clients';

const { Panel } = Collapse;

interface IFilterSelectOption<TValue> {
    name: string;
    value: TValue;
}

interface IClientListFilterProps {
    productsOptions: IProduct[];
    managersOptions: IManager[];
    clientStateOptions: IFilterSelectOption<ClientState>[];
    subscriptionStateOptions: IFilterSelectOption<SubscriptionState>[];
    freeRequestSettingsStateOptions: IFilterSelectOption<ClientFreeRequestSettingsState>[];
    handleOnSearch: (values: IFilterValues) => void;
    isLoading?: boolean;
}

export function ClientListFilter(props: IClientListFilterProps) {
    const {
        managersOptions,
        productsOptions,
        clientStateOptions,
        subscriptionStateOptions,
        freeRequestSettingsStateOptions,
        handleOnSearch,
        isLoading
    } = props;

    const { isFilterOpen, openFilter, closeFilter, filter } = useClientListConfig();
    const [activePanels, setActivePanels] = useState<string[]>([]);
    const panelKey = '1';
    const [form] = Form.useForm<IFilterValues>();

    useEffect(() => {
        setActivePanels(isFilterOpen ? [panelKey] : [])
    }, [isFilterOpen])

    function handleOnChange(key: string | string[]) {
        if (Array.isArray(key) && key.length > 0) {
            openFilter();
        }
        else {
            closeFilter();
        }
    }

    return (
        <Collapse
            ghost
            className="filter-row__collapse"
            activeKey={activePanels}
            onChange={handleOnChange}
        >
            <Panel header="Фильтры" key={panelKey}>
                <Form
                    form={form}
                    initialValues={filter}
                    onFinish={handleOnSearch}
                    wrapperCol={{ span: 8 }}
                >
                    <Form.Item name="name">
                        <Input placeholder="Наименование" />
                    </Form.Item>

                    <Form.Item name="managers">
                        <Select
                            placeholder="Ответственный"
                            mode="multiple"
                            allowClear
                            options={managersOptions?.map((option) => ({
                                value: option.id,
                                label: option.name,
                            }))}
                        />
                    </Form.Item>

                    <Form.Item name="clientState">
                        <Select
                            placeholder="Статус клиента"
                            allowClear
                            options={clientStateOptions?.map((option) => ({
                                value: option.value,
                                label: option.name,
                            }))}
                        />
                    </Form.Item>

                    <Row gutter={16}>
                        <Col span={8} style={{ paddingRight: 0 }}>
                            <Divider orientation='left'>Подписка</Divider>

                            <Form.Item name="subscriptionProducts" wrapperCol={{ span: 24 }}>
                                <Select
                                    placeholder="Продукты"
                                    mode="multiple"
                                    allowClear
                                    options={productsOptions?.map((option) => ({
                                        value: option.id,
                                        label: option.name,
                                    }))}
                                />
                            </Form.Item>

                            <Form.Item name="subscriptionState" wrapperCol={{ span: 24 }}>
                                <Select
                                    allowClear
                                    placeholder="Статус подписки"
                                    options={subscriptionStateOptions?.map((option) => ({
                                        value: option.value,
                                        label: option.name,
                                    }))}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={1} />
                        <Col span={8} style={{ paddingRight: 0 }}>
                            <Divider orientation='left'>Бесплатные запросы</Divider>

                            <Form.Item name="freeRequestSettingsProducts" wrapperCol={{ span: 24 }}>
                                <Select
                                    placeholder="Продукты"
                                    mode="multiple"
                                    allowClear
                                    options={productsOptions?.map((option) => ({
                                        value: option.id,
                                        label: option.name,
                                    }))}
                                />
                            </Form.Item>

                            <Form.Item name="freeRequestSettingsState" wrapperCol={{ span: 24 }}>
                                <Select
                                    placeholder="Статус бесплатных запросов"
                                    allowClear
                                    options={freeRequestSettingsStateOptions?.map((option) => ({
                                        value: option.value,
                                        label: option.name,
                                    }))}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item>
                        <Button
                            type="primary"
                            block
                            loading={isLoading}
                            htmlType="submit"
                            icon={<SearchOutlined />}
                        >
                            Искать
                        </Button>
                    </Form.Item>

                </Form>
            </Panel>
        </Collapse>
    );
}