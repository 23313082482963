import React, { useEffect, useState } from 'react';
import { Collapse, Row, Col, Button, Form, Checkbox } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useInvoiceListConfig, IFilterValues } from "./InvoiceListConfig";

const { Panel } = Collapse;

interface InvoiceListFilterProps {
  handleOnSearch: (values: IFilterValues) => void;
  isLoading?: boolean;
}

export function InvoiceListFilter(props: InvoiceListFilterProps) {
  const { handleOnSearch, isLoading } = props;
  const { isFilterOpen, openFilter, closeFilter, filter } = useInvoiceListConfig();
  const [activePanels, setActivePanels] = useState<string[]>([]);
  const panelKey = '1';
  const [form] = Form.useForm<IFilterValues>();

  useEffect(() => {
    setActivePanels(isFilterOpen ? [panelKey] : [])
  }, [isFilterOpen])

  function handleOnChange(key: string | string[]) {
    if (Array.isArray(key) && key.length > 0) {
      openFilter();
    }
    else {
      closeFilter();
    }
  }

  return (
    <Collapse
      ghost
      className="filter-row__collapse"
      activeKey={activePanels}
      onChange={handleOnChange}
    >
      <Panel header="Фильтры" key={panelKey}>
        <Form
          form={form}
          initialValues={filter}
          onFinish={handleOnSearch}
        >
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Form.Item name="paymentPending" valuePropName="checked">
                <Checkbox>Ожидает оплаты</Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Button
                type="primary"
                block
                loading={isLoading}
                htmlType="submit"
                icon={<SearchOutlined />}
              >
                Искать
              </Button>
            </Col>
          </Row>
        </Form>
      </Panel>
    </Collapse>
  );
}