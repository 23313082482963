import React, { useState } from 'react';
import { Form, FormInstance } from 'antd';
import IBackendError, { generalErrorKey } from '../types/BackendError';
const { useForm } = Form;

interface IBackendForm<TValues> {
  validate: (field: string) => void;
  form: FormInstance<TValues>
  setErrors: (backendError: IBackendError) => void;
  resetErrors: () => void;
  generalErrors: string[];
}

function useBackendForm<TValues>(): IBackendForm<TValues> {
  const [generalErrors, setGeneralErrors] = useState<string[]>([]);
  const [form] = useForm<TValues>();

  function setErrors(backendError: IBackendError) {

    const backendErrors = backendError && typeof backendError.getGroupedMessages === 'function'
      ? backendError.getGroupedMessages()
      : {};

    if (!backendErrors) return;

    const fieldsErrors: { name: string, errors: string[] }[] = [];

    Object.keys(backendError.getGroupedMessages()).forEach(key => {
      const errors = backendErrors[key];

      if (errors) {
        if (key === generalErrorKey) setGeneralErrors(errors);
        else fieldsErrors.push({ name: key, errors });
      }
    });

    form.setFields(fieldsErrors);
  }

  function resetErrors() {
    setGeneralErrors([]);
    form.setFields([]);
  }

  function validate(field: string): void {
    form.validateFields([field]);
  }

  return {
    validate,
    setErrors,
    resetErrors,
    generalErrors,
    form
  }
}

export default useBackendForm;