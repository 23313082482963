import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { atom, selector, useSetRecoilState, useRecoilValue } from 'recoil';
import accountApi from '../services/backend/api/accountApi';
import { ICurrentUser, IUserPermissions } from '../types/backend/Account';
import { HttpError } from '../services/httpClient';
import ILoginRedirectState from '../types/LoginRedirectState';

export interface IUserProfile {
  userName: string;
  canViewClients: () => boolean;
  canEditClients: () => boolean;
  canViewUsers: () => boolean;
  canEditUsers: () => boolean;
  canEditSpecialTariffs: () => boolean;
  canViewInvoices: () => boolean;
  canViewInvoicesOnClient: () => boolean;
  canConfirmInvoices: () => boolean;
  canCancelInvoices: () => boolean;
}

class UserProfile implements IUserProfile {
  userName: string;
  private permissions: IUserPermissions;
  constructor(currentUser: ICurrentUser) {
    this.userName = currentUser.userName;
    this.permissions = currentUser.permissions;
  }

  canViewClients = () => this.permissions.canViewClients;
  canViewUsers = () => this.permissions.canViewUsers;
  canEditUsers = () => this.permissions.canEditUsers;
  canEditClients = () => this.permissions.canEditClients;
  canEditSpecialTariffs = () => this.permissions.canEditSpecialTariffs;
  canViewInvoices = () => this.permissions.canViewInvoices;
  canViewInvoicesOnClient = () => this.permissions.canViewInvoicesOnClient;
  canConfirmInvoices = () => this.permissions.canConfirmInvoices;
  canCancelInvoices = () => this.permissions.canCancelInvoices;
}

interface IAuthState {
  user: IUserProfile | null;
}

const authState = atom<IAuthState>({
  key: 'authState',
  default: {
    user: null
  }
})

const isAuthenticatedState = selector({
  key: 'isAuthenticatedState',
  get: ({ get }) => {
    const currentAuth = get(authState);

    return currentAuth.user !== null;
  }
})

const currentUserState = selector({
  key: 'currentUserState',
  get: ({ get }) => {
    const currentAuth = get(authState);

    return currentAuth.user;
  }
})

interface IAuth {
  signin: (userName: string, password: string) => Promise<void>;
  signout: () => Promise<void>;
  refresh: () => Promise<void>;
  reset: () => void;
  isAuthenticated: boolean;
  currentUser: IUserProfile | null;
}

export default function useAuth(): IAuth {
  const location = useLocation();
  const navigate = useNavigate();
  const setAuth = useSetRecoilState(authState);
  const isAuthenticated = useRecoilValue(isAuthenticatedState);
  const currentUser = useRecoilValue(currentUserState);

  async function signin(userName: string, password: string) {
    try {
      const response = await accountApi.login(userName, password);

      setAuth({ user: new UserProfile(response) })

      const redirectState = location.state as ILoginRedirectState;
      location.state = undefined;

      if (redirectState && redirectState.from.pathname) {
        navigate(redirectState.from.pathname);
      }
      else {
        navigate('/');
      }
    }
    catch (error) {
      throw error;
    }
  }

  async function signout() {
    try {
      await accountApi.logout();
    }
    catch (error) {
      const httpError = error as HttpError;
      
      if (!httpError) throw error;
      else if (httpError.statusCode !== 401) throw error;
    }

    reset();
  }

  async function refresh() {
    if (!currentUser) {
      try {
        const response = await accountApi.getCurrentUser();

        if (response) {
          setAuth({ user: new UserProfile(response) });
        }
      }
      catch (error) {
        throw error;
      }
    }
  }

  function reset() {
    setAuth({ user: null })
  }

  return {
    signin,
    signout,
    refresh,
    reset,
    isAuthenticated,
    currentUser
  }
}