import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Popover, Tooltip, Typography, notification } from "antd";
import { LinkOutlined } from '@ant-design/icons';
import { useClientNotificationsApi } from "../../../../../hooks/backend/api/clients/useClientNotificationsApi";

const { Text } = Typography;

interface IClientTelegramGetLinkProps {
  disabled?: boolean;
  link: string;
}

export function ClientTelegramGetLink(props: IClientTelegramGetLinkProps) {
  const { disabled, link } = props;

  const btn = (
    <Tooltip title='Получить ссылку'>
      <Button
        disabled={disabled}
        shape='circle'
        icon={<LinkOutlined />}
      />
    </Tooltip>
  )

  return disabled === true ? (
    btn
  ) : (
    <Popover
      trigger='click'
      title='Ссылка на подключение'
      placement='left'
      content={(
        <Text copyable>{link}</Text>
      )}
    >
      {btn}
    </Popover>
  )
}