import moment, { Moment } from 'moment';
import useBackendRequest from '../useBackendRequest';
import { IManager, IRolesListItem, IUser, IUserLocking, IUsersListItem } from '../../../types/backend/Identity';

interface IUserResponse {
  id: string;
  userName: string;
  roleId: string;
  isLockedOut: boolean;
  manager?: IManagerResponse;
}

interface IManagerResponse {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  middleName: string;
}

interface IUsersListItemResponse {
  id: string;
  userName: string;
  roleName: string;
  isLockedOut: boolean;
}

interface IUserLockingResponse {
  userName: string;
  lockoutEnd: Date;
}

interface IRolesListItemResponse {
  id: string;
  name: string;
  canBeManager: boolean;
}

interface IIdentityApi {
  createUser(userName: string, 
    firstName: string, 
    lastName: string, 
    middleName: string, 
    password: string, 
    confirmPassword: string, 
    roleId: string): Promise<void>;
  editUser(userId: string, 
    userName: string,    
    firstName: string, 
    lastName: string, 
    middleName: string,  
    roleId: string): Promise<void>;
  getUserLocking(userId: string): Promise<IUserLocking>;
  setUserLocking(userId: string, lockoutEnd: Moment | null): Promise<void>;
  getUser(userId: string): Promise<IUser>;
  getUsers(): Promise<IUsersListItem[]>;
  getManagers(): Promise<IManager[]>;
  getRoles(): Promise<IRolesListItem[]>;
}

export default function useIdentityApi(): IIdentityApi {
  const { makeRequest } = useBackendRequest();

  function createUser(
    userName: string, 
    firstName: string, 
    lastName: string, 
    middleName: string, 
    password: string, 
    confirmPassword: string, 
    roleId: string): Promise<void> {
    return makeRequest<string, void>(
      '/api/users/create',
      'post',
      () => { },
      {
        userName: userName,
        password: password,
        confirmPassword: confirmPassword,
        roleId: roleId,
        manager:{
          firstName: firstName,
          lastName: lastName,
          middleName: middleName
        }
      }
    );
  }

  function editUser(
    userId: string, 
    userName: string,    
    firstName: string, 
    lastName: string, 
    middleName: string,  
    roleId: string): Promise<void> {
    return makeRequest<void, void>(
      `/api/users/${userId}/edit`,
      'post',
      () => { },
      {
        userName: userName,
        roleId: roleId,
        manager:{
          firstName: firstName,
          lastName: lastName,
          middleName: middleName
        }
      }
    );
  }

  function getUserLocking(userId: string): Promise<IUserLocking> {
    return makeRequest<IUserLockingResponse, IUserLocking>(
      `/api/users/${userId}/lock`,
      'get',
      response => ({
        ...response,
        lockoutEnd: response.lockoutEnd ? moment.utc(response.lockoutEnd).local() : null
      }),
      undefined
    );
  }

  function setUserLocking(userId: string, lockoutEnd: Moment | null): Promise<void> {
    return makeRequest<void, void>(
      `/api/users/${userId}/lock`,
      'post',
      () => { },
      {
        lockoutEnd: lockoutEnd?.utc()
      }
    );
  }

  function getUser(userId: string): Promise<IUser> {
    return makeRequest<IUserResponse, IUser>(
      `/api/users/${userId}`,
      'get',
      response => ({ ...response }),
      undefined
    );
  }

  function getUsers(): Promise<IUsersListItem[]> {
    return makeRequest<IUsersListItemResponse[], IUsersListItem[]>(
      '/api/users',
      'get',
      response => {
        return response.map<IUsersListItem>(value => ({ ...value }));
      },
      undefined
    );
  }

  function getManagers(): Promise<IManager[]> {
    return makeRequest<IManagerResponse[], IManager[]>(
      '/api/users/managers',
      'get',
      response => {
        return response.map<IManager>(value => ({ ...value }));
      },
      undefined
    );
  }

  function getRoles(): Promise<IRolesListItem[]> {
    return makeRequest<IRolesListItemResponse[], IRolesListItem[]>(
      '/api/roles',
      'get',
      response => response.map<IRolesListItem>(value => ({ ...value })),
      undefined
    );
  }

  return {
    createUser,
    editUser,
    getUserLocking,
    setUserLocking,
    getUser,
    getUsers,
    getManagers,
    getRoles
  }
}