import { Modal, Form, Input, InputNumber, Spin } from 'antd';
import React, { useRef, useState } from 'react';
import useBalanceApi from '../../../hooks/backend/api/useBalanceApi';
import { parseBackendError } from '../../../types/BackendError';
import useBackendForm from '../../../hooks/useBackendForm';

interface IClientManualBalanceOperationModalProps {
  visible: boolean;
  clientId: string;
  isWithdrawalOperation: boolean;
  onCancel: any;
  afterTransactionApplied: any;
  afterResultNotification: any;
}

interface IClientManualBalanceOperationForm {
  TransactionAmount: number;
  Description: string;
}

export function ClientManualBalanceOperationModal(props: IClientManualBalanceOperationModalProps) {
  const balanceApi = useBalanceApi();
  const unmounted = useRef(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const backendForm = useBackendForm<IClientManualBalanceOperationForm>();

  async function applyDepositTransaction(manualTransactionAmount: number, description: string) {
    try {
      await balanceApi.deposit(props.clientId, manualTransactionAmount, description)
      showSuccessManualTransactionModal(manualTransactionAmount)
    } catch (error) {
      if (!unmounted.current) showFailModal(error);
    }
  }

  async function applyWithdrawTransaction(manualTransactionAmount: number, description: string) {
    try {
      await balanceApi.withdraw(props.clientId, manualTransactionAmount, description)
      showSuccessManualTransactionModal(manualTransactionAmount)
    } catch (error) {
      if (!unmounted.current) showFailModal(error);
    }
  }

  function showFailModal(error: any) {
    const errorsContent = parseBackendError(error).map((value, index) => (
      <div key={`error_${index}`}>{value.fieldName} {value.message}</div>
    ))
    Modal.warning({
      title: 'Возникла ошибка',
      content: errorsContent
    });
  }

  function showSuccessManualTransactionModal(transactionAmount: number) {
    const transtactionAction = props.isWithdrawalOperation ? 'списание' : 'зачисление';
    Modal.info({
      title: `Успешное ${transtactionAction} (${transactionAmount} р.)`,
      afterClose: props.afterResultNotification
    });
  }

  async function handleOnFinish(values: IClientManualBalanceOperationForm) {
    setIsProcessing(true);
    const transactionAmount = Number(values.TransactionAmount);
    if (props.isWithdrawalOperation) {
      await applyWithdrawTransaction(transactionAmount, values.Description);
    }
    else {
      await applyDepositTransaction(transactionAmount, values.Description);
    }
    setIsProcessing(false);
    backendForm.form.resetFields();
    props.afterTransactionApplied()
  }

  return (
    <Modal
      visible={props.visible}
      destroyOnClose
      closable={false}
      maskClosable={false}
      title={props.isWithdrawalOperation == true ? "Ручное списание" : "Ручное пополнение"}
      okText={props.isWithdrawalOperation == true ? "Списать" : "Пополнить"}
      onOk={backendForm.form.submit}
      okButtonProps={{ loading: isProcessing }}
      cancelText="Отмена"
      onCancel={props.onCancel}
      cancelButtonProps={{ disabled: isProcessing }}
    >
      <Form
        form={backendForm.form}
        name="form_in_modal"
        initialValues={{ TransactionAmount: 0, Description: '' }}
        onFinish={handleOnFinish}
        labelCol={{ span: 6 }}
      >
        <Form.Item
          name="TransactionAmount"
          label="Сумма"
          rules={[
            {
              required: true,
              message: 'Введите сумму операции',
            },
          ]}
        >
          <InputNumber
            autoFocus
            min={0}
            max={100000000}
            decimalSeparator='.'
            precision={2}
            step={1}
            parser={
              value => value ? Number(value.replace(',', '.')) : 0
            }
            onFocus={e => e.target.select()}
          />
        </Form.Item>
        <Form.Item name="Description" label="Основание">
          <Input type="textarea" />
        </Form.Item>
      </Form>
    </Modal>
  );
};