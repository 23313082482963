import { atom, selector, RecoilState, RecoilValueReadOnly } from 'recoil';
import { IPaging, defaultPage, defaultPageSize } from './PagingConfig';

interface IPagedListConfigStore {
  pagingAtom: RecoilState<IPaging>;
  pagingSelector: RecoilValueReadOnly<IPaging>;
}

interface IFilteredListConfigStore<TFilterValues> {
  filterIsOpenAtom: RecoilState<boolean>;
  filterIsOpenSelector: RecoilValueReadOnly<boolean>;
  filterAtom: RecoilState<TFilterValues>;
  filterSelector: RecoilValueReadOnly<TFilterValues>;
}

interface ISortedListConfigStore<TSortValues> {
  sortAtom: RecoilState<TSortValues>;
  sortSelector: RecoilValueReadOnly<TSortValues>;
}

export function buildPagedListConfigStores(prefix: string): IPagedListConfigStore {
  const pagingAtom = atom<IPaging>({
    key: `${prefix}_pagingStore`,
    default: { page: defaultPage, pageSize: defaultPageSize }
  })
  
  const pagingSelector = selector({
    key: `${prefix}_paging`,
    get: ({ get }) => get(pagingAtom)
  })
  
  return {
    pagingAtom,
    pagingSelector,
  }
}

export function buildFilteredListConfigStores<TFilterValues>(prefix: string, defaultFilter: TFilterValues): IFilteredListConfigStore<TFilterValues> {
  const filterIsOpenAtom = atom<boolean>({
    key: `${prefix}_filterIsOpenStore`,
    default: false
  })
  
  const filterIsOpenSelector = selector({
    key: `${prefix}_filterIsOpen`,
    get: ({ get }) => get(filterIsOpenAtom)
  })
  
  const filterAtom = atom<TFilterValues>({
    key: `${prefix}_filterStore`,
    default: defaultFilter
  })
  
  const filterSelector = selector({
    key: `${prefix}_filter`,
    get: ({ get }) => get(filterAtom)
  })

  return {
    filterIsOpenAtom,
    filterIsOpenSelector,
    filterAtom,
    filterSelector
  }
}

export function buildSortedListConfigStores<TSortValues>(prefix: string, defaultSort: TSortValues): ISortedListConfigStore<TSortValues> {
  
  const sortAtom = atom<TSortValues>({
    key: `${prefix}_sortStore`,
    default: defaultSort
  })
  
  const sortSelector = selector({
    key: `${prefix}_sort`,
    get: ({ get }) => get(sortAtom)
  })

  return {
    sortAtom,
    sortSelector
  }
}