import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Input, Row, Typography, Alert, Space, Card } from 'antd';
import { LoginOutlined, UserOutlined, LockOutlined } from '@ant-design/icons';
import useAuth from '../../hooks/useAuth';
import useBackendForm from '../../hooks/useBackendForm';
import IBackendError from '../../types/BackendError';
const { Title } = Typography;

interface ILoginForm {
  UserName: string;
  Password: string;
}

const validateMessages = {
  required: "Обязательное поле",
};

export function Login() {
  const unmounted = useRef(false);
  const auth = useAuth();
  const backendForm = useBackendForm<ILoginForm>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    return function cleanup() {
      unmounted.current = true;
    };
  }, []);

  async function onFinish(values: ILoginForm) {
    setIsLoading(true);
    backendForm.resetErrors();
    try {
      await auth.signin(values.UserName, values.Password);
    }
    catch (error) {
      backendForm.setErrors(error as IBackendError);
    }
    finally {
      if (!unmounted.current) setIsLoading(false);
    }
  }

  function renderErrors() {
    return backendForm.generalErrors?.map((error, index) =>
      <Alert
        key={`login_error_${index}`}
        type="error"
        message={error}
      />
    )
  }

  return (
    <div className="container">
      <Row justify="center" className="u-mt-9">
        <Card title={<Title>Вход</Title>} bordered={false} style={{ width: '350px' }}>
          <Space direction="vertical" style={{ width: '100%' }}>
            {renderErrors()}

            <Form
              form={backendForm.form}
              validateMessages={validateMessages}
              name="login"
              className="login-form"
              onFinish={onFinish}
            >
              <Form.Item
                name="UserName"
                rules={[{ required: true }]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Логин"
                />
              </Form.Item>
              <Form.Item
                name="Password"
                rules={[{ required: true }]}
              >
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Пароль"
                />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  style={{ width: '100%' }}
                  icon={<LoginOutlined />}
                  loading={isLoading}
                >
                  Войти
                </Button>
              </Form.Item>
            </Form>
          </Space>
        </Card>
      </Row>
    </div>
  );
}