import { useSetRecoilState, useRecoilValue } from 'recoil';
import IPagingConfig from '../../../types/list/PagingConfig';
import IFilterConfig from '../../../types/list/FilterConfig';
import ISortConfig from '../../../types/list/SortConfig';
import SortDirection from '../../../types/list/SortDirection';
import { buildFilteredListConfigStores, buildPagedListConfigStores, buildSortedListConfigStores } from '../../../types/list/ListConfigStore';
import { ClientFreeRequestSettingsState, ClientState, SubscriptionState } from '../../../types/backend/Clients';

const prefix = 'clientList';

export interface ISortValues {
  currentBalance: SortDirection | null;
  period: SortDirection | null;
}

const defaultSort: ISortValues = {
  currentBalance: null,
  period: null
}

export interface IFilterValues {
  name: string | undefined;
  managers: number[];
  clientState: ClientState | undefined;
  subscriptionState: SubscriptionState | undefined;
  subscriptionProducts: string[];
  freeRequestSettingsProducts: string[];
  freeRequestSettingsState: ClientFreeRequestSettingsState | undefined;
}

export const defaultFilter: IFilterValues = {
  name: undefined,
  managers: [],
  clientState: undefined,
  subscriptionState: undefined,
  subscriptionProducts: [],
  freeRequestSettingsState: undefined,
  freeRequestSettingsProducts: []
}

const { pagingAtom, pagingSelector } = buildPagedListConfigStores(prefix);
const { filterIsOpenAtom, filterIsOpenSelector, filterAtom, filterSelector } = buildFilteredListConfigStores(prefix, defaultFilter);
const { sortAtom, sortSelector } = buildSortedListConfigStores(prefix, defaultSort);

interface IConfig extends IPagingConfig, IFilterConfig<IFilterValues>, ISortConfig<ISortValues> { }

export function useClientListConfig(): IConfig {
  const setPaging = useSetRecoilState(pagingAtom);
  const paging = useRecoilValue(pagingSelector);
  const setFilterIsOpen = useSetRecoilState(filterIsOpenAtom);
  const filterIsOpen = useRecoilValue(filterIsOpenSelector);
  const setFilter = useSetRecoilState(filterAtom);
  const filter = useRecoilValue(filterSelector);
  const setSort = useSetRecoilState(sortAtom);
  const sort = useRecoilValue(sortSelector);

  return {
    isFilterOpen: filterIsOpen,
    openFilter: () => setFilterIsOpen(true),
    closeFilter: () => setFilterIsOpen(false),
    filter: filter,
    resetFilter: () => setFilter(defaultFilter),
    setFilter: (values: IFilterValues) => setFilter(values),
    paging,
    setPaging: (page: number, pageSize: number) => setPaging({ page, pageSize }),
    setSort: (values: ISortValues) => setSort(values),
    sort: sort,
    resetSort: () => setSort(defaultSort)
  }
}