import httpClient, { HttpError, HttpMethod, ContentType } from "../httpClient";
import IBackendError, { BackendError, BackendValidationError } from '../../types/BackendError';

async function makeBackendRequest<TResponse, TResult>(
  url: string,
  method: HttpMethod,
  transformResponse: (response: TResponse) => TResult,
  requestData: any,
  contentType?: ContentType): Promise<TResult> {
  try {
    const result = await httpClient.makeRequest(url, method, transformResponse, requestData, contentType ?? "json");
    return result;
  }
  catch (httpError) {
    throw mapError(httpError as HttpError);
  }
}

interface IBackendHttpErrorWrapper<TValue> {
  values: TValue[];
}

interface IBackendHttpError {
  message: string;
}

interface IBackendValidationHttpError {
  fieldName: string;
  message: string;
}

function mapError(httpError: HttpError): IBackendError {
  if (httpError.statusCode === 400) {
      const backendHttpError = httpError.data as IBackendHttpErrorWrapper<IBackendValidationHttpError>;
      return new BackendValidationError(backendHttpError?.values);
  }
  else {
      const backendHttpError = httpError.data as IBackendHttpErrorWrapper<IBackendHttpError>;
      return new BackendError(httpError.statusCode, backendHttpError?.values);
  }
}

export default makeBackendRequest;