import makeBackendRequest from "../makeBackendRequest";
import { ICurrentUser } from '../../../types/backend/Account';

interface IUserPermissionsResponse {
  canViewClients: boolean;
  canViewUsers: boolean;
  canEditUsers: boolean;
  canEditClients: boolean;
  canEditSpecialTariffs: boolean;
  canViewInvoices: boolean;
  canViewInvoicesOnClient: boolean;
  canConfirmInvoices: boolean;
  canCancelInvoices: boolean;
}

interface ICurrentUserResponse {
  userName: string;
  permissions: IUserPermissionsResponse;
}

interface IAccountApi {
  login(userName: string, password: string): Promise<ICurrentUser>;
  logout(): Promise<void>;
  getCurrentUser(): Promise<ICurrentUser | null>;
}

const accountApi: IAccountApi = {
  login(userName: string, password: string): Promise<ICurrentUser> {
    return makeBackendRequest<ICurrentUserResponse, ICurrentUser>(
      '/api/account/login',
      "post",
      response => ({ ...response }),
      { userName: userName, password: password }
    );
  },

  logout(): Promise<void> {
    return makeBackendRequest<void, void>(
      '/api/account/logout',
      "post",
      () => { },
      undefined
    );
  },

  getCurrentUser(): Promise<ICurrentUser | null> {
    return makeBackendRequest<ICurrentUserResponse | null, ICurrentUser | null>(
      '/api/account/getCurrentUser',
      "get",
      response => {
        if (response) {
          return { ...response };
        }
        else {
          return null
        }
      },
      undefined
    );
  },
}

export default accountApi;