import moment, { utc } from "moment";
import { ClientNotificationType, IClientNotificationSettings, IClientTelegramBindingsListItem } from "../../../../types/backend/Clients";
import IPagedList from "../../../../types/backend/PagedList";
import useBackendRequest from "../../useBackendRequest";

interface ITelegramNotificationMetaResponse {
  id: string;
  date: Date;
  type: ClientNotificationType;
}

interface IClientTelegramBindingsListItemResponse {
  id: string;
  userName: string | null;
  lastNotification: ITelegramNotificationMetaResponse | null;
  alreadyBinded: boolean;
  disabled: boolean;
  link: string;
}

interface IClientNotificationsApi {
  getSettings(clientId: string): Promise<IClientNotificationSettings>;
  disable(settingsId: string): Promise<void>;
  enable(settingsId: string): Promise<void>;
  disableTelegramBinding(bindingId: string): Promise<void>;
  enableTelegramBinding(bindingId: string): Promise<void>;
  getTelegram(clientId: string, page: number, pageSize: number): Promise<IPagedList<IClientTelegramBindingsListItem>>;
  createTelegramBinding(clientId: string): Promise<void>;
}

export function useClientNotificationsApi(): IClientNotificationsApi {

  const { makeRequest } = useBackendRequest();

  function getSettings(clientId: string): Promise<IClientNotificationSettings> {
    return makeRequest<IClientNotificationSettings, IClientNotificationSettings>(
      `/api/clients/${clientId}/notifications/settings`,
      'get',
      response => response,
      undefined
    )
  }

  function getTelegram(clientId: string, page: number, pageSize: number): Promise<IPagedList<IClientTelegramBindingsListItem>> {
    const params = new URLSearchParams({
      page: page.toString(),
      pageSize: pageSize.toString(),
    })

    return makeRequest<IPagedList<IClientTelegramBindingsListItemResponse>, IPagedList<IClientTelegramBindingsListItem>>(
      `/api/clients/${clientId}/notifications/bindings/telegram?${params.toString()}`,
      'get',
      response => ({
        ...response,
        list: response.list.map(value => ({
          ...value,
          lastNotification: value.lastNotification === null
            ? null
            : { ...value.lastNotification, date: utc(value.lastNotification.date) }
        }))
      }),
      undefined
    )
  }

  function createTelegramBinding(clientId: string): Promise<void> {
    return makeRequest<void, void>(
      `/api/clients/${clientId}/notifications/bindings/telegram`,
      'post',
      () => {},
      undefined
    )
  }

  function disable(settingsId: string): Promise<void> {
    return makeRequest<void, void>(
      `/api/clients/notifications/settings/${settingsId}`,
      'patch',
      () => {},
      {
        disabled: true
      }
    )
  }
  
  function enable(settingsId: string): Promise<void> {
    return makeRequest<void, void>(
      `/api/clients/notifications/settings/${settingsId}`,
      'patch',
      () => {},
      {
        disabled: false
      }
    )

  }

  function disableTelegramBinding(bindingId: string): Promise<void> {
    return makeRequest<void, void>(
      `/api/clients/notifications/bindings/telegram/${bindingId}`,
      'patch',
      () => {},
      {
        disabled: true
      }
    )

  }

  function enableTelegramBinding(bindingId: string): Promise<void> {
    return makeRequest<void, void>(
      `/api/clients/notifications/bindings/telegram/${bindingId}`,
      'patch',
      () => {},
      {
        disabled: false
      }
    )
  }

  return {
    getSettings,
    enable,
    enableTelegramBinding,
    disable,
    disableTelegramBinding,
    getTelegram,
    createTelegramBinding,
  }
}