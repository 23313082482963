import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { Alert, Button, Card, Form, PageHeader, Space, DatePicker } from 'antd';
import useAuth from '../../../hooks/useAuth';
import useIdentityApi from '../../../hooks/backend/api/useIdentityApi';
import { IUserLocking } from '../../../types/backend/Identity';
import { Wrapper, WrapperState } from '../../Wrapper';
import useBackendForm, { } from '../../../hooks/useBackendForm';
import IBackendError from '../../../types/BackendError';

interface IUserLockingForm {
  LockoutEnd: string;
}

const initialValues: IUserLockingForm = {
  LockoutEnd: '',
}

export function UserLocking() {
  const unmounted = useRef(false);
  const identityApi = useIdentityApi();
  const [isLoading, setIsLoading] = useState(false);
  const [initError, setInitError] = useState<any>(undefined);
  const [wrapperState, setWrapperState] = useState<WrapperState | null>(null);
  const [currentUserLocking, setCurrentUserLocking] = useState<IUserLocking>({
    lockoutEnd: null,
    userName: ''
  });
  const { currentUser } = useAuth();
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();
  const backendForm = useBackendForm<IUserLockingForm>();
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    return function cleanup() {
      unmounted.current = true;
    };
  }, []);

  useEffect(() => {
    async function fetchData(userId: string) {
      setInitError(undefined);
      setIsLoading(true);
      try {
        const receivedUserLocking = await identityApi.getUserLocking(
          userId
        );

        if (!unmounted.current) {
          setCurrentUserLocking(receivedUserLocking);
          backendForm.form.setFieldsValue({
            LockoutEnd: receivedUserLocking.lockoutEnd
              ? receivedUserLocking.lockoutEnd.toString()
              : '',
          });
        }
      } catch (error) {
        if (!unmounted.current) setInitError(error);
      } finally {
        if (!unmounted.current) setIsLoading(false);
      }
    }

    if (params?.id && currentUser?.canEditUsers()) {
      fetchData(params.id);
    }
  }, [currentUser, params]);

  useEffect(() => {
    if (currentUser === null) setWrapperState('loading');
    else if (!currentUser?.canEditUsers()) setWrapperState('accessDenied');
    else if (isLoading) setWrapperState('loading');
    else if (initError) setWrapperState('hasErrors');
    else setWrapperState(null);
  }, [currentUser, isLoading, initError]);

  async function setUserLocking(userId: string, lockoutEnd: moment.Moment | null) {
    setIsProcessing(true);
    backendForm.resetErrors();

    try {
      await identityApi.setUserLocking(userId, lockoutEnd);
      navigate('/users');  
    } catch (error) {
      if (!unmounted.current) backendForm.setErrors(error as IBackendError);
    } finally {
      if (!unmounted) setIsProcessing(false);
    }
  }

  async function handleOnFinish(values: IUserLockingForm) {
    if (!params?.id) return;

    if (currentUserLocking.lockoutEnd) await setUserLocking(params.id, null);
    else if (values.LockoutEnd) {
      const lockoutEndDate = moment(new Date(values.LockoutEnd));
      if (window.confirm(`Заблокировать пользователя до ${lockoutEndDate.format('DD.MM.yyyy HH:mm:ss')}?`)) {
        await setUserLocking(params.id, lockoutEndDate)
      }
    }
  }

  const onEditUser = () => navigate(`/users/${params.id}`);

  return (
    <Wrapper state={wrapperState} error={initError}>
      <Card
        bordered={false}
        title={(
          <PageHeader
            title={
              currentUserLocking.lockoutEnd
                ? `Разблокировка пользователя ${currentUserLocking.userName}`
                : `Блокировка пользователя ${currentUserLocking.userName}`
            }
            extra={<Button onClick={onEditUser}>Редактировать пользователя</Button>}
            onBack={() => navigate('/users')}
          />
        )}
      >
        <Space direction="vertical">
          {currentUserLocking.lockoutEnd && (
            <Alert
              message={
                <span>
                  {`Дата завершения блокировки: ${currentUserLocking.lockoutEnd.format('DD.MM.yyyy HH:mm:ss')}`}
                </span>
              }
              type="info"
              className="u-mb-4"
            />
          )}
          <Form
            form={backendForm.form}
            initialValues={initialValues}
            onFinish={handleOnFinish}
            wrapperCol={{ span: 12 }}
          >
            {!currentUserLocking.lockoutEnd && (
              <Form.Item
                name="LockoutEnd"
                label="Дата завершения блокировки"
                rules={[
                  { required: true, message: 'Необходимо указать дату' },
                  () => ({
                    validator(_, value) {
                      if (moment(value) <= moment()) {
                        return Promise.reject(new Error('Дата должна быть в будущем'));
                      }
                      return Promise.resolve();
                    },
                  })
                ]}
              >
                <DatePicker showTime placeholder='' />
              </Form.Item>
            )}
            <Form.Item>
              <Button
                block
                type="primary"
                htmlType="submit"
                loading={isProcessing}
              >
                {currentUserLocking.lockoutEnd
                  ? 'Разблокировать'
                  : 'Заблокировать'}
              </Button>
            </Form.Item>
          </Form>
        </Space>
      </Card>
    </Wrapper>
  );
}