import React, { useEffect, useState, useRef } from 'react';
import { Form, InputNumber, Modal, Select, Space, Alert, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import useBackendForm from '../../../../../hooks/useBackendForm';
import emptyGuid from '../../../../../types/EmptyGuid';
import useProductsApi from '../../../../../hooks/backend/api/useProductsApi';
import useClientFreeRequestsApi from '../../../../../hooks/backend/api/clients/useClientFreeRequestsApi';
import { Wrapper, WrapperState } from '../../../../Wrapper';
import { IUserProfile } from '../../../../../hooks/useAuth';
import { IProduct } from '../../../../../types/backend/Products';
import IBackendError from '../../../../../types/BackendError';

interface IFreeRequestSettingsConnectionProps {
  currentUser: IUserProfile
  clientId: string;
  visible: boolean;
  handleConnectionCancel(): void;
  handleConncectionOk(productName: string, count: number): void;
}

interface IForm {
  ProductId: string;
  Count: number;
  Comment: string;
}

const zeroProduct = { id: emptyGuid, name: '-', hasTariffs: false };

export function FreeRequestSettingsConnection(props: IFreeRequestSettingsConnectionProps) {
  const { clientId, visible, handleConncectionOk, handleConnectionCancel, currentUser } = props;

  const unmounted = useRef(false);
  const backendForm = useBackendForm<IForm>();
  const productsApi = useProductsApi();
  const clientFreeRequestsApi = useClientFreeRequestsApi();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [initError, setInitError] = useState<any>(undefined);
  const [wrapperState, setWrapperState] = useState<WrapperState | null>(null);
  const [products, setProducts] = useState<IProduct[]>([]);

  useEffect(() => {
    return () => { unmounted.current = true; };
  }, []);

  async function fetchData() {
    setInitError(undefined);
    setIsLoading(true);

    try {
      if (visible) {
        const receivedProducts = await productsApi.getProducts();
        if (!unmounted.current) setProducts([zeroProduct, ...receivedProducts]);
      }
    } catch (error) {
      if (!unmounted.current) setInitError(error);
    } finally {
      if (!unmounted.current) setIsLoading(false);
    }
  }

  useEffect(() => {
    setProducts([zeroProduct]);

    backendForm.form.setFieldsValue({
      ProductId: emptyGuid,
      Count: 0,
      Comment: ''
    });

    if (currentUser?.canViewClients() && visible) {
      fetchData();
    }
  }, [currentUser, visible])

  useEffect(() => {
    if (currentUser === null) setWrapperState('loading');
    else if (!currentUser.canViewClients()) setWrapperState('accessDenied');
    else if (isLoading) setWrapperState('loading');
    else if (initError) setWrapperState('hasErrors');
    else setWrapperState(null);
  }, [currentUser, initError, isLoading]);

  async function handleOnFinish(values: IForm) {
    let successful = false;
    setIsProcessing(true);

    try {
      await clientFreeRequestsApi.add(
        clientId, values.ProductId, values.Count, values.Comment);
      successful = true;
    } catch (error) {
      if (!unmounted.current) backendForm.setErrors(error as IBackendError);
    } finally {
      if (!unmounted.current) setIsProcessing(false);
    }

    if (successful) {
      const product = products.find(e => e.id === values.ProductId);
      handleConncectionOk(product?.name ?? 'Неизвестно', values.Count);
    }
  }

  function renderErrors() {
    return backendForm.generalErrors?.map((error, index) =>
      <Alert
        key={`error_${index}`}
        type="error"
        message={error}
      />
    )
  }

  return (
    <Modal
      destroyOnClose
      closable={false}
      maskClosable={false}
      okText='Подключить'
      onOk={backendForm.form.submit}
      okButtonProps={{ loading: isProcessing, disabled: isLoading }}
      cancelText='Отмена'
      onCancel={handleConnectionCancel}
      cancelButtonProps={{ disabled: isProcessing }}
      visible={visible}
      title='Подключение бесплатных запросов'
    >
      <Wrapper
        state={wrapperState}
        error={initError}
      >
        <Space direction='vertical' style={{ width: '100%' }}>
          <Form
            form={backendForm.form}
            onFinish={handleOnFinish}
            labelCol={{ span: 5 }}
          >
            <Form.Item
              name='ProductId'
              label='Продукт'
              rules={[
                { required: true, message: 'Обязательное поле' },
                () => ({
                  validator(_, value) {
                    if (value === emptyGuid) return Promise.reject('Необходимо выбрать продукт');
                    return Promise.resolve();
                  },
                })
              ]}
            >
              <Select
                loading={isLoading}
                options={products.map(value => ({ label: value.name, value: value.id }))}
              />
            </Form.Item>
            <Form.Item
              name='Count'
              label='Количество'
              rules={[
                { required: true, message: 'Обязательное поле' },
                { pattern: new RegExp('^[0-9]+$'), message: 'Допускаются только целые числа' },
                () => ({
                  validator(_, value) {
                    if (value > 0) return Promise.resolve();
                    return Promise.reject('Необходимо указать количество');
                  },
                }),
              ]}
            >
              <InputNumber
                min={0}
                precision={0}
                onFocus={e => e.target.select()}
              />
            </Form.Item>
            <Form.Item name='Comment' label='Комментарий'>
              <Input onFocus={e => e.target.select()} />
            </Form.Item>
          </Form>
          {renderErrors()}
        </Space>
      </Wrapper>
    </Modal>
  )
}