import moment, { Moment } from "moment";
import { IFilterValues } from "../../../components/Invoices/InvoiceListConfig";
import IPagedList from "../../../types/backend/PagedList";
import useBackendRequest from "../useBackendRequest";
import { InvoiceState, IInvoiceListItem, IInvoiceWithClientListItem } from '../../../types/backend/Invoices';

interface IInvoiceListItemResponse {
  id: string;
  number: string;
  created: Moment;
  paymentDate: Moment | null;
  amount: number;
  state: string;
  stateValue: InvoiceState;
}

interface IInvoiceListItemResponse {
  id: string;
  number: string;
  created: Moment;
  paymentDate: Moment | null;
  amount: number;
  state: string;
  stateValue: InvoiceState;
}

interface IInvoiceWithClientListItemResponse extends IInvoiceListItemResponse {
  clientId: string;
  clientINN: string;
  managerId: string;
  managerName: string;
}

interface IInvoicesApi {
  create(clientId: string, amount: number): Promise<void>;
  confirm(invoiceId: string): Promise<void>;
  cancel(invoiceId: string): Promise<void>;
  get(page: number, pageSize: number, filterData: IFilterValues): Promise<IPagedList<IInvoiceWithClientListItem>>;
  getByClientId(clientId: string, page: number, pageSize: number): Promise<IPagedList<IInvoiceListItem>>;
  download(invoiceId: string): Promise<Blob>;
}

export default function useInvoicesApi(): IInvoicesApi {
  const { makeRequest } = useBackendRequest();

  function create(clientId: string, amount: number) {
    return makeRequest<void, void>(
      `/api/invoices?clientId=${clientId}&amount=${amount}`,
      'post',
      () => { },
      undefined
    )
  }

  function get(page: number, pageSize: number, filterData: IFilterValues) {
    let url = `/api/invoices?page=${page}&pageSize=${pageSize}`;

    if (filterData.paymentPending) {
      url += `&state=1`
    }

    return makeRequest<IPagedList<IInvoiceWithClientListItemResponse>, IPagedList<IInvoiceWithClientListItem>>(
      url,
      'get',
      response => (
        {
          ...response,
          list: response.list.map(value => (
            {
              ...value,
              created: moment(value.created),
              paymentDate: value.paymentDate && moment(value.paymentDate)
            }
          ))
        }
      ),
      undefined
    )
  }

  function getByClientId(clientId: string, page: number, pageSize: number) {
    return makeRequest<IPagedList<IInvoiceListItemResponse>, IPagedList<IInvoiceListItem>>(
      `/api/invoices/getByClient?clientId=${clientId}&page=${page}&pageSize=${pageSize}`,
      'get',
      response => (
        {
          ...response,
          list: response.list.map(value => (
            {
              ...value,
              created: moment(value.created),
              paymentDate: value.paymentDate && moment(value.paymentDate)
            }
          ))
        }
      ),
      undefined
    )
  }

  function confirm(invoiceId: string) {
    return makeRequest<void, void>(
      `/api/invoices/${invoiceId}/confirm`,
      'post',
      () => { },
      undefined
    )
  }

  function cancel(invoiceId: string) {
    return makeRequest<void, void>(
      `/api/invoices/${invoiceId}/cancel`,
      'post',
      () => { },
      undefined
    )
  }

  function download(invoiceId: string) {
    return makeRequest<Blob, Blob>(
      `/api/invoices/${invoiceId}/download`,
      'get',
      response => response,
      undefined,
      "pdf"
    )
  }

  return {
    create,
    get,
    getByClientId,
    confirm,
    cancel,
    download
  }
}