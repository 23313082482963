import { ActivationIssue, DeactivationIssue } from "../../../../types/backend/Clients";
import useBackendRequest from "../../useBackendRequest";

interface IClientSubscriptionApi {
  checkActivationIssues(clientId: string): Promise<ActivationIssue[]>;
  activate(clientId: string): Promise<void>;
  checkClosingIssues(clientId: string): Promise<DeactivationIssue[]>;
  close(clientId: string): Promise<void>;
}

export default function useClientSubscriptionApi(): IClientSubscriptionApi {
  const { makeRequest } = useBackendRequest();

  function activate(clientId: string): Promise<void> {
    return makeRequest<void, void>(
      `/api/clients/${clientId}/activate`,
      'post',
      () => { },
      undefined
    )
  }

  function close(clientId: string): Promise<void> {
    return makeRequest<void, void>(
      `/api/clients/${clientId}/deactivate`,
      'post',
      () => { },
      undefined
    );
  }

  function checkActivationIssues(clientId: string): Promise<ActivationIssue[]> {
    return makeRequest<ActivationIssue[], ActivationIssue[]>(
      `/api/clients/${clientId}/activation/check`,
      'get',
      response => response,
      {}
    )
  }

  function checkClosingIssues(clientId: string): Promise<DeactivationIssue[]> {
    return makeRequest<DeactivationIssue[], DeactivationIssue[]>(
      `/api/clients/${clientId}/deactivation/check`,
      'get',
      response => response,
      {}
    )
  }

  return {
    checkActivationIssues,
    activate,
    checkClosingIssues,
    close,
  }
}