import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Form, PageHeader, Input, Select, Space, Alert, Divider } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import useIdentityApi from '../../../hooks/backend/api/useIdentityApi';
import { IRolesListItem, IUser } from '../../../types/backend/Identity';
import { Wrapper, WrapperState } from '../../Wrapper';
import emptyGuid, { isEmptyGuid } from '../../../types/EmptyGuid';
import useBackendForm from '../../../hooks/useBackendForm';
import IBackendError from '../../../types/BackendError';

interface IUserForm {
  RoleId: string;
  UserName: string;
  FirstName: string;
  LastName: string;
  MiddleName: string;
  Password?: string;
  ConfirmPassword?: string;
}

const initialValues: IUserForm = {
  RoleId: emptyGuid,
  UserName: '',
  FirstName: '',
  LastName: '',
  MiddleName: ''
};

export function UserCard() {
  const unmounted = useRef(false);
  const identityApi = useIdentityApi();
  const [isLoading, setIsLoading] = useState(false);
  const [initError, setInitError] = useState<any>(undefined);
  const [wrapperState, setWrapperState] = useState<WrapperState | null>(null);
  const [roles, setRoles] = useState<IRolesListItem[]>([]);
  const [editableUser, setEditableUser] = useState<IUser | null>(null);
  const { currentUser } = useAuth();
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [selectedRole, setSelectedRole] = useState<IRolesListItem>();
  const backendForm = useBackendForm<IUserForm>();
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  useEffect(() => {
    async function fetchData() {
      setInitError(undefined);
      setIsLoading(true);
      try {
        const receivedRoles = await identityApi.getRoles();
        if (!unmounted.current) {
          setRoles([{ id: emptyGuid, name: '-', canBeManager: false }, ...receivedRoles]);
        }
        if (params?.id) {
          const receivedUser = await identityApi.getUser(params.id);
          if (!unmounted.current) {
            setEditableUser(receivedUser);
            backendForm.form.setFieldsValue({
              UserName: receivedUser.userName,
              RoleId: receivedUser.roleId,
              FirstName: receivedUser.manager?.firstName,
              LastName: receivedUser.manager?.lastName,
              MiddleName: receivedUser.manager?.middleName
            });
            setSelectedRole(receivedRoles.find(r => r.id == receivedUser.roleId))
          }
        }
      } catch (error) {
        if (!unmounted.current) setInitError(error);
      } finally {
        if (!unmounted.current) setIsLoading(false);
      }
    }

    if (currentUser?.canEditUsers()) {
      fetchData();
    }
  }, [currentUser, params]);

  useEffect(() => {
    if (currentUser === null) setWrapperState('loading');
    else if (!currentUser.canEditUsers()) setWrapperState('accessDenied');
    else if (isLoading) setWrapperState('loading');
    else if (initError) setWrapperState('hasErrors');
    else setWrapperState(null);
  }, [currentUser, isLoading, initError]);

  async function createUser(values: IUserForm) {
    if (values.Password && values.ConfirmPassword) {
      await identityApi.createUser(
        values.UserName,
        values.FirstName,
        values.LastName,
        values.MiddleName,
        values.Password,
        values.ConfirmPassword,
        values.RoleId
      );

      navigate('/users');
    }
  }

  async function editUser(userId: string, values: IUserForm) {
    await identityApi.editUser(
      userId,
      values.UserName,
      values.FirstName,
      values.LastName,
      values.MiddleName,
      values.RoleId
    );

    navigate('/users');
  }

  function handleOnChangeRole(value: string) {
    const role = roles.find(role => { return role.id === value });
    setSelectedRole(role);
  }

  async function handleOnFinish(values: IUserForm) {
    setIsProcessing(true);
    backendForm.resetErrors();
    try {
      if (editableUser) await editUser(editableUser.id, values);
      else if (values.Password && values.ConfirmPassword) await createUser(values);
    } catch (error) {
      if (!unmounted.current) backendForm.setErrors(error as IBackendError);
    } finally {
      if (!unmounted.current) setIsProcessing(false);
    }
  }
  const onLockUser = () => navigate(`/users/${params.id}/locking`);

  function renderErrors() {
    return backendForm.generalErrors?.map((error, index) =>
      <Alert
        key={`login_error_${index}`}
        type="error"
        message={error}
      />
    )
  }

  return (
    <Wrapper state={wrapperState} error={initError}>
      <Card
        bordered={false}
        title={(
          <PageHeader
            title={
              editableUser ? 'Редактирование пользователя' : 'Создание пользователя'
            }
            subTitle={editableUser ? editableUser.userName : null}
            extra={
              editableUser && <Button disabled={!currentUser?.canEditUsers()} onClick={onLockUser}>
                Блокировать пользователя
              </Button>
            }
            onBack={() => navigate('/users')}
          />
        )}
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          <Form
            form={backendForm.form}
            initialValues={initialValues}
            autoComplete="off"
            onFinish={handleOnFinish}
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 8 }}
          >
            <Form.Item
              name="UserName"
              label="Логин"
              rules={[{ required: true, message: 'Необходимо указать логин' }]}
            >
              <Input />
            </Form.Item>
            {!editableUser && (
              <>
                <Form.Item
                  name="Password"
                  label="Пароль"
                  rules={[{ required: true, message: 'Необходимо указать пароль' }]}
                >
                  <Input type="password" autoComplete="off" />
                </Form.Item>
                <Form.Item
                  name="ConfirmPassword"
                  label="Подтверждение пароля"
                  rules={[
                    { required: true, message: 'Необходимо подтвердить пароль' },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('Password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('Пароли должны совпадать'));
                      },
                    })
                  ]}
                >
                  <Input type="password" autoComplete="off" />
                </Form.Item>
              </>
            )}
            <Form.Item
              name="RoleId"
              label="Выберите роль"
              rules={[
                () => ({
                  validator(_, value) {
                    if (isEmptyGuid(value)) {
                      return Promise.reject('Необходимо выбрать роль');
                    }
                    return Promise.resolve();
                  },
                })
              ]}
            >
              <Select
                allowClear
                options={roles.map((r) => ({ label: r.name, value: r.id }))}
                onChange={handleOnChangeRole}
              />
            </Form.Item>

            {selectedRole?.canBeManager && (
              <>
                <Divider orientation="left">Данные менеджера</Divider>
                <Form.Item name="LastName" label="Фамилия"><Input /></Form.Item>
                <Form.Item name="FirstName" label="Имя"><Input /></Form.Item>
                <Form.Item name="MiddleName" label="Отчество"><Input /></Form.Item>
              </>
            )}

            <Form.Item wrapperCol={{ span: 8, offset: 3 }}>
              <Button
                block
                type="primary"
                htmlType="submit"
                loading={isProcessing}
              >
                {editableUser ? 'Редактировать' : 'Создать'}
              </Button>
            </Form.Item>
          </Form>
          {renderErrors()}
        </Space>
      </Card>
    </Wrapper>
  );
};