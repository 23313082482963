import React, { useState } from 'react';
import { Col, Descriptions, InputNumber, Row, Tooltip } from 'antd';
import useDashboardApi from '../../../hooks/backend/api/useDashboardApi';
import { IClientBalanceLeftForSomeDays } from '../../../types/backend/Dashboard';
import { buildCollapsedStores } from '../stores/CollapsedStore';
import { buildParamsStores, useParamsStore } from '../stores/ParamsStore';
import { buildPagingStore } from '../stores/PagingStore';
import { DashboardPanel } from '../Common/DashboardPanel';

const daysCountMin = 1;
const daysCountDefault = 3;

const prefix = 'balanceLeftForSomeDays';

const collapsedStoreConfig = buildCollapsedStores(prefix, true);
const pagingStoreConfig = buildPagingStore(prefix);
const { paramsAtom, paramsSelector } = buildParamsStores(prefix, daysCountDefault);

export function BalanceLeftForSomeDays() {
  const { params, setParams } = useParamsStore(paramsAtom, paramsSelector);
  const dashboardApi = useDashboardApi();

  const [subTitle, setSubTitle] = useState(getSubTitle);

  function getSubTitle() {
    return `Дней: ${params}`
  }

  function fetchData(page: number, pageSize: number) {
    setSubTitle(getSubTitle());
    return dashboardApi.clientsBalanceLeftForSomeDays(params, page, pageSize);
  }

  function handleOnChangeDaysCount(value: number) {
    setParams(value === null ? daysCountMin : value);
  }

  function getItemDescription(item: IClientBalanceLeftForSomeDays) {
    const balanceStr = Intl.NumberFormat('en-us', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      .format(item.balance ?? 0);
    return `Баланс: ${balanceStr} руб.`
  }

  function getItemInfo(item: IClientBalanceLeftForSomeDays) {
    return (
      <Descriptions
        column={1}
        size='small'
        bordered
      >
        <Descriptions.Item label='Период подписки'>
          {`${item.billingPeriodStartDate.format('DD.MM.yyyy')}- ${item.billingPeriodEndDate.format('DD.MM.yyyy')}`}
        </Descriptions.Item>
        <Descriptions.Item label='Средне-дневное потребление'>
          {Intl.NumberFormat('en-us', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            .format(item.averageDailyConsumption)}
        </Descriptions.Item>
      </Descriptions>
    )
  }

  function renderParams(isLoading: boolean) {
    return (
      <InputNumber
        disabled={isLoading}
        min={daysCountMin}
        max={30}
        precision={0}
        value={params}
        onChange={handleOnChangeDaysCount}
        onFocus={e => e.target.select()}
      />
    )
  }

  return (
    <DashboardPanel
      title='Денег осталось'
      subTitle={subTitle}
      fetchData={fetchData}
      collapsedStoreConfig={collapsedStoreConfig}
      pagingStoreConfig={pagingStoreConfig}
      getItemDescription={getItemDescription}
      renderParams={renderParams}
      getItemInfo={getItemInfo}
    />
  )
}