import useBackendRequest from '../useBackendRequest';

interface ILicenseAgreementApi {
  create(clientId: string): Promise<void>;
  reject(licenseAgreementId: string, comment: string): Promise<void>;
  download(licenseAgreementId: string, withStamp: boolean): Promise<Blob>;
  sign(licenseAgreementId: string): Promise<void>;
  terminate(licenseAgreementId: string, comment: string): Promise<void>;
}

export default function useLicenseAgreementApi(): ILicenseAgreementApi {
  const { makeRequest } = useBackendRequest();

  function create(clientId: string): Promise<void> {
    return makeRequest<void, void>(
      `/api/licenseAgreements?clientId=${clientId}`,
      'post',
      () => { },
      undefined
    )
  }

  function reject(licenseAgreementId: string, comment: string) {
    return makeRequest<void, void>(
      `/api/licenseAgreements/${licenseAgreementId}/reject`,
      'post',
      () => { },
      { comment }
    )
  }

  function download(licenseAgreementId: string, withStamp: boolean) {
    return makeRequest<Blob, Blob>(
      `/api/licenseAgreements/${licenseAgreementId}/download?withStamp=${withStamp}`,
      'get',
      response => response,
      undefined,
      "zip"
    )
  }

  function sign(licenseAgreementId: string) {
    return makeRequest<void, void>(
      `/api/licenseAgreements/${licenseAgreementId}/sign`,
      'post',
      () => { },
      undefined
    )
  }

  function terminate(licenseAgreementId: string, comment: string) {
    return makeRequest<void, void>(
      `/api/licenseAgreements/${licenseAgreementId}/terminate`,
      'post',
      () => { },
      { comment }
    )
  }

  return {
    create,
    reject,
    download,
    sign,
    terminate
  }
}