import React, { useEffect, useState } from 'react';
import { useRoutes } from 'react-router-dom';
import { Result } from 'antd';
import { Layout } from './components/Layout';
import { Login, RequireAuth } from './components/Security';
import { NoMatch } from './components/NoMatch';
import { PageLoading } from './components/PageLoading';
import { Dashboard } from './components/Dashboard';
import { UserCard, UserList, UserLocking } from './components/Users';
import { ClientCard, ClientCreation, ClientList, ClientRequisitesEdit } from './components/Clients';
import { InvoiceList } from './components/Invoices';
import useAuth from './hooks/useAuth';
import './styles/App.scss';

type UserCheckingState = "Checked" | "Checking" | "Error";

export default function App() {
  const routes = useRoutes([
    {
      path: '/',
      element: <RequireAuth><Layout /></RequireAuth>,
      children: [
        { index: true, element: <Dashboard /> },
        { path: 'users/creation', element: <UserCard /> },
        { path: 'users/:id/locking', element: <UserLocking /> },
        { path: 'users/:id', element: <UserCard /> },
        { path: 'users', element: <UserList /> },
        { path: 'clients/creation', element: <ClientCreation /> },
        //{ path: '/clients/:id/editRequisites', element: {ClientRequisitesEdit}, },
        { path: '/clients/:id/edit', element: <ClientCreation /> },
        { path: '/clients/:id', element: <ClientCard /> },
        { path: 'clients', element: <ClientList /> },
        { path: 'invoices', element: <InvoiceList />, },
      ]
    },
    { path: '/login', element: <Login /> },
    { path: '*', element: <NoMatch /> }
  ]);
  const auth = useAuth();
  const [userCheckingState, setUserCheckingState] = useState<UserCheckingState | undefined>(undefined);

  useEffect(() => {
    async function fetchData() {
      setUserCheckingState("Checking")
      try {
        await auth.refresh();
        setUserCheckingState("Checked")
      }
      catch (error) {
        setUserCheckingState("Error")
      }
    }

    fetchData();
  }, []);

  function render() {
    switch (userCheckingState) {
      case "Checked":
        return routes;
      case "Error":
        return <Result status="error" />
      default:
        return <PageLoading />
    }
  }

  return render();
}