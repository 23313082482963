import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import { UserOutlined, LogoutOutlined, TeamOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Avatar, Layout as AntdLayout, Menu, Button, Popover, Space, Row, Col } from 'antd';
import useAuth from '../../hooks/useAuth';
import './Layout.scss';

const { Header, Content, Sider } = AntdLayout;

export function Layout() {
  const { currentUser, signout } = useAuth();

  function renderUserListMenuItem() {
    const usersMenuItem = currentUser?.canViewUsers() &&
      <Menu.Item key="/users" icon={<TeamOutlined />}>
        <Link to="/users">Пользователи</Link>
      </Menu.Item>;

    return [usersMenuItem];
  }

  function renderClienListMenuItem() {
    const clientsMenuItem = currentUser?.canViewClients() &&
      <Menu.Item key="/clients" icon={<TeamOutlined />}>
        <Link to="/clients">Клиенты</Link>
      </Menu.Item>;

    return [clientsMenuItem];
  }

  function renderInvoicesMenuItem() {
    const invoicesMenuItem = currentUser?.canViewInvoices() &&
      <Menu.Item key="/invoices" icon={<PaperClipOutlined />}>
        <Link to="/invoices">Счета</Link>
      </Menu.Item>;

    return [invoicesMenuItem];
  }

  function renderUserPopover() {
    return <Space direction="vertical">
      <Button size="small" icon={<LogoutOutlined />} onClick={() => signout()}>
        Выход
      </Button>
    </Space>
  }

  return (
    <AntdLayout className='layout'>
      <Header style={{ position: 'fixed', zIndex: 100, width: '100%' }}>
        <Row wrap={false}>
          <Col flex="none">
            <div className="layout__logo">
              <Link to="/">ERG.Admin</Link>
            </div>
          </Col>
          <Col flex="auto"></Col>
          <Col flex="none">
            <Popover placement="bottom" content={renderUserPopover()} trigger="click">
              <Space direction="horizontal">
                <span style={{ color: 'white' }}>{currentUser?.userName}</span>
                <Avatar size={32} icon={<UserOutlined />} />
              </Space>
            </Popover>
          </Col>
        </Row>
      </Header>
      <AntdLayout hasSider style={{ marginTop: 64 }}>
        <Sider
          width={300}
          style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            left: 0,
            top: 64,
            bottom: 0,
          }}
        >
          <Menu theme="dark" mode="inline">
            {renderUserListMenuItem()}
            {renderClienListMenuItem()}
            {renderInvoicesMenuItem()}
          </Menu>
        </Sider>
        <AntdLayout style={{ marginLeft: 300 }}>
          <Content style={{ margin: '24px 16px 24px 16px', overflow: 'initial', background: '#fff' }}>
            <div style={{ padding: 24 }}>
              <Outlet />
            </div>
          </Content>
        </AntdLayout>
      </AntdLayout>
    </AntdLayout>
  )
}