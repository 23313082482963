import React from 'react';
import { Popover } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
interface InfoPopoverProps {
  popoverWidth?: number;
  info: JSX.Element | string;
  className?: string;
  style?: React.CSSProperties;
}

export function InfoPopover({
  popoverWidth,
  info,
  className,
  style,
}: InfoPopoverProps) {
  return (
    <Popover
      trigger={['click']}
      overlayStyle={{ width: popoverWidth }}
      content={info}
    >
      <QuestionCircleOutlined
        className={className}
        style={{ fontSize: 16, ...style }}
        onClick={(e) => e.preventDefault()}
      />
    </Popover>
  );
}
