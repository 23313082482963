import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Modal, Col, Form, Row, InputNumber, Alert, Space } from "antd";
import { AppstoreAddOutlined } from '@ant-design/icons';
import useAuth from "../../../../hooks/useAuth";
import useInvoicesApi from '../../../../hooks/backend/api/useInvoicesApi';
import { IInvoiceListItem } from '../../../../types/backend/Invoices';
import { Wrapper, WrapperState } from "../../../Wrapper";
import { getInvoiceListColumns } from "./InvoiceListColumns";
import { useInvoiceListConfig } from "./InvoiceListConfig";
import useBackendForm from '../../../../hooks/useBackendForm';
import IBackendError from "../../../../types/BackendError";
import { InvoicesTable } from '../../../Invoices/Common';

interface IInvoiceCreationForm {
    Amount: number;
}

interface IInvoicesTableItem extends IInvoiceListItem {
    key: string;
}

export function InvoiceList(props: { clientId: string }) {
    const unmounted = useRef(false);
    const invoicesApi = useInvoicesApi();
    const { paging, setPaging } = useInvoiceListConfig();
    const { currentUser } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [initError, setInitError] = useState<any>(undefined);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [wrapperState, setWrapperState] = useState<WrapperState | null>(null);
    const [invoices, setInvoices] = useState<IInvoicesTableItem[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);

    const backendForm = useBackendForm<IInvoiceCreationForm>();

    useEffect(() => {
        return () => { unmounted.current = true; };
    }, []);

    async function fetchData() {
        try {
            setInitError(undefined);
            setIsLoading(true);
            const receivedInvoices = await invoicesApi.getByClientId(props.clientId, paging.page, paging.pageSize);
            const tableItems = receivedInvoices.list.map((value, index) => ({ key: `invoice_${index}`, ...value }));

            if (!unmounted.current) {
                setInvoices(tableItems);
                setTotalRows(receivedInvoices.totalRowCount);
            }
        } catch (error) {
            if (!unmounted.current) setInitError(error);
        } finally {
            if (!unmounted.current) setIsLoading(false);
        }
    }

    useEffect(() => {
        if (currentUser === null) setWrapperState('loading');
        else if (!currentUser.canViewInvoicesOnClient()) setWrapperState('accessDenied');
        else if (initError) setWrapperState('hasErrors');
        else setWrapperState(null);
    }, [currentUser, isLoading, initError]);

    useEffect(() => {
        if (currentUser?.canViewInvoicesOnClient()) fetchData();
    }, [currentUser, paging]);

    async function handleModalSubmit(values: IInvoiceCreationForm) {
        setIsProcessing(true);
        backendForm.resetErrors();
        try {
            await invoicesApi.create(props.clientId, values.Amount);
            await fetchData();
            handleModalClose();
        } catch (error) {
            backendForm.setErrors(error as IBackendError)
        } finally {
            setIsProcessing(false);
        }
    }

    function handleModalClose() {
        setIsModalVisible(false);
        backendForm.form.resetFields();
    }

    function renderErrors() {
        return backendForm.generalErrors?.map((error, index) =>
            <Alert
                key={`login_error_${index}`}
                type="error"
                message={error}
            />
        )
    }

    return (
        <Wrapper state={wrapperState} error={initError}>
            <Card
                extra={
                    <Button
                        type="primary"
                        size="large"
                        icon={<AppstoreAddOutlined />}
                        onClick={() => setIsModalVisible(true)}
                        loading={isProcessing}
                    >
                        Создать счет
                    </Button>
                }
            >
                <InvoicesTable
                    currentUser={currentUser}
                    invoices={invoices}
                    isLoading={isLoading}
                    refresh={fetchData}
                    paging={paging}
                    setPaging={setPaging}
                    totalRows={totalRows}
                    getColumns={getInvoiceListColumns}
                />
            </Card>
            <Modal
                title="Создать счет"
                visible={isModalVisible}
                onCancel={handleModalClose}
                footer={null}
            >
                <Space direction="vertical" style={{ width: '100%' }}>
                    <Form
                        form={backendForm.form}
                        onFinish={handleModalSubmit}
                        labelCol={{ span: 4 }}
                    >
                        <Row className="u-mt-4">
                            <Col span={24}>
                                <Form.Item
                                    rules={[{ required: true, message: "Необходимо указать сумму" }]}
                                    name="Amount"
                                    label="Сумма">
                                    <InputNumber
                                        style={{ width: '100%' }}
                                        min={1}
                                        precision={2}
                                        decimalSeparator=","
                                    />
                                </Form.Item>
                                <Form.Item name="submit" wrapperCol={{ offset: 4 }}>
                                    <Button
                                        block
                                        type="primary"
                                        htmlType="submit"
                                        loading={isProcessing}
                                    >
                                        Создать
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    {renderErrors()}
                </Space>
            </Modal>
        </Wrapper>
    );
}
