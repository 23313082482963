export interface IClientLicenseAgreement {
  id: string;
  number: string;
  state: LicenseAgreementState;
  stateName: string;
  rejectionComment: string;
  terminationComment: string;
}

export enum LicenseAgreementState {
  Created = 1,
  Sent = 2,
  Signed = 3,
  Terminated = 4,
  Rejected = 5
}